let moment = require('moment');
import * as bootbox from 'bootbox';
import * as React from 'react';
import * as ReactDom from 'react-dom';
import {
    BootstrapTable, TableHeaderColumn, TextFilter, CustomFilter,
    CustomSelectProps
} from 'react-bootstrap-table';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { ButtonToolbar, DropdownButton } from "react-bootstrap";
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { Link } from 'react-router-dom';
import { IFilters } from '../../../components/common/Filters';
import { SignatureFlowReportFilter } from '../SignatureFlowReportFilter';
import { CheckBoxComponent, CheckBoxSize } from "../../../components/common/CheckBoxComponent";
import { CustomDateFilter } from '../../../components/common/CustomDateFilter';
import { DaysRangeOptionsList, SearchDelayTimeConstant } from '../../helper/Constants';
import * as Helper from '../../../components/helper/HelperFunctions';
import { VenusNotifier } from '../../../components/helper/VenusNotifier';
import { CustomMultiSelect } from "../../../components/common/MultipleSelectComponent";
import { SignatureFlowReportSignatureStatus, ILinkedMergedDetails, ILinkedMergedDetailsResponse } from '../../../models/SignatureFlowReportState';
import { ISignatureFlowReportCustomColumn } from '../../../models/CustomColumn';
import { SignatureFlowReportAction } from '../SignatureFlowReportAction';
import { ISignatureFlowReportTableModel } from '../../../models/SignatureFlowReportTableState';
import { container } from '../../../../Startup/inversify.config';
import { TYPES } from "../../../../Startup/types";
import { IHtmlUtilities } from '../../../../Core/Utilities/HtmlUtilities';
import { SignatureFlowReportFilterConstants } from '../../helper/Constants';
import { IUserProfile } from '../../../components/navigation/profile/ProfileObjects';
import { CustomColumnType } from '../../../models/CustomColumn';
import { ResourceIdConstants, ScreenIds } from '../../helper/RBACConstants';
import { getSignatureStatusClassName } from '../../../components/helper/HelperFunctions';
import CustomSingleSelect from '../../common/SingleSelectComponent';
import LinkIcon from '../../../../images/LinkIcon';
const htmlUtilities = container.get<IHtmlUtilities>(TYPES.IHtmlUtilities);
var htmlencode = require('htmlencode');

export interface TableProps {
    screenId: string;
    isLoading: boolean,
    saveFilterShow: boolean,
    onSortChange: any,
    onSearchChange: any,
    onFilterChange: any,
    pageNo: number,
    totalRows: number;
    officeLocation: any[];


    pageSize: number,
    loadGrid(): void,
    onRowSelect: (row: any, isSelected: any, e: any) => void,
    onSelectAll: (isSelected: any, rows: any, e: any) => void,
    selectedRows: number[],

    onExportToExcel(onExportToExcelComplete: () => void): void;
    onFilterNameChange(event: any): void,
    onFilterSave(onApplyFilter: (filter: IFilters) => void): void,
    onFilterUpdate: (filterName: string) => void,
    onFilterDelete: (filterName: string) => any,
    filterList: IFilters[],
    currentFilter: IFilters,
    onSetDefaultFilter: (name: string) => void,
    onRemoveDefaultFilter: (name: string) => void,
    defaultFilter: string | undefined,
    onSaveFilterShow(): void,
    onSaveFilterHide(): void,
    onPageReload: () => void,

    onSignersDetailOpen: (rowIndex: number) => void,
    onResendAccessLinkOpen: (rowIndex: number) => void;
    onDocumentDetailsOpen: (rowIndex: number) => void,
    onSetAccessOpen: (rowIndex: number, headerTextShow: boolean) => void;
    onClientTrackingOpen: (rowIndex: number) => void,
    onDocumentNoteOpen: (rowIndex: number) => void,
    onReportProblemOpen: (rowIndex: number) => void;
    onCancelDocumentOpen: (rowIndex: number) => void,
    onCancelDocumentConfirmation: (rowIndex: number) => void,
    onDeleteDocumentConfirmation: (rowIndex: number) => void,
    onViewAccessCodeOpen: (rowIndex: number) => void,
    onDownloadReturnOpen: (rowIndex: number) => void,
    onDeleteDocumentsOpen: (rowIndex: number) => void
    onRetoreDocumentsOpen: (rowIndex: number) => void;
    onSendReminder: (rowIndex: number) => void,
    onDownloadHistoryOpen: (rowIndex: number) => void,
    onRecipientDetailsOpen: (rowIndex: number) => void,
    onEditClientDetailsOpen: (rowIndex: number) => void,
    onUnlockRecipientsOpen: (rowIndex: number) => void,

    signatureReportsData: ISignatureFlowReportTableModel,

    reportCustomColumns: ISignatureFlowReportCustomColumn[],
    saveReportCustomColumns: (customColumns: ISignatureFlowReportCustomColumn[], callback: () => void) => void,
    userBasicProfile: IUserProfile,
    getLinkedMergeDetails: (
        emailId: string,
        callback: (linkedMergedDetailsResponse: ILinkedMergedDetailsResponse) => void
    ) => void;
    getPrimaryUTELinkedEmail: (signingId: number, callback?: (email: string | null) => void) => void;
}


export interface TableStates {
    customColumn: ISignatureFlowReportCustomColumn[],
    showDocumentNote: boolean,
    linkedDetails: string
}

const SignatureStatusList = {
    "None": "None",
    "Processing": "Processing",
    "OutForSignature": "Out For Signature",
    "ESigned": "E-Signed",
    "Declined": "Declined",
    "Cancelled": "Canceled",
    "PartiallySigned": "Partially Signed",
    "SignatureStampingFailed": "Signature Stamping Failed",
    "DeliveryFailed": "Delivery Failed"
};

export class SignatureArchiveReportTable extends React.Component<TableProps, TableStates>{
    private filterChanged: boolean = false;
    public refClearSearchBtn: any;
    private customMultiSelect: any;
    private customExpirationDateFilter: any;
    private customLastReminderDateFilter: any;
    public isAppiedFilter: boolean = false;
    private taxYearList: any[] = [];
    private customDownloadStatusSingleSelect: any;
    private customDeliveredDateSingleSelect: any;
    private customArchiveDateSingleSelect: any;
    private customOfficeLocationMultiSelect: any;

    private customTaxYearMultiSelect: any;
    constructor(props: TableProps) {
        super(props);
        this.state = {
            customColumn: [],
            showDocumentNote: false,
            linkedDetails: ""
        }
        this.onClearFilter = this.onClearFilter.bind(this);
        this.defaultType = this.defaultType.bind(this);
        this.defaultClientIdType = this.defaultClientIdType.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onLoadSelectedFilter = this.onLoadSelectedFilter.bind(this);
        this.onFilterSaveClick = this.onFilterSaveClick.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.taxYearList = Helper.getTaxYearsForSignReportsFilter();
    }


    private onFilterChange(dataField: any) {
        if (!this.filterChanged && !this.isAppiedFilter) {
            this.filterChanged = true;
            this.props.onFilterChange(dataField);
            this.filterChanged = false;
        }
    }

    onLoadSelectedFilter = (filter?: IFilters) => {
        this.isAppiedFilter = true;        
        this.onClearFilter(true);
        if (filter) {
            this.filterChanged = true;
            for (let field of Object.keys(filter.fields)) {
                switch (field) {
                    case 'deliveredBy':
                        (this.refs.deliveredBy as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'documentName':
                        (this.refs.documentName as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'documentType':
                        (this.refs.documentType as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'documentNote':
                        (this.refs.documentNote as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'title':
                        (this.refs.title as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'signers':
                        (this.refs.signers as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'signatureStatus':
                        this.customMultiSelect?.applyFilter((filter.fields[field]));
                        break;
                    case 'downloadStatus':
                        this.customDownloadStatusSingleSelect?.applyFilter((filter.fields[field]));
                        break;
                    case 'deliveredOn':
                        let deliveredDateFilter: any = filter.fields[field];
                        if (deliveredDateFilter) {
                            this.customDeliveredDateSingleSelect?.applyFilter((filter.fields[field]));
                        }
                        break;
                    case 'expiration':
                        this.customExpirationDateFilter?.applyFilter((filter.fields[field]));
                        break;
                    case 'loggedUser':
                        (this.refs.loggedUser as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'taxYear':
                        this.customTaxYearMultiSelect?.applyFilter(filter.fields[field]);
                        break;
                    case 'recipientName':
                        (this.refs.recipientName as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'clientId':
                        (this.refs.clientId as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'documentStatusChangedOn':
                        let archivedDateFilter: any = filter.fields[field];
                        if (archivedDateFilter) {
                            this.customArchiveDateSingleSelect?.applyFilter((filter.fields[field]));
                        }
                        break;
                    case 'lastReminderOn':
                        this.customLastReminderDateFilter?.applyFilter((filter.fields[field]));
                        break;
                    case 'officeLocation':
                        this.customOfficeLocationMultiSelect?.applyFilter(filter.fields[field]);
                        break;
                }
                this.filterChanged = false;
            }

            this.isAppiedFilter = false;
            this.onFilterChange(filter.fields);
            (this.refs.signatureReportTable as BootstrapTable)?.handleSearch(filter.searchText);
        }
    }
    
    private onFilterSaveClick(filterName: string) {
        
        if (!Helper.validateFilter(this.props.currentFilter)) {
            VenusNotifier.Warning(SignatureFlowReportFilterConstants.OtherMessage.FilterFieldsAreEmpty, null);
            return;
        }

        let temThis = this;
        if (typeof filterName == 'undefined' || filterName.length == 0) {
            this.props.onSaveFilterShow();
        }
        else {
            bootbox.dialog({
                title: SignatureFlowReportFilterConstants.FilterConfirmationModalTitle.FilterSaveUpdate,
                message: SignatureFlowReportFilterConstants.OtherMessage.CreateOrUpdateFilter,
                buttons: {
                    cancel: {
                        label: SignatureFlowReportFilterConstants.ControlLabel.NoUpdateTheCurrent,
                        className: 'btn-white modal-footer-button-cancel',
                        callback: function () { temThis.props.onFilterUpdate(filterName) }
                    },
                    confirm: {
                        label: SignatureFlowReportFilterConstants.ControlLabel.YesCreateNew,
                        className: 'btn-info modal-footer-button-save',
                        callback: function () { temThis.props.onSaveFilterShow(); }
                    }
                }
            }).find(".modal-dialog")
            .addClass("modal-dialog-centered");
        }
    }

    getLinkedDetails = (emailId: string) => {
        this.props.getLinkedMergeDetails(
            emailId,
            (linkedMergedDetailsResponse: ILinkedMergedDetailsResponse) => {
                const { linkedDetails, error } = linkedMergedDetailsResponse;
                if (error) {
                    this.setState({ linkedDetails: "Error while fetching linked report" });
                } else {
                    if (linkedDetails.length === 0) {
                        this.setState({ linkedDetails: "No Data" });
                    } else {
                        let outputData = "";
                        const uniqueClientIds = linkedDetails.reduce(
                            (clientIds: string[], eachDetail: ILinkedMergedDetails) => {
                                if (clientIds.includes(eachDetail.clientId)) {
                                    return clientIds;
                                }
                                clientIds.push(eachDetail.clientId);
                                return clientIds;
                            },
                            []
                        );
                        uniqueClientIds.forEach((eachClientId: string) => {
                            const linkedClientDetails = linkedDetails.filter(
                                (eachDetail: ILinkedMergedDetails) => {
                                    return eachDetail.clientId === eachClientId;
                                }
                            );
                            const uniqueClientNames = linkedClientDetails.reduce(
                                (clientNames: string[], eachClientDetail: ILinkedMergedDetails) => {
                                    if (clientNames.includes(eachClientDetail.name)) {
                                        return clientNames;
                                    }
                                    clientNames.push(eachClientDetail.name);
                                    return clientNames;
                                },
                                []
                            );
                            uniqueClientNames.forEach((eachClientName: string) => {
                                const linkedDataForClientIdAndName = linkedClientDetails.filter(
                                    (eachClientDetail: ILinkedMergedDetails) => {
                                        return (
                                            eachClientDetail.clientId === eachClientId && eachClientDetail.name === eachClientName
                                        );
                                    }
                                );
                                outputData += `
                                <div class='linkClientName'>${eachClientName}</div>
                                <div class='linkClientId'>${eachClientId}</div>
                            `;
                                if (linkedDataForClientIdAndName.length === 1) {
                                    outputData += `
                                    <div class='linkEmailId ${
                                        linkedDataForClientIdAndName[0].isPrimaryUTE ? "linkPrimaryUTE" : ""
                                    }'><span class='emailId'>${linkedDataForClientIdAndName[0].emailId}${
                                        linkedDataForClientIdAndName[0].isSpouse ? " (Spouse)" : ""
                                    }</span></div>
                                `;
                                } else {
                                    linkedDataForClientIdAndName.forEach(
                                        (eachLinkedDetail: ILinkedMergedDetails, index: number) => {
                                            outputData += `
                                    <div class='linkEmailId ${eachLinkedDetail.isPrimaryUTE ? "linkPrimaryUTE" : ""}'>${
                                                index + 1
                                            }. <span class='emailId'>${eachLinkedDetail.emailId}${
                                                eachLinkedDetail.isSpouse ? " (Spouse)" : ""
                                            }</span></div>
                                `;
                                        }
                                    );
                                }
                            });
                        });
                        this.setState({ linkedDetails: outputData });
                    }
                }
            }
        );
    };

    nameType = (cell: any, row: any) => {
        return (
            <div className="nameCell">
                <div title={cell} className="ellipsis">
                    {cell}
                </div>
                {row.isUTELinked && (
                    <ButtonToolbar className="btn-group-vertical linkIconContainer">
                        <DropdownButton
                            size="sm"
                            title={<LinkIcon />}
                            id="linkIcon"
                            data-test-auto="271576c6-b729-4e50-b51a-480831e76dc4"
                            className=""
                            onClick={(e) => {
                                this.setState({ linkedDetails: "Loading..." });
                                this.props.getPrimaryUTELinkedEmail(row.signingId, (email) => {
                                    if (email) {
                                      console.log(`Primary UTE linked email: ${email}`);
                                      this.getLinkedDetails(email)
                                    } 
                            })}}
                        >
                            <div className="linkedDetailsContainer">
                                <div className="connectedStyle">Connected with:</div>
                                <div className="linkedData">
                                    <div dangerouslySetInnerHTML={{ __html: this.state.linkedDetails }} />
                                </div>
                            </div>
                        </DropdownButton>
                    </ButtonToolbar>
                )}
            </div>
        );
    };

    createCustomCheckbox = (props: CustomSelectProps): any => {
        return (<CheckBoxComponent size={CheckBoxSize.sm}
            id={"signatureflow-reports-checkbox-" + props.rowIndex}
            className={"custom-curve-check-box SignatureArchivecheckbox"}
            indeterminate={props.indeterminate}
            checked={props.checked}
            disabled={props.disabled}
            onChange={(e: any) => props.onChange(e, props.rowIndex)}
            ref={(input: any) => {
                if (input) {
                    input.indeterminate = props.indeterminate;
                }
            }}
            text={""} />);

    }

    renderShowTotal = (start: number, to: number, total: number) => {
        return (
            <p>
                Showing {start} to {to} of {total} entries
            </p>
        )
    }

    private setNoContent() {
        if (this.props.isLoading) {
            return (<LoadingOverlay style={{ height: '400px' }}>
                <Loader loading={this.props.isLoading} />
            </LoadingOverlay>)
        } else {
            return "No reports found"
        }
    }

    private defaultType(cell: any, row: any) {
        return <div title={cell} className="ellipsis">{cell}</div>
    }

    private defaultClientIdType(cell: any, row: any) {
        return <div title={cell} className="ellipsis" style={{fontWeight:'700'}}>{cell}</div>
    }

    //===================================Signature Status Column Header====================================================//
    customSignatureStatusToolTip(cell: any, row: any) {
        let status: any = row.signatureStatus;
        let signatureType: "None" | "Processing" | "OutForSignature" | "ESigned" | "Declined" | "Cancelled" | "PartiallySigned" | "SignatureStampingFailed" | "DeliveryFailed"
            = (typeof status == "number") ? SignatureFlowReportSignatureStatus[row.signatureStatus] : row.signatureStatus;
        return `${SignatureStatusList[signatureType]}`;
    }

    private signatureStatusDataFormatType = (cell: any, row: any) => {

        let status: any = row.signatureStatus;
        let signatureType: "None" | "Processing" | "OutForSignature" | "ESigned" | "Declined" | "Cancelled" | "PartiallySigned" | "SignatureStampingFailed" | "DeliveryFailed"
            = (typeof status == "number") ? SignatureFlowReportSignatureStatus[status] : status;
        const signatureStatusClassName = getSignatureStatusClassName(SignatureStatusList[signatureType])
        if (signatureType == undefined) {
            <Link to={"#"}>{SignatureStatusList["None"].toUpperCase()}</Link>;
        }
        else if (SignatureStatusList[signatureType] === SignatureStatusList.Declined) {                      
            return <div className={'signature-status '+signatureStatusClassName}>
                        <span className='report-signature-status'
                        onClick={() => this.props.onCancelDocumentOpen(row.rowIndex)}>{SignatureStatusList[signatureType]}</span>
                    </div> ;
        }
        else if (SignatureStatusList[signatureType] === SignatureStatusList.Cancelled) {
            return <div className={'signature-status '+signatureStatusClassName}>
                        <span data-resource-id={`${this.props.screenId}${ResourceIdConstants.Cancel}`}
                            className='report-signature-status'
                            onClick={() => this.props.onCancelDocumentOpen(row.rowIndex)}>
                            {SignatureStatusList[signatureType]}
                        </span>
                    </div>
        }
        else {
            return <div className={'signature-status '+signatureStatusClassName}>
                        <span className='report-signature-status' onClick={() => this.props.onSignersDetailOpen(row.rowIndex)}>{SignatureStatusList[signatureType]}</span>
                    </div>
        }
    }

    //==================================Signature Status Column Header Ends Here====================================================//

    private CreateSignerDataType = (cell: any, row: any) => {

        return (
            <div style={{ display: "inline-flex" }}>

                <Link data-resource-id={`${this.props.screenId}${ResourceIdConstants.EditClientDetails}`}
                    to={"#"} onClick={() => this.props.onRecipientDetailsOpen(row.rowIndex)} style={{ display: "contents" }}>
                    <div style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "135px"
                    }}>
                        {(row.signers)}
                    </div>
                    <div>
                        {" (" + row.signedCount + " of " + row.signersCount + " )"}
                    </div>
                </Link>

            </div>
        );
    }
    //=================================================Download Status Column Header====================================================
    private CreateDownloadCountType = (cell: any, row: any) => {
        return (row.downloadStatus > 0 ? <Link to={"#"} className='ellipsis' onClick={() => this.props.onDownloadHistoryOpen(row.rowIndex)}>{"Downloaded"}</Link> : <span className='ellipsis'>{"To Be Downloaded"}</span>);
    }

    private CreateDownloadCountToolTip = (cell: any, row: any) => {
        return (row.downloadStatus > 0 ? "Downloaded" : "To Be Downloaded");
    }

    //=================================================Download Status Column Header Ends Here====================================================

    private CreateDocumentNameDataFormatType = (cell: any, row: any) => {
        if (row.documentCount > 1) {
            return (<Link to={'#'} onClick={() => this.props.onDocumentDetailsOpen(row.rowIndex)}>{"Multiple Documents"}</Link>)
        }
        return <span className='ellipsis'>{`${row.documentName}`}</span>
    }

    private CreateDocumentNameToolTip = (cell: any, row: any) => {
        if (row.documentCount == 1) {
            return `${row.documentName}`
        }
    }

    private CreateDocumentNoteDataFormat = (cell: any, row: any) => {
        return (
            <div>
                <div className="table-reports-document-note" dangerouslySetInnerHTML={{ __html: htmlUtilities.sanitize(htmlencode.htmlDecode(row.documentNote)) }}></div>
            </div>
        )
    }

    private CreateDocumentNoteToolTip = (cell: any, row: any) => {
        return `${row.documentNote.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/(<(.*?)>|&\w+;)/g, '')}`
    }

    private CreateDocumentTypeDataFormatType = (cell: any, row: any) => {
        if (row.documentCount > 1) {
            return (<Link to={'#'} onClick={() => this.props.onDocumentDetailsOpen(row.rowIndex)}>{"Multiple Documents"}</Link>)
        }
        return <span className='ellipsis'>{`${row.documentType}`}</span>
    }

    private CreateDocumentTypeToolTip = (cell: any, row: any) => {
        if (row.documentCount == 1) {
            return `${row.documentType}`
        }
    }

    public onClearFilter(clearAll?: boolean) {
        this.filterChanged = true;
        this.refs.deliveredBy !== undefined ? (this.refs.deliveredBy as TableHeaderColumn).cleanFiltered() : "";
        this.refs.expiration !== undefined ? (this.refs.expiration as TableHeaderColumn).cleanFiltered() : "";
        this.refs.signatureStatus !== undefined ? (this.refs.signatureStatus as TableHeaderColumn).cleanFiltered() : "";
        this.refs.signers !== undefined ? (this.refs.signers as TableHeaderColumn).cleanFiltered() : "";
        this.refs.title !== undefined ? (this.refs.title as TableHeaderColumn).cleanFiltered() : "";
        this.refs.loggedUser !== undefined ? (this.refs.loggedUser as TableHeaderColumn).cleanFiltered() : "";
        this.refs.documentName !== undefined ? (this.refs.documentName as TableHeaderColumn).cleanFiltered() : "";
        this.refs.documentType !== undefined ? (this.refs.documentType as TableHeaderColumn).cleanFiltered() : "";
        this.refs.documentNote !== undefined ? (this.refs.documentNote as TableHeaderColumn).cleanFiltered() : "";
        this.refs.recipientName !== undefined ? (this.refs.recipientName as TableHeaderColumn).cleanFiltered() : "";
        this.refs.clientId !== undefined ? (this.refs.clientId as TableHeaderColumn).cleanFiltered() : "";
        this.refs.lastReminderOn !== undefined ? (this.refs.lastReminderOn as TableHeaderColumn).cleanFiltered() : "";

        this.customArchiveDateSingleSelect?.cleanFilteredValue();
        this.customDeliveredDateSingleSelect?.cleanFilteredValue();
        this.customDownloadStatusSingleSelect?.cleanFilteredValue();
        this.customTaxYearMultiSelect?.cleanFiltered(clearAll);
        this.customMultiSelect?.cleanFiltered(clearAll);
        this.customExpirationDateFilter?.cleanFilteredValue();
        this.customLastReminderDateFilter?.cleanFilteredValue();
        this.customOfficeLocationMultiSelect?.cleanFiltered(clearAll);
        this.filterChanged = false;
    }

    private createCustomToolBar = () => {
        return (
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 marR15 header-filter" style={{ height: 33, position: "unset" }}>
                <SignatureFlowReportFilter ref='Filters'
                    screenId={this.props.screenId}
                    onFilterNameChange={this.props.onFilterNameChange}
                    onFilterSave={this.props.onFilterSave}
                    onClearFilter={this.onClearFilter}
                    showExportButton={true}
                    onExportToExcel={this.props.onExportToExcel}
                    filterList={this.props.filterList}
                    onSetDefaultFilter={this.props.onSetDefaultFilter}
                    onRemoveDefaultFilter={this.props.onRemoveDefaultFilter}
                    onFilterDelete={this.props.onFilterDelete}
                    show={this.props.saveFilterShow}
                    onFilterSaveClick={this.onFilterSaveClick}
                    onSaveFilterHide={this.props.onSaveFilterHide}
                    onLoadSelectedFilter={this.onLoadSelectedFilter}
                    defaultFilter={this.props.defaultFilter}
                    loadGrid={this.props.loadGrid}
                    showCustomColumnsButton={true}
                    reportCustomColumns={this.props.reportCustomColumns}
                    saveReportCustomColumns={this.props.saveReportCustomColumns}
                    onPageReload={this.props.onPageReload}
                />
            </div >);
    }

    private ExpirationDateFilter = (filterHandler: any, customFilterParameters: any) => {
        const placeholder = customFilterParameters.placeholder;
        return (<CustomDateFilter onRef={(ref: any) => (this.customExpirationDateFilter = ref)} filterHandler={filterHandler} customFilterParameters={customFilterParameters} calendarContainer={ReactDom.findDOMNode(this.refs.signatureReportTable)} placeholder={placeholder}/>)
    }
    
    private LastReminderDateFilter = (filterHandler: any, customFilterParameters: any) => {
        const placeholder = customFilterParameters.placeholder;
        return (<CustomDateFilter onRef={(ref: any) => (this.customLastReminderDateFilter = ref)} filterHandler={filterHandler} customFilterParameters={customFilterParameters} calendarContainer={ReactDom.findDOMNode(this.refs.signatureReportTable)} placeholder={placeholder}/>)
    }

    private getMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customMultiSelect = ref)} filterHandler={filterHandler} options={options}
                enableAllFilter={enableAllFilter} placeholder={placeholder} />
        );
    }
    private getYearMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customTaxYearMultiSelect = ref)} filterHandler={filterHandler} options={options} enableAllFilter={enableAllFilter} placeholder={placeholder} isSignatureReports="True"/>

        );
    }

    
    private getMultiOfficeLocationDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect
                onRef={(ref: any) => (this.customOfficeLocationMultiSelect = ref)}
                filterHandler={filterHandler}
                options={options}
                placeholder={placeholder}
            />
        );
    }

    private getDeliveredDateSingleSelectDropDown = (filterHandler: any, customFilterParameters: any) =>{
        const options = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomSingleSelect onRef={(ref: any) => (this.customDeliveredDateSingleSelect = ref)} filterHandler={filterHandler} options={options} placeholder={placeholder} />
        );
    }

    private getArchiveDateSingleSelectDropDown = (filterHandler: any, customFilterParameters: any) =>{
        const options = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomSingleSelect onRef={(ref: any) => (this.customArchiveDateSingleSelect = ref)} filterHandler={filterHandler} options={options} placeholder={placeholder} />
        );
    }

    private getDownloadStatusSelectDropDown = (filterHandler: any, customFilterParameters: any) =>{
        const options = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomSingleSelect onRef={(ref: any) => (this.customDownloadStatusSingleSelect = ref)} filterHandler={filterHandler} options={options} placeholder={placeholder} />
        );
    }

    private SignatureFlowReportAction = (cell: any, row: any) => {
        return <SignatureFlowReportAction
            screenId={ScreenIds.ArchiveReport}
            isArchive={true}
            rowIndex={row.rowIndex}
            title={cell}
            onDownloadReturnOpen={this.props.onDownloadReturnOpen}
            onSetAccessOpen={this.props.onSetAccessOpen}
            onResendAccessLinkOpen={this.props.onResendAccessLinkOpen}
            onViewAccessCodeOpen={this.props.onViewAccessCodeOpen}
            onSendReminder={this.props.onSendReminder}
            onReportProblemOpen={this.props.onReportProblemOpen}
            onDocumentNoteOpen={this.props.onDocumentNoteOpen}
            onCancelDocumentConfirmation={this.props.onCancelDocumentConfirmation}
            onDeleteDocumentConfirmation={this.props.onDeleteDocumentConfirmation}
            onClientTrackingOpen={this.props.onClientTrackingOpen}
            signatureReportsData={this.props.signatureReportsData.documents}
            userBasicProfile={this.props.userBasicProfile}
            onEditClientDetails={this.props.onEditClientDetailsOpen}
            onUnlockRecipientsOpen={this.props.onUnlockRecipientsOpen}
        />;
    }

    private GetSortedColumns = (columns: any) => {
        let columnHeader: any = columns;
        let order: number = 0;
        let removedColumn: any = null;
        for (let i = 0; i < this.props.reportCustomColumns.length; i++) {
            for (let j = 0; j < columnHeader.length; j++) {
                if (this.props.reportCustomColumns[i].columnName.trim() == columnHeader[j].header.trim()) {
                    order = this.props.reportCustomColumns[i].columnOrder;
                    if (this.props.reportCustomColumns[i].customColumn != CustomColumnType.Recipients) {
                        columnHeader[j].isHidden = !this.props.reportCustomColumns[i].isActive;
                    }
                    removedColumn = columnHeader.splice(j, 1);
                    columnHeader.splice(order - 1, 0, removedColumn[0]);
                }
            }
        }

        const indexOfColumnIndex = columnHeader.findIndex((item:any) => item.key === 'index');
        columnHeader = Helper.moveItemAtEnd(indexOfColumnIndex, columnHeader);

        return columnHeader;
    }

    public render() {

        let selected: number[] = [];
        if (this.props.selectedRows.length > 0) {
            for (var i in this.props.selectedRows) {
                let rowIndex = this.props.selectedRows[i];
                selected.push(((this.props.pageNo - 1) * this.props.pageSize) + rowIndex);
            }
        }

        const options = {
            onSortChange: this.props.onSortChange,
            sizePerPage: this.props.pageSize,
            onSearchChange: this.props.onSearchChange,
            page: this.props.pageNo,
            onFilterChange: this.onFilterChange,
            noDataText: this.setNoContent(),
            toolBar: this.createCustomToolBar,
            clearSearch: true,
        };

        let selectRowProp: any = {
            mode: 'checkbox',
            onSelect: this.props.onRowSelect,
            onSelectAll: this.props.onSelectAll,
            selected: selected,
            customComponent: this.createCustomCheckbox,
            className: 'row-selected'
        };

        const DownloadedOptionsList = [
            {label: "To Be Downloaded", value: 0},
            {label: "Downloaded", value: 1}
        ]

        const SignatureFlowTypeList = {
            1: "Signatures",
            2: "Sign Merge"
        }

        const SignatureStatusOptionsList = [
            { label: 'Canceled', value: 5 },
            { label: 'Declined', value: 4 },
            { label: 'Delivery Failed', value: 8},
            { label: 'E-Signed', value: 3 },
            { label: 'Out For Signature', value: 2 },
            { label: 'Partially Signed', value: 6 },
            { label: 'Processing', value: 1 },
            { label: 'Signature Stamping Failed', value: 7 },
        ];

        let data: any = [];

        if (this.props.signatureReportsData.documents) {
            data = this.props.signatureReportsData.documents.map((model, index) => {
                return {
                    signingId: model.signingId,
                    deliveredBy: model.senderName,
                    loggedUser: model.loggedUser,
                    documentName: model.documentName == null ? " " : model.documentName,
                    documentType: model.documentType == null ? " " : model.documentType,
                    documentNote: model.documentNote == null ? " " : model.documentNote,
                    deliveredOn: model.deliveredOn == null ? "" : moment.utc(model.deliveredOn).local().format('MM/DD/YYYY'),
                    documentStatusChangedOn: model.documentStatusChangedOn == null ? "" : moment.utc(model.documentStatusChangedOn).local().format('MM/DD/YYYY'),
                    signers: model.signersEmailAddress,
                    title: model.title,
                    signatureStatus: model.signatureStatus,
                    downloadStatus: model.downloadCount,
                    documentCount: model.documentCount,
                    signedCount: model.signedCount,
                    signersCount: model.signersCount,
                    expiration: model.expiration == null ? "" : moment(model.expiration).local().format('MM/DD/YYYY'),
                    button: 'Actions',
                    index: ((this.props.pageNo - 1) * this.props.pageSize) + index,
                    rowIndex: index,
                    taxYear: (model.taxYear == null || model.taxYear == 0) ? "" : model.taxYear,
                    recipientName: model.recipientName == null ? "" : model.recipientName,
                    clientId: model.clientId == null ? "" : model.clientId,
                    lastReminderOn:model.lastReminder == null ? "" : moment.utc(model.lastReminder).local().format('MM/DD/YYYY'),
                    officeLocation: model.officeLocation == null ? "" : model.officeLocation,
                    isUTELinked: model.isUTELinked
                }
            })
        }
      
        const Columns = [
            {
                header: '',
                key: 'index',
                isKey: true,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: true,
                width: 'auto',
                className: 'text-field-header header-text',
                filter: { type: 'TextFilter', placeholder: 'index', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Client Name',
                key: 'recipientName',
                isKey: false,
                dataFormat: this.nameType,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                columnClassName: 'nameColumnClass',
                width: '120px',
                className: 'text-field-header header-text',
                filter: {
                    type: 'TextFilter', placeholder: 'Client Name', delay : SearchDelayTimeConstant
                } as TextFilter
            },
            {
                header: 'Client ID',
                key: 'clientId',
                isKey: false,
                dataFormat: this.defaultClientIdType,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                width: 'auto',
                className: 'text-field-header header-text',
                filter: {
                    type: 'TextFilter', placeholder: 'Client ID', delay : SearchDelayTimeConstant
                } as TextFilter
            },
            {
                header: 'Office Location',
                key: 'officeLocation',
                isKey: false,
                dataFormat: this.defaultType,
                className: 'text-field-header header-text',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: { type: 'CustomFilter', getElement: this.getMultiOfficeLocationDropDown, 
                        customFilterParameters: { options: this.props.officeLocation, enableAllFilter: true, placeholder: 'Office Location' } as any } as CustomFilter
            },
            {
                header: 'Subject',
                key: 'title',
                isKey: false,
                dataFormat: this.defaultType,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                width: 'auto',
                className: 'text-field-header header-text',
                filter: {
                    type: 'TextFilter', placeholder: 'Subject', style: { font: 'bold' }, delay : SearchDelayTimeConstant
                } as TextFilter
            },
            {
                header: 'Sent By',
                key: 'deliveredBy',
                isKey: false,
                dataFormat: this.defaultType,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                width: 'auto',
                className: 'text-field-header header-text',
                filter: {
                    type: 'TextFilter', placeholder: 'Sent By', style: { font: 'bold' }, delay : SearchDelayTimeConstant
                } as TextFilter
            },
            {
                header: 'Document Name',
                key: 'documentName',
                isKey: false,
                dataFormat: this.CreateDocumentNameDataFormatType,
                dataSort: true,
                isHidden: false,
                toolTip: this.CreateDocumentNameToolTip,
                columnClassName: '',
                width: 'auto',
                className: 'text-field-header header-text',
                filter: { type: 'TextFilter', placeholder: 'Document Name', style: { font: 'bold' }, delay : SearchDelayTimeConstant } as TextFilter
            },
            {
                header: 'Document Type',
                key: 'documentType',
                isKey: false,
                dataFormat: this.CreateDocumentTypeDataFormatType,
                dataSort: true,
                isHidden: false,
                toolTip: this.CreateDocumentTypeToolTip,
                columnClassName: '',
                width: 'auto',
                className: 'text-field-header header-text',
                filter: {
                    type: 'TextFilter', placeholder: 'Document Type', style: { font: 'bold' }, delay : SearchDelayTimeConstant
                } as TextFilter
            },
            {
                header: 'Tax Year',
                key: 'taxYear',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                className: 'text-field-header header-text',
                filter: { type: 'CustomFilter', getElement: this.getYearMultiSelectDropDown, customFilterParameters: { options: this.taxYearList, enableAllFilter: true, placeholder: 'Tax Year' } as any } as CustomFilter
            },
            {
                header: 'Delivered Date',
                key: 'deliveredOn',
                isKey: false,
                dataFormat: this.defaultType,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                width: 'auto',
                className: 'text-field-header header-text',
                filter: { type: 'CustomFilter', getElement: this.getDeliveredDateSingleSelectDropDown, customFilterParameters: { options:DaysRangeOptionsList, placeholder: 'Delivered Date'} as any } as CustomFilter
            },
            {
                header: 'Document Note',
                key: 'documentNote',
                isKey: false,
                dataSort: true,
                width: 'auto',
                dataFormat: this.CreateDocumentNoteDataFormat,
                toolTip: this.CreateDocumentNoteToolTip,
                columnClassName: '',
                isHidden: false,
                className: 'text-field-header header-text',
                filter: {
                    type: 'TextFilter', placeholder: 'Document Note', style: { font: 'bold' }, delay : SearchDelayTimeConstant
                } as TextFilter
            },
            {
                header: 'Recipients',
                key: 'signers',
                isKey: false,
                dataFormat: this.CreateSignerDataType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: '200px',
                className:'text-field-header header-text',
                filter: {
                    type: 'TextFilter', placeholder: 'Recipients', style: { font: 'bold' }, delay : SearchDelayTimeConstant
                } as TextFilter
            },
            {
                header: 'Status',
                key: 'signatureStatus',
                isKey: false,
                dataSort: true,
                dataFormat: this.signatureStatusDataFormatType,
                width: '200px',
                columnClassName: '',
                toolTip: this.customSignatureStatusToolTip,
                isHidden: false,
                className:'text-field-header header-text',
                filter: {
                    type: 'CustomFilter', getElement: this.getMultiSelectDropDown, 
                    customFilterParameters: { options: SignatureStatusOptionsList, enableAllFilter: false, placeholder: 'Status' } as any
                } as CustomFilter
            },
            {
                header: 'Expiration Date',
                key: 'expiration',
                isKey: false,
                isHidden: false,
                toolTip: false,
                dataSort: true,
                dataFormat: this.defaultType,
                columnClassName: '',
                width: 'auto',
                className: 'text-field-header header-text',
                filter: {
                    type: 'CustomFilter', getElement: this.ExpirationDateFilter, customFilterParameters: {placeholder: 'Expiration Date' }  as any
                } as CustomFilter,
            },
            {
                header: 'Archived Date',
                key: 'documentStatusChangedOn',
                isKey: false,
                dataFormat: this.defaultType,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                width: 'auto',
                className: 'text-field-header header-text',
                filter: { type: 'CustomFilter', getElement: this.getArchiveDateSingleSelectDropDown, customFilterParameters: { options:DaysRangeOptionsList, placeholder: 'Archived Date'} as any } as CustomFilter
            },
            {
                header: 'Logged In CPA',
                key: 'loggedUser',
                isKey: false,
                dataFormat: this.defaultType,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                width: 'auto',
                className: 'text-field-header header-text',
                filter: {
                    type: 'TextFilter', placeholder: 'Logged in CPA', style: { font: 'bold' }, delay : SearchDelayTimeConstant
                } as TextFilter
            },
            {
                header: 'Download Status',
                key: 'downloadStatus',
                isKey: false,
                isHidden: false,
                toolTip: this.CreateDownloadCountToolTip,
                dataSort: true,
                dataFormat: this.CreateDownloadCountType,
                columnClassName: '',
                width: 'auto',
                className: 'text-field-header header-text',
                filter: { type: 'CustomFilter', getElement: this.getDownloadStatusSelectDropDown, customFilterParameters: { options:DownloadedOptionsList, placeholder: 'Download Status' } as any } as CustomFilter
            },
            {
                header: 'Last Reminder',
                key: 'lastReminderOn',
                isKey: false,
                dataFormat: this.defaultType,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                width: 'auto',
                className: 'text-field-header header-text',
                filter: {
                    type: 'CustomFilter', getElement: this.LastReminderDateFilter, customFilterParameters: {placeholder: 'Last Reminder' }  as any
                } as CustomFilter,
            },
            {
                header: 'Actions',
                key: 'button', // String-based value accessors!
                isKey: false,
                dataFormat: this.SignatureFlowReportAction,
                columnClassName: 'button-cell',
                dataSort: false,
                toolTip: false,
                isHidden: false,
                width: '129px',
                className: 'text-field-header header-text',
                filter: { type: 'TextFilter', placeholder: '', style: { display: 'none' } } as TextFilter
            },
        ];

        const SortedColumns: any[] = this.GetSortedColumns(Columns); 

        return <div className="pos-relative">

            <div>
                {SortedColumns.length > 0 &&
                    <BootstrapTable
                        ref='signatureReportTable'
                        data={data}
                        remote={true}
                        fetchInfo={{ dataTotalSize: this.props.totalRows }}
                        options={options}
                        striped
                        bordered={false}
                        containerClass='report-table-container'
                        tableHeaderClass='report-header-border table-header-font'
                        trStyle={{borderBottom:'1px solid #A6A9AC', height: '32px'}}
                        hover={true}
                        selectRow={selectRowProp}>
                        {SortedColumns.map((value, index) => {
                            return <TableHeaderColumn
                                className={value.className ? value.className : ''}
                                key={index}
                                ref={value.key}
                                isKey={value.isKey}
                                dataField={value.key}
                                hidden={value.isHidden}
                                width={value.width}
                                dataFormat={value.dataFormat}
                                columnClassName={value.columnClassName}
                                columnTitle={value.toolTip}
                                filter={value.filter}
                                dataSort={value.dataSort}
                                caretRender={Helper.sortRenderer}>
                                    <span title={value.header} className="table-text-sort">{value.header}</span>
                            </TableHeaderColumn>;
                        })}
                    </BootstrapTable>
                }
            </div>

        </div>
    }
}

export default SignatureArchiveReportTable;
