import { useState, useRef } from "react";
import { ISignatureControl } from "src/SignatureFlow/models/SignatureData";

export const useResizable = (control : ISignatureControl) => {
  const [size, setSize] = useState({ width: control.width, height: control.height });
  const resizableRef = useRef(null);

  const handleMouseDown = (e) => {
    e.preventDefault();

    const startX = e.clientX;
    const startY = e.clientY;
    const startWidth = size.width;
    const startHeight = size.height;

    const doDrag = (e) => {
      setSize({
        width: startWidth + e.clientX - startX,
        height: startHeight + e.clientY - startY,
      });
    };

    const stopDrag = () => {
      document.documentElement.removeEventListener("mousemove", doDrag, false);
      document.documentElement.removeEventListener("mouseup", stopDrag, false);
    };

    document.documentElement.addEventListener("mousemove", doDrag, false);
    document.documentElement.addEventListener("mouseup", stopDrag, false);
  };

  return {
    size,
    resizableRef,
    handleMouseDown,
  };
};
