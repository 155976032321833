import {  addTask } from 'domain-task';
import { Reducer } from 'redux';
import { AppThunkAction } from '../../index';
import { actionTypes } from '../../ActionTypes';
import { API_BASE_URL, SSSUITE_API_BASE_URL } from '../../../../utils/AppConstants';
import { StatusType, NotificationAction } from '../../common/NotificationStore';
import { handleBlob, handleResponse } from '../../Library';
import {
    RequestDeliveredReportDocuments, ReceiveDeliveredReportDocuments,
    RequestSignatureFlowReportDocumentPagesAction, ReceiveSignatureFlowReportDocumentPagesAction,
    ReceiveSignatureFlowReportDocumentPopupModal, ReceiveSignatureFlowReportDocument,
    ResetDeliveredReportsAction, ResetRecycledSignatureReportsAction, ResetSignatureFlowReportDocumentPagesAction,
     SignatureDeliveredReportSendReminder, RecieveDraftDocumentsLockedState , ReceivePrimaryUTELinkedEmail
    } from '../../ReportsCommon/KnownTypes';
import { ISignatureFlowReportTableModel, ISignatureFlowReportState } from '../../../models/SignatureFlowReportTableState';
import { ISignatureFlowReportDocument, ISignerModel, ILinkedMergedDetails,ILinkedMergedDetailsResponse} from '../../../models/SignatureFlowReportState';
import { HeaderResourceId, SignatureFlowReportConstants } from '../../../components/helper/Constants';
import { IClient } from '../../../models/SigningInfo';
import { ResetSigningInfoSettingsAction } from '../../SigningInfoSettingsStore';
import { validateError } from '../../../components/helper/Validations';

import { logger } from '../../../../routes/LoggedIn';

type KnownAction =
    RequestDeliveredReportDocuments |
    RequestSignatureFlowReportDocumentPagesAction |
    ReceiveDeliveredReportDocuments |
    ReceiveSignatureFlowReportDocument |
    ReceiveSignatureFlowReportDocumentPagesAction |
    ReceiveSignatureFlowReportDocumentPopupModal |
    ResetSignatureFlowReportDocumentPagesAction |
    NotificationAction |
    ResetSigningInfoSettingsAction |
    SignatureDeliveredReportSendReminder|
    ReceivePrimaryUTELinkedEmail;

type dispatchAction =
    RequestDeliveredReportDocuments |
    ReceiveDeliveredReportDocuments |
    ReceiveSignatureFlowReportDocument |
    ResetDeliveredReportsAction |
    SignatureDeliveredReportSendReminder|
    ReceivePrimaryUTELinkedEmail ;

export const actionCreators = {
    requestSignatureDeliveredReportDocuments: (query: string, reload: boolean = false, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        if (reload || query !== state.DeliveredReportDocuments.query) {
            let page = state.signatureFlowReportsDocumentPages[query];
            if (!reload && page) {
                dispatch({ type: actionTypes.REQUEST_DELIVERED_REPORT_DOCUMENTS, query: query });
                dispatch({ type: actionTypes.RECEIVE_DELIVERED_REPORT_DOCUMENTS, query: query, table: page.signatureFlowReportTableModel });
                return;
            }
            const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureFlowReports/GetSignatureFlowReports' + query, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(response => response as Promise<ISignatureFlowReportTableModel>)
                .then(data => {
                    if (callback) {
                        callback();
                    }
                    let updatedState = getState();
                    if(updatedState.DeliveredReportDocuments.query == query){ //This condition is added whatever the latest query (call) is, it should be updated in the store.
                        dispatch({ type: actionTypes.RECEIVE_DELIVERED_REPORT_DOCUMENTS, query: query, table: data });
                        dispatch({ type: actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_DOCUMENT_PAGES, query: query, table: data, totalRowCount: data.count });
                        dispatch({ type: actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_DOCUMENT_POPUP_MODAL, signatureFlowReportDetails: data.documents });
                    }
                })
                .catch((error) => {
                    const message = validateError(error);
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: message, statusType: StatusType.Error, statusCode:error?.status });
                    logger.trackError(`requestSignatureReportDocuments failed for the request having query ${query} with error ${message}`)
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_DELIVERED_REPORT_DOCUMENTS, query: query });
            dispatch({ type: actionTypes.REQUEST_SIGNATURE_FLOW_REPORT_DOCUMENT_PAGES, query: query });
        }
    },
    getLinkedMergeDetails:
    (
        emailId: string,
        callback: (linkedMergedDetailsResponse: ILinkedMergedDetailsResponse) => void
    ): AppThunkAction<KnownAction> =>
    (dispatch) => {
        const linkedMergedDetailsResponse: ILinkedMergedDetailsResponse = {
            linkedDetails: [],
            error: false
        };
        fetch(SSSUITE_API_BASE_URL + "api/one-hub/linked-merge-details?emailId=" + emailId, {
            method: "GET",
            credentials: "include"
        })
            .then(handleResponse)
            .then((response) => response as Promise<ILinkedMergedDetails[]>)
            .then((data) => {
                linkedMergedDetailsResponse.linkedDetails = data;
                if (callback) {
                    callback(linkedMergedDetailsResponse);
                }
            })
            .catch((error) => {
                linkedMergedDetailsResponse.error = true;
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof statusMessage === "string") {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: statusMessage,
                        statusType: StatusType.Error
                    });
                }
                callback(linkedMergedDetailsResponse);
            });
    },
    receivePrimaryUTELinkedEmail: (signingId: number, callback?: (email: string | null) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let fetchTask = fetch(API_BASE_URL + `api/SignatureFlow/SignatureFlowReports/GetPrimaryUTELinkedEmail?signingId=${signingId}`, { credentials: 'include' })
            .then(handleResponse)
            .then((email: string) => {
                if (callback) {
                    callback(email);
                }
            })
            .catch((error) => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: "Error fetching primary UTE linked email.", statusType: StatusType.Error, statusCode: error?.status });
                if (callback) {
                    callback(null); // Return null in case of an error
                }
                logger.trackError(`getPrimaryUTELinkedEmail failed for signingId ${signingId} with error ${validateError(error)}`);
            });
    
        addTask(fetchTask);
    },
    resetSignatureFlowReportsDocumentPagesState: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.RESET_SIGNATURE_FLOW_REPORT_DOCUMENT_PAGES });
        dispatch({ type: actionTypes.RESET_SIGNING_INFO_SETTINGS });
    },
    exportSigantureDeliveredReportsAsExcel: (query: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureFlowReports/ExportExcelSignatureReports' + query, { credentials: 'include' })
            .then(handleBlob)
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.target = '_blank';
                link.setAttribute('download', 'SignatureReports.xlsx');
                document.body.appendChild(link);
                link.click();
                link.parentNode && link.parentNode.removeChild(link);
                if (callback) {
                    callback();
                }
            }).catch((error) => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: SignatureFlowReportConstants.ExportExcel.Error, statusType: StatusType.Error, statusCode:error?.status});
                if (callback) {
                    callback();
                }
                logger.trackError(`exportSigantureReportsAsExcel failed for the request having query ${query} with error ${validateError(error)}`)
            });
        addTask(fetchTask);
    },
    updateDeliveredDocumentNote: (signingReport: ISignatureFlowReportDocument, urlPath: string, isDelete?: boolean, isSave?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
            const fetchTask = fetch(urlPath, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8',
                    'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
                },
                body: JSON.stringify({
                    id: signingReport.signingId,
                    message: signingReport.documentNote
                })
            }).then(handleResponse)
                .then(() => {
                    actionCreators.updateSignatureDeliveredReportDocuments(signingReport);
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: isDelete ? SignatureFlowReportConstants.DocumentNote.DeleteSuccess :
                            isSave ? SignatureFlowReportConstants.DocumentNote.SaveSuccess :
                                SignatureFlowReportConstants.DocumentNote.UpdateSuccess,
                        statusType: StatusType.Success
                    });
                })
                .catch((error) => {
                    const message = validateError(error);
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: SignatureFlowReportConstants.DocumentNote.Error,
                        statusType: StatusType.Error, statusCode:error?.status
                    });

                    logger.trackError(`updateDocumentNote failed for the request having parameters ${JSON.stringify(signingReport)} with error ${message}`)
                });
            addTask(fetchTask);
    },
    updateSignatureDeliveredReportDocuments: (signingReport: ISignatureFlowReportDocument, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.RECEIVE_DELIVERED_REPORT_DOCUMENT, id: signingReport.signingId, signatureFlowReportDetails: signingReport });
        if (callback) {
            callback();
        }
    },
    updateSignatureDeliveredReports: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();        
        let queryUpdated = state.DeliveredReportDocuments.query;
        let indexOfAmpersand = queryUpdated.indexOf("&");
        let res = queryUpdated.substr(indexOfAmpersand, queryUpdated.length);
        let hardCodedPageNo = "?pageNo=1";

        queryUpdated = hardCodedPageNo + res;
        dispatch(actionCreators.requestSignatureDeliveredReportDocuments(queryUpdated, true));
    },
    updateDeliveredSignerEmail: (signingReport: ISignatureFlowReportDocument, signers: ISignerModel[], resourceId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + 'api/SignatureFlow/SignatureFlowReports/UpdateSignersEmail', {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8',
                    'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value,
                    [HeaderResourceId]: resourceId
                },
                body: JSON.stringify({
                    signingId: signingReport.signingId,
                    signers: signers
                })
            }).then(handleResponse)
                .then((data: Array<IClient>) => {

                    signingReport?.signerInfo?.forEach((si) => {
                        data?.filter(res => res?.emailAddress === si?.signerEmail)
                            .map(m => si.clientGuid = m.clientGuid.toString());
                    });

                    actionCreators.updateSignatureDeliveredReportDocuments(signingReport);
                    let state = getState().DeliveredReportDocuments;
                    let table = state.signatureFlowReportTableModel;
                    let id: number = table.documents.findIndex(x => x.signingId == signingReport.signingId);
                    table.documents[id] = signingReport;
                    dispatch({ type: actionTypes.RECEIVE_DELIVERED_REPORT_DOCUMENTS, query: state.query, table: table });
                    dispatch({ type: actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_DOCUMENT_PAGES, query: state.query, table: table, totalRowCount: table.count });
                    dispatch({ type: actionTypes.RECEIVE_SIGNATURE_FLOW_REPORT_DOCUMENT_POPUP_MODAL, signatureFlowReportDetails: table.documents });
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: SignatureFlowReportConstants.UpdateSignatureSigners.Success,
                        statusType: StatusType.Success
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: SignatureFlowReportConstants.UpdateSignatureSigners.Error,
                        statusType: StatusType.Error,
                        statusCode:error?.status
                    });

                    logger.trackError(`updateSignerEmail failed for the request having parameters ${JSON.stringify(signingReport)} and ${JSON.stringify(signers)} with error ${validateError(error)}`)
                });
            addTask(fetchTask);
    }
}

const unloadedState: ISignatureFlowReportState = {
    query: '',
    signatureFlowReportTableModel: {
        documents: [],
        count: 0
    } as ISignatureFlowReportTableModel,
    totalRowCount: 0,
    isLoading: false,
    primaryUTELinkedEmail: ''
} as ISignatureFlowReportState

export const reducer: Reducer<ISignatureFlowReportState> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as dispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_DELIVERED_REPORT_DOCUMENTS:
            return {
                ...unloadedState,
                query: action.query,
                isLoading: true
            } as ISignatureFlowReportState;

        case actionTypes.RECEIVE_DELIVERED_REPORT_DOCUMENTS:
            return {
                query: action.query,
                signatureFlowReportTableModel: action.table,
                totalRowCount: action.table.count,
                isLoading: false
            } as ISignatureFlowReportState;

        case actionTypes.RECEIVE_DELIVERED_REPORT_DOCUMENT:
            let newState: ISignatureFlowReportState = { ...state }
            let id: number = newState.signatureFlowReportTableModel.documents.findIndex(x => x.signingId == action.id);
            newState.signatureFlowReportTableModel.documents[id] = action.signatureFlowReportDetails;
            return newState;

        case actionTypes.RESET_DELIVERED_REPORTS:
            return clearSignatureDeliveredReports(state);
        
        case actionTypes.SIGNATURE_DELIVERED_REPORT_SEND_REMINDER:
            let signatureFlowReportState: ISignatureFlowReportState = { ...state }
            action.sendReminder.forEach((item, index) => {
                const rowIndex: number = signatureFlowReportState.signatureFlowReportTableModel.documents.findIndex(i => i.signingId == item.documents[0].signingId);
                signatureFlowReportState.signatureFlowReportTableModel.documents[rowIndex].lastReminder = new Date();
            });
            return signatureFlowReportState;

        case actionTypes.RECEIVE_PRIMARY_UTE_LINKED_EMAIL:
            return {
                ...state,
                primaryUTELinkedEmail: action.primaryEmail
            } as ISignatureFlowReportState;

        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }
    return state;
};

function clearSignatureDeliveredReports(state: ISignatureFlowReportState): ISignatureFlowReportState {
    return {
        ...unloadedState,
        query: state.query,
        isLoading: true,
        primaryUTELinkedEmail: ''
    } as ISignatureFlowReportState;
}


