import * as React from 'react';
import { ISigner } from '../SignatureControlPanel';
import { ISignatureControl, SignatureControlType, SignatureControlRole, SignatureGroupType, ChoosableControlData} from './../../models/SignatureData';
import { getControlText, getControlIcon } from './../helper/SignatureHelper';
import { AddControlSvg, EnableCheckBoxSvg, GripVertical , RadioButtonSvg, ResizeControlSvg} from '../svg/CustomSvgIcons';
import ConditionControlOptions, { ConditionControlOptionsTypes } from '../ConditionalControl/ConditionalControlOptions';
import { IConditionalControlPanelState } from '../ConditionalControl/ConditionalControlPanel';
import { useResizable } from './ResizableControl';

export interface SignatureControlProps {
    control: ISignatureControl,
    selectedSigner: ISigner,
    focusedGroup: SignatureGroupType,
    onControlRemove(control: ISignatureControl): void;
    style: React.CSSProperties;
    onToolTipEdit?: (control: ISignatureControl) => void;
    updateConditionalControl?: (control: ISignatureControl, type: string) => void;
    onConditionalControlOptionsClick?: (option : ConditionControlOptionsTypes, control: ISignatureControl) => void;
    conditionalControlState: IConditionalControlPanelState;
    slectControlForRule: (control: ISignatureControl) => void;
    signerName : string;
    handleResize: (controlGuid: string, height: number, width: number) => void;
    controlColor: string;
    isEditRule: boolean;
}

const SignatureControl: React.FC<SignatureControlProps> = (props) => {
    const { size, resizableRef, handleMouseDown } = useResizable(props.control);

    React.useEffect(() => {
        props.handleResize && props.handleResize(props.control.controlGuid,size.height, size.width)
    },[size])

    const getSelectedSignStyle = (): string => {
        if (props.focusedGroup && props.control.role) {
            if (props.focusedGroup == SignatureGroupType.Sender &&
                props.selectedSigner.value == props.control.signer &&
                props.selectedSigner.signatureRole == SignatureControlRole.SFSender) {
                return "sf-sender-signer-sign-highlight";
            }
            if (props.focusedGroup == SignatureGroupType.Receiver &&
                props.selectedSigner.value == props.control.signer &&
                props.selectedSigner.signatureRole == SignatureControlRole.SFReceipient) {
                return "sf-receipient-signer-sign-highlight";
            }
        }
        return "";
    }

    const onToolTipEdit = (event: any) => {
        if (props.onToolTipEdit) {
            props.onToolTipEdit(props.control);
        }
    }

    const onAddControl = (event: any) => {
        event.stopPropagation();
        if (props.updateConditionalControl) {
            props.updateConditionalControl(props.control,"addControlOption");
        }
    }

    const onOptionCLick = (option: ConditionControlOptionsTypes) => {
        if(props.onConditionalControlOptionsClick){
            props.onConditionalControlOptionsClick(option, props.control);
        }

    }

    const getControlActions = (control: ISignatureControl) => {
        switch(control.type) {
            case SignatureControlType.Text:
                return <span onClick={onToolTipEdit} className="textfield-control fa fa-info-circle" style={{ cursor: 'pointer' }}></span>;
            case SignatureControlType.Signature:
                return <>
                        { control.required && <span style={{top: '1px',marginLeft: '1%'}} className="fas fa-asterisk compulsory-star"></span> }
                        <span 
                            onClick={onToolTipEdit} 
                            className={"textfield-control fa fa-info-circle" + (control.required ? " padL05" : "") } 
                            style={{ cursor: 'pointer' }}>
                        </span>
                    </>;
            case SignatureControlType.Radio:
                return <>
                {props.conditionalControlState.selectedPanelOption === ConditionControlOptionsTypes.Rules ||Number(control.tooltip) === 5 ? null : 
                     <span onClick={onAddControl} className="add-control-btn"> <AddControlSvg fill='#404346'/> </span>}
                </> 
            default:
                return "";
        }
    }

    const isConditionalControlOptionDisabled = (control: ISignatureControl) => {
        const focusedGroup = props.focusedGroup  && props.focusedGroup as Number;
        const controlRole = control.role as Number;

        if(controlRole != focusedGroup){
            return true;
        }

        else if(props.conditionalControlState.selectedPanelOption == ConditionControlOptionsTypes.Rules){
            return true;
        }
        else if(control.signer != props.selectedSigner.value){
            return true;
        }
        return false;
    }

    const createChoosableControls = (control: ISignatureControl, items: ChoosableControlData[]) => {
        let choosableControls: JSX.Element[] = [];
        let icon: string = getControlIcon(control.type);

        if(items) {
            items.forEach((item: ChoosableControlData,index) => {
                choosableControls.push(
                    <div
                        id={item.name}
                        key={"key_" + item.name}
                        data-id={item.name}
                        data-controlguid={control.controlGuid}
                        className={"radio-btn-container choosable-control-item"}
                        title=''
                        onClick={(e) => showConditionalControlOptions(e,control)}
                        style={{
                            top: item.top + 'px',
                            left: item.left + 'px'
                        }}
                    >
                        <span><RadioButtonSvg /></span> Option {item?.controlNumber ? item.controlNumber : index + 1}
                    </div>
                )
            });
        }
        return choosableControls;
    }

    const isControlSelectable = (control: ISignatureControl) => {
        const allSignatureConditionalControls = props.conditionalControlState.conditionalControlsRules;
        const focusedGroup = props.focusedGroup  && props.focusedGroup as Number;
        const controlRole = control.role as Number;


        if(controlRole != focusedGroup){
            return false;
        }
        
        if(control.signer != props.selectedSigner.value){
            return false;
        }

        if(control.controlGuid == props.conditionalControlState.selectedControl.controlGuid){
            return false;
        }

        //finding is control which is already not present in the conditional controls and have some rules
        for (let controls of allSignatureConditionalControls) {
                if (controls.conditionalControl.controlGuid == control.controlGuid && controls.controlOptionsRules.length > 0) {
                  return false;
                }
        }

        //finding is control is not present under in any of the any of the another conditional Control Rules
        for (let controls of allSignatureConditionalControls) {
            for (let rule of controls.controlOptionsRules) {
              for (let selectedControl of rule.selectedControls) {
                if (selectedControl.controlGuid === control.controlGuid) {
                  return false;
                }
              }
            }
        }

        return true;
    }

    const isControlRuleDisabled = (control: ISignatureControl) => {
        const allSignatureConditionalControls = props.conditionalControlState.conditionalControlsRules;
        
        if(props.control.type === SignatureControlType.Radio || props.control.type == SignatureControlType.CheckBox){
            if(allSignatureConditionalControls.length > 0){
                for (let controls of allSignatureConditionalControls) {
                    for (let rule of controls.controlOptionsRules) {
                      for (let selectedControl of rule.selectedControls) {
                        if (selectedControl.controlGuid === control.controlGuid) {
                          return true;
                        }
                      }
                    }
                }
            }
        }

        return false;
    }

    const createChoosableControlRule = (control: ISignatureControl) => {
        if(props.conditionalControlState.selectingFields && isControlSelectable(control)) {
            const customClass  = control.type === SignatureControlType.Radio ? '-radio' : control.type === SignatureControlType.CheckBox ? '-checkbox' : '';
            return <span className={'selcting-field-button' + customClass} 
                         onClick={(e) => { 
                            e.stopPropagation(); 
                            props.slectControlForRule && props.slectControlForRule(control)
                        }}>
                            <AddControlSvg fill='#355ABF'/>
                    </span>
        }
        return null;
    }

    const showConditionalControlOptions = (event:any , control: ISignatureControl) => {

        event.stopPropagation();
        //if user click on the same control or selecting fields
        if(props.conditionalControlState.selectedControl?.controlGuid === control.controlGuid || props.conditionalControlState.selectingFields){
            return;
        }
        else if(props.conditionalControlState.selectedPanelOption != ConditionControlOptionsTypes.None){
            return;
        }
        else if(props.conditionalControlState.selectedPanelOption == ConditionControlOptionsTypes.None && control.type != SignatureControlType.CheckBox && control.type != SignatureControlType.Radio){
            if(props.conditionalControlState.selectedControl?.controlGuid){
                props.onConditionalControlOptionsClick && props.onConditionalControlOptionsClick(null, null);
                return;
            }
            else{
                return;
            }
        }
        else{
            props.onConditionalControlOptionsClick && props.onConditionalControlOptionsClick(ConditionControlOptionsTypes.None, props.control);
        }
        
    }

    const createCheckBoxControl = (control: ISignatureControl) => {
        if(control.type === SignatureControlType.CheckBox){
           return <div className='checkbox-control-action' id={'checkbox_' + props.control.controlGuid}
                key={"checkbox_key_' +" + props.control.controlGuid} onClick={(e) => showConditionalControlOptions(e,control)}><EnableCheckBoxSvg /></div>
        }
        return null;
    }

    const handleControlResize = (event: any) => {
        event.stopPropagation();
        handleMouseDown(event);
    }

    const createResizeAction = (control: ISignatureControl) => {
        if(control.type === SignatureControlType.Radio ){
            return <div className='resizeable_action' onMouseDown={(e) => handleControlResize(e) }><ResizeControlSvg fill={props.controlColor}/></div>
        }
        return null;
    }

    const isConditionalControlVisible = (control: ISignatureControl) => {
        if(props.conditionalControlState.selectedControl?.controlGuid === control.controlGuid){
            return true;
        }
        return false;
    }

    const getControlOptionStyle = () => {
        let isVisible=isConditionalControlVisible(props.control);

        let style: any = {display: isVisible ? 'block' : 'none'};

        if(props.control && props.control.type === SignatureControlType.CheckBox){
            let width = props.style?.width as string;
            style.left = `${((parseFloat(width)+12).toFixed(2))}px`;
        }
        else if(props.control && props.control.type === SignatureControlType.Radio){
            style.right = '-45px';
        }

        return style;
    }

    return (
        <>
        { props.control.type === SignatureControlType.Radio ?
            <>
            <div 
                id={'choosable_control_' + props.control.controlGuid} 
                data-id={props.control.controlGuid}
                data-signerstyle={getSelectedSignStyle()}
                className={'choosable-signature-control'}
                style={props.style}
                title={props.signerName}
                onClick={(e) =>showConditionalControlOptions(e,props.control)}
            >
                {createChoosableControls(props.control, props.control.items)}
                {getControlActions(props.control)}
                {createResizeAction(props.control)}
                <div className='draggable-btn-control'><GripVertical /></div>
            </div>
            {createChoosableControlRule(props.control)}
            </>
        :
            
            <div className={"draggable-signature " + getSelectedSignStyle()} title={props.signerName} style={props.style} onClick={(e) =>showConditionalControlOptions(e,props.control)}>
                <span ><GripVertical /></span>
                {createChoosableControlRule(props.control)}
                {getControlText(props.control.type)}
                {createCheckBoxControl(props.control)}
                {props.conditionalControlState.selectedPanelOption != ConditionControlOptionsTypes.Rules &&
                    <>
                        {getControlActions(props.control)}
                        {props.control.type != SignatureControlType.CheckBox &&
                            <span
                            onClick={() => { props.onControlRemove(props.control) }}
                            aria-disabled={false}
                            className="remove-handle glyphicon glyphicon-remove"
                            style={{ cursor: 'pointer', float: "right", lineHeight: 1, margin: 0, position:'absolute' }}>
                            </span>
                        }
                    </>
                }
            </div>  
        }

        {props.control.type === SignatureControlType.Radio || props.control.type == SignatureControlType.CheckBox ? <>
            <ConditionControlOptions style = {getControlOptionStyle()} isControlRuleDisabled={isControlRuleDisabled(props.control)} onOptionClick={onOptionCLick} control={props.control} isEditRule={props.isEditRule} isConditionalControlOptionDisabled={isConditionalControlOptionDisabled(props.control)}/>
        </>: null}
        </>
    ); 
}

export default SignatureControl;