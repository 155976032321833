export interface ISignatureData {
    pageNo: number;
    signatureControls: ISignatureControl[];
    signatureControlsHistory: ISignatureControlsHistory[];
}

export interface IConditionalControlRule{
    conditionalControl : ISignatureControl;
    controlOptionsRules : IControlOptionsRule[];
}

export interface IControlOptionsRule{
    ruleId: number;
    selectedOption: ChoosableControlData;
    selectedControls: ISignatureControl[];
}

export interface ISignatureControlsHistory {
    signatureControl: ISignatureControl;
    controlHistoryType: ControlHistoryType
}

export interface ISignatureControl {
    controlGuid: string;
    signer: string;
    type: SignatureControlType;
    role: SignatureControlRole;
    top: number;
    left: number;
    height: number;
    width: number;
    required: boolean;
    tooltip: string;
    items: ChoosableControlData[];
    controlName?: string;
}

export interface ChoosableControlData {
    id: string;
    controlNumber: number;
    name: string;
    top: number;
    left: number;
    width: number;
    height: number;
    value: any;
}

export enum ControlHistoryType {
    None = 0,
    Added = 1,
    Removed = 2,
    Updated = 3
}

export enum SignatureGroupType {
    None = 0,
    Sender = 1,
    Receiver = 2
}

export enum SignatureControlRole {
    None = 0,
    SFSender = 1,
    SFReceipient = 2
}

export enum SignatureControlType {
    None = 0,
    Signature = 1,
    Initial = 2,
    Date = 3,
    NameAndTitle = 4,
    Company = 5,
    Text = 6,
    Name = 7,
    Title = 8,
    CheckBox = 9,
    PrintName = 10,
    Radio=11
}

export const initialSignatureControl: ISignatureControl = {
    controlGuid: "",
    signer: "",
    type: SignatureControlType.None,
    top: 0,
    left: 0,
    height: 0,
    width: 0,
    role: SignatureControlRole.None,
    tooltip: "",
    required: false,
    items: []
}
