import React from 'react';
import { OverlayLoader } from '../../../components/helper/OverlayLoader';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Link } from 'react-router-dom';

interface UnlockOneHubAccountsProps {
    show: boolean;
    onUnlockOneHubAccount(emailAddress: string): void;
    lockedEmailAddresses: string[];
}

interface IColumnValues {
    recipient: string;
}

const UnlockOneHubAccounts: React.FC<UnlockOneHubAccountsProps> = props => {
    const loadingMessage = 'Loading, please wait...';

    const ellipsisType = (cell: any) => {
        return (
            <div title={cell} className="custom-ellipsis">
                {cell}
            </div>
        );
    };

    const unlockUTEAccountLink = (cell: any, row: IColumnValues) => {
        return (
            <Link
                to={'#'}
                onClick={() => {
                    props.onUnlockOneHubAccount(row.recipient);
                }}
            >
                {'Unlock'}
            </Link>
        );
    };

    const unlockOneHubAccountsColumnName = [
        {
            header: 'Recipient(s)',
            key: 'recipient',
            isKey: true,
            dataFormat: ellipsisType,
            toolTip: true,
            width: '80%',
            columnClassName: '',
        },
        {
            header: 'Client Portal',
            key: 'Client Portal',
            isKey: false,
            dataFormat: unlockUTEAccountLink,
            toolTip: false,
            width: '20%',
            columnClassName: '',
        },
    ];

    return props.lockedEmailAddresses && props.lockedEmailAddresses.length > 0 ? (
        <>
            <div className="onehub-access-code-table" style={{ marginTop: '25px' }}>
                <BootstrapTable
                    headerStyle={{ background: 'lightgray' }}
                    data={props.lockedEmailAddresses.map(email => ({ recipient: email }))}
                    containerClass="report-table-container"
                    tableHeaderClass="report-header-border"
                    options={{ noDataText: 'No data to display' }}
                    bordered={false}
                    striped
                >
                    {unlockOneHubAccountsColumnName.map((value, index) => {
                        return (
                            <TableHeaderColumn
                                key={index}
                                isKey={value.isKey}
                                dataField={value.key}
                                dataFormat={value.dataFormat}
                                className={value.columnClassName}
                                columnTitle={value.toolTip}
                                width={value.width}
                            >
                                {value.header}
                            </TableHeaderColumn>
                        );
                    })}
                </BootstrapTable>
            </div>
        </>
    ) : (
        <OverlayLoader show={props.show} text={loadingMessage} width="94%" />
    );
};

export default UnlockOneHubAccounts;
