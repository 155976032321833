import React from "react";
import { Link } from "react-router-dom";
import { IOnehubAccessCodeModel, OnehubAccessCodeStatus } from "../../common/OneHubAccessCodeModal";
import { Alert } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { OverlayLoader } from '../../../components/helper/OverlayLoader';
import { InfoIcon, SuccessTickIcon } from '../../svg/CustomSvgIcons';
import { ISignatureFlowReportDocument } from "src/SignatureFlow/models/SignatureFlowReportState";
let moment = require('moment');
interface IOnehubAccessCodeProps {
    selectedDocument: ISignatureFlowReportDocument;
    show: boolean;
    onGenerateOnehubAccessCode(primaryEmailAddress: string, callback: () => void): void;
    oneHubAccessModel: IOnehubAccessCodeModel[];
}

interface IOnehubAccessCodeState {
    message: string;
    hideInfo: boolean;
}

interface IColumnValues {
    clientName: string;
    clientEmailId: string;
    otpDate: string;
    accessCode: string;
    status: OnehubAccessCodeStatus;
}

const msg = {
    saving: "Generating OTP...",
    loading: "Loading, please wait...",
    generateTooltip: "Generate Access Code",
    info: "Please note that generating a new code will not result in the sending of a new email."
};
const successMsg = {
    generatedAccessCode: "Access code generated successfully"
}
export class OnehubAccessCode extends React.Component<IOnehubAccessCodeProps, IOnehubAccessCodeState> {
    constructor(props: IOnehubAccessCodeProps) {
        super(props);
        this.state = {
            message: msg.loading,
            hideInfo: false
        };
    }

    componentDidMount() {
        this.setState({ hideInfo: false });
    }

    private createdOnDataFormat = (cell: any, row: IColumnValues) => {
        if (row.status === OnehubAccessCodeStatus.Active) {
            const formattedDate =  moment.utc(cell).local().format('MM/DD/YYYY');
            return formattedDate;
        } else {
            return "NA";
        }
    };

    private getAccessCodeDataType = (cell: any, row: IColumnValues) => {
        if (row.status === OnehubAccessCodeStatus.Active) {
            return row.accessCode;
        } else {
            return "NA";
        }
    };

    private showOnehubAccessCodeStatus = (cell: any, row: IColumnValues) => {
        if (row.status === OnehubAccessCodeStatus.Active) {
            return (
                <>
                    <span className="active" title="Active">
                        <span className="status-text">Active</span>
                    </span>
                </>
            );
        } else if (row.status === OnehubAccessCodeStatus.Authenticated) {
            return (
                <>
                    <span className="authenticated" title="Authenticated">
                        <span className="status-text">Authenticated</span>
                    </span>
                </>
            );
        } else if (row.status === OnehubAccessCodeStatus.Expired) {
            return (
                <>
                    <span className="expired" title="Expired">
                        <span className="status-text">Expired</span>
                    </span>
                </>
            );
        } else {
            return (
                <>
                    <span className="notRequested" title="Not Requested">
                        <span className="status-text">Not Requested</span>
                    </span>
                </>
            );
        }
    };

    private generateOnehubAccess(cell: any, row: IColumnValues) {
        return (
            <Link
                to={"#"}
                onClick={() => {
                    this.OnGenerateOnehubAccessCode(row);
                }}
            >
                {" "}
                {msg.generateTooltip}
            </Link>
        );
    }

    private OnGenerateOnehubAccessCode(row: IColumnValues) {
        this.setState({ message: msg.saving, hideInfo: true }, () => {
            this.props.onGenerateOnehubAccessCode(row.clientEmailId, () => {
                this.setState({ hideInfo: true });
            });
        });
    }

    private ellipsisType(cell: any) {
        return (
            <div title={cell} className="custom-ellipsis">
                {cell}
            </div>
        );
    }

    public render() {
        let oneHubViewAccessCodeData: any[] = [];
        const oneHubAccessCodeColumn = [
            {
                header: "Recipient Name",
                key: "clientName",
                isKey: false,
                dataFormat: this.ellipsisType,
                toolTip: true,
                width: "130px",
                columnClassName: ""
            },
            {
                header: "Recipient Email ID",
                key: "clientEmailId",
                isKey: false,
                dataFormat: this.ellipsisType,
                toolTip: true,
                width: "170px",
                columnClassName: ""
            },
            {
                header: "Date",
                key: "otpDate",
                isKey: true,
                dataFormat: this.createdOnDataFormat,
                toolTip: true,
                width: "130px",
                columnClassName: ""
            },
            {
                header: "Access Code",
                key: "accessCode",
                isKey: false,
                dataFormat: this.getAccessCodeDataType,
                toolTip: true,
                width: "115px",
                columnClassName: ""
            },
            {
                header: "Status",
                key: "status",
                isKey: false,
                toolTip: true,
                dataFormat: this.showOnehubAccessCodeStatus,
                width: "150px",
                columnClassName: ""
            },
            {
                header: "Action",
                key: "action",
                isKey: false,
                dataFormat: this.generateOnehubAccess.bind(this),
                width: "200px",
                toolTip: msg.generateTooltip,
                columnClassName: ""
            }
        ];

        if (this.props.oneHubAccessModel && this.props.oneHubAccessModel?.length > 0) {
            oneHubViewAccessCodeData = this.props.oneHubAccessModel.map((value) => {
                return {
                    clientName: value.clientName,
                    clientEmailId: value.clientEmail,
                    otpDate: !value.otpDate ? "" : value.otpDate.toString()?.split("T")[0],
                    accessCode: !value.accessCode ? "" : value.accessCode,
                    status: value.accessCodeStatus
                };
            });
        }

        return this.props.oneHubAccessModel && this.props.oneHubAccessModel?.length > 0 ? (
            <>
                <Alert variant="success" hidden={!this.state.hideInfo}>
                    <SuccessTickIcon />
                    <span className="toaster-message">{successMsg.generatedAccessCode}</span>
                </Alert>
                <Alert variant="info" hidden={this.state.hideInfo}>
                    <InfoIcon />
                    <span className="toaster-message">{msg.info}</span>
                </Alert>
                <div className="onehub-access-code-table" style={{ marginTop: "25px" }}>
                    <BootstrapTable headerStyle={{ background: "lightgray" }} data={oneHubViewAccessCodeData}
                    containerClass='report-table-container'
                    tableHeaderClass='report-header-border'
                    options={{noDataText:'No data to display'}}
                    bordered={false}
                    striped>
                        {oneHubAccessCodeColumn.map((value, index) => {
                            return (
                                <TableHeaderColumn
                                    key={index}
                                    isKey={value.isKey}
                                    dataField={value.key}
                                    dataFormat={value.dataFormat}
                                    className={value.columnClassName}
                                    columnTitle={value.toolTip}
                                    width={value.width}
                                >
                                    {value.header}
                                </TableHeaderColumn>
                            );
                        })}
                    </BootstrapTable>
                </div>
            </>
        ) : (
            <OverlayLoader show={this.props.show} text={this.state.message} width="94%" />
        );
    }
}

export default OnehubAccessCode;
