import React from 'react';
import { DeleteHeaderIcon } from '../svg/CustomSvgIcons';



export interface IConditionalControlPanelButtonsProps {
    onButtonClick: (type: string) => void;
    showUpdateButton: boolean;
}

const ConditionalControlPanelButtons :React.FC<IConditionalControlPanelButtonsProps> = (props) => {
    return (
        <div className='conditional-control-panel-buttons'>
            <button
                    className='discard-button' 
                    onClick={() => { props.onButtonClick('discard') }}>
                    
                    Discard
            </button>
            <button
                    className='save-button' 
                    onClick={() => { props.onButtonClick('addRules')  }}>
                    {props.showUpdateButton ? 'Update' : 'Save Rule'}
            </button>
            <span data-testid="b01b9182-af42-4f17-b9ca-cfee9a8b4c54" onClick={() => { props.onButtonClick('deleteControlRules')  }}><DeleteHeaderIcon/></span>
        </div>
    );
}

export default ConditionalControlPanelButtons;