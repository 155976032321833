import React, { FC, useState, useEffect } from 'react';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import UnlockOneHubAccounts from './UnlockOneHubAccounts';

interface IUnlockRecipientsModalProps {
    show: boolean;
    lockedEmailAddresses: string[];
    isLoading: boolean;
    onCancel(): void;
    onUnlockOneHubAccount(emailAddress: string): void;
}

const tabKeyNames = {
    directDocument: 'directDocumentTab',
    oneHub: 'oneHubTab',
};

export const UnlockRecipientsModal: FC<IUnlockRecipientsModalProps> = props => {
    return (
        <>
            <Modal
                centered={true}
                show={props.show}
                className="unlock-recipients-modal"
                onHide={props.onCancel}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="unlock-recipients-header">Unlock Recipient(s)</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Tabs className="custom-tab" fill id="direct_document" activeKey={tabKeyNames.oneHub}>
                        <Tab
                            eventKey={tabKeyNames.oneHub}
                            title={
                                <div>
                                    <span className="view-acces-code-tab">Client Portal</span>
                                </div>
                            }
                            style={{ overflowX: 'hidden' }}
                        >
                            <UnlockOneHubAccounts
                                show={props.show}
                                lockedEmailAddresses={props.lockedEmailAddresses}
                                onUnlockOneHubAccount={props.onUnlockOneHubAccount}
                            />
                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Row style={{ display: 'contents' }}>
                        <Col sm={4}>
                            <Button onClick={props.onCancel} className="close-button">
                                <span className="close-button-text">Close</span>
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
};
