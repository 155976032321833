import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface IDeleteConditionalControlPopupProps {
    showDeleteConditionalControlPopup: boolean;
    onHideDeleteConditionalControlPopup: () => void;
}


const DeleteConditionalControlPopup: React.FC<IDeleteConditionalControlPopupProps> = (props) => {
    return (
        <Modal className='delete-conditional-control-popup' centered={true} show={props.showDeleteConditionalControlPopup} onHide={props.onHideDeleteConditionalControlPopup} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>
                        Action Restricted
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='marB25' style={{color:'#1C2B36'}}>
                            A conditional rule is currently applied to this control. <br/>
                            To modify the control, please delete the rule first, then <br/>
                            proceed with your changes.
                    </div>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant='info'
                        className=' modal-footer-button-save'
                        onClick={props.onHideDeleteConditionalControlPopup}
                        data-test-auto="58D8BFE5-4906-4314-BBF3-C9E173A2A4B2">
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
    );
}

export default DeleteConditionalControlPopup;
