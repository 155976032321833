import  React, { forwardRef, useCallback, useEffect, useRef } from 'react';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { ISigningInfo } from '../models/SigningInfo';
import { SignatureFlowConstants } from './helper/Constants';
import { FormControl, Row } from 'react-bootstrap';
import { IDropdown, IUserModel } from '../../Core/ViewModels/User/UserViewModel';
import { EMPTY_STRING, daysDropdown, prepareDropDownData } from './helper/HelperFunctions';
import { useImperativeHandle, useState } from 'react';
import { CalendarCheck, DropdownArrow, PaperClip, XLg } from '../components/svg/CustomSvgIcons';
import DropdownComponent from './common/DropdownComponent';
import { CustomDatePicker } from './common/CustomDatePicker';
import { Button } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { IDocumentType, ISignatureFlowSettings } from '../models/SignatureFlowSettings';
import { debounce, isArray, trim } from 'lodash';
import  moment from "moment";
import { DocumentUploadData } from '../models/DocumentInfo';
import { VenusNotifier } from './helper/VenusNotifier';
import * as Constants from './helper/Constants';
import Select from 'react-select';

interface SFStep4Props {
    signingInfo: ISigningInfo;
    signatureFlowSettings: ISignatureFlowSettings;
    users: IUserModel[];
    isOutForSignature: boolean
    modifyAccessCodeAuthentication: (isEnabled: boolean) => void;
    userResources: string[]
    updateClientName: (clientName: string) => void;
    updateClientId: (clientId: string) => void;
    onRef?: (ref: any) => any;
    documentTypes: IDocumentType[];
    requestDocumentTypes: () => void;
    updateDocumentUploadData: (documentInfo: DocumentUploadData[]) => void;
    documentUploadData: DocumentUploadData[];
    onModifyReminderEnabled: (enabled: boolean) => void,
    onModifyReminderDays: (reminderDays: number) => void,
    onChangeNotifyUser: (notifyId: number) => void,
    onChangeContactPerson: (userId: number) => void,
    deleteUploadedSFDocument: (documentKey: string, storageAccountId: number, callback?: () => void) => void;
    deleteDocumentData: (documentGuid: string) => void;
    getClientInfo: () => void;
    officeLocations: IDropdown[];
    updateOfficeLocation: (officeLocation: number) => void;
    currentUserId: number
}

interface SFStep4State {
    documentUploadData: DocumentUploadData[];
    showValidationError:{
        clientName:boolean,
        officeLocation: boolean
    }
}

const initialStep4State: SFStep4State = {
    documentUploadData: [],
    showValidationError: {
        clientName: false,
        officeLocation: false
    }
}


const SFStep4 = forwardRef((props:SFStep4Props, ref: any) => {
    const searchDelay = 500;

    //Used to prevent calling getClientInfo on initial load
    const loadClientInfo = useRef(false);

    const [step4State, setStep4State] = useState<SFStep4State>(initialStep4State);
    const [editableRow, setEditableRow] =useState(null);
    const [isFileDeleting,setIsFileDeleting ] = useState(false)

    useEffect(()=>{
        const documentUploadData = JSON.parse(JSON.stringify(props.documentUploadData));
        setStep4State({...step4State,documentUploadData:documentUploadData});
        
    },[props.documentUploadData])

    useEffect(() => {
        if(loadClientInfo.current){
            if(loadClientInfo.current && trim(props.signingInfo.clientId).length > 0) deBounceCallback();
        }
        else {
            loadClientInfo.current = true;
        }
    },[props.signingInfo.clientId])

    const deBounceCallback = useCallback(
        debounce(() => {
        props.getClientInfo();
    }, searchDelay),[])

    const modifyClientNameValidationError = (show: boolean) => {
        setStep4State({...step4State,
                showValidationError:{...step4State.showValidationError, clientName: show}})
    }

    const modifyLocationValidationError = (show: boolean) => {
        setStep4State({...step4State,
                showValidationError:{...step4State.showValidationError, officeLocation: show}})
    }

    const updateClientName = (event: any) => {
        if(step4State.showValidationError.clientName){
            modifyClientNameValidationError(false)
        }
        props.updateClientName(event.target.value);
    }

    const updateOfficeLocation = (locationId: number) => {
        if(step4State.showValidationError.officeLocation){
            modifyLocationValidationError(false)
        }
        props.updateOfficeLocation(locationId)
    }

    const updateClientId = (event: any) => {
        props.updateClientId(event.target.value);
    }

    const clearClientId = () => {
        props.updateClientId(EMPTY_STRING);
    }

    const clearClientName = () => {
        props.updateClientName(EMPTY_STRING);
    }

    const updateReminderEnabled = (event: any) => {
        props.onModifyReminderEnabled(event.target.checked)
    }

    const updateReminderDays = (event: any) => {
        props.onModifyReminderDays(event.value)
    }
    const checkisUpdateOpen = () =>  editableRow == null ? false : true;

    useImperativeHandle(ref, () => ({
        modifyClientNameValidationError,
        modifyLocationValidationError,
        checkisUpdateOpen
    }));

    const fileNameFormat = (cell: any, row: any) => {
        return <div style={{width: '90%'}} title={cell} className="ellipsis"><PaperClip/>  {cell}</div>
    }

    const setDocumentExpiryDate = (documentType: number) => {
        if (documentType == null || documentType == undefined) {
            return undefined;
        }
        let temp = props.documentTypes.filter((item, index) => {
            return item.id == documentType;
        });

        let result = (temp[0].expiration != null || temp[0].expiration != undefined) ?
            moment(new Date()).add(temp[0].expiration, 'months') : moment(new Date());
        return result.toDate();
    }

    const handleExpiryDate = (row: any, value: any) => {
        let _gridData = [...step4State.documentUploadData];

        _gridData.map((gridData, index) => {
            if (gridData.number == row.number) {
                gridData.expireon = value ? value : undefined;
            }
        });
        setStep4State({...step4State,documentUploadData:[..._gridData]})
    }

    const handleDocumentType = (row: any, value: any) => {
        let _gridData = [...step4State.documentUploadData];

        _gridData.map((gridData, index) => {
            if (gridData.number == row.number) {
                gridData.documentType = (value == null || value == undefined) ? 0 : value;
                gridData.expireon = setDocumentExpiryDate(value);
            }
        }); 
        setStep4State({...step4State,documentUploadData:[..._gridData]})
    }

    const selectFormat = (cell: any, row: any) => {
        const documentType = props.documentTypes && props.documentTypes.find(e => e.id == cell)
        return <div className='reviewDocumentType' style={{width:'90%'}}>
            {row.number != editableRow ? <div title={documentType?.description} className='font16 ellipsis'>{documentType?.description}</div> : 
                <DropdownComponent
                id="reviewDocumentType"
                options={setDocumentTypes(props.documentTypes)}
                selectedValue={cell}
                onChange={(value) =>handleDocumentType(row,value)}
                customPlaceHolder="Document Type"
                searchable={false}
                clearable={false}
                arrowRenderer={() => <DropdownArrow/>} />
            }
        </div>
    }

    const dateFormat = (cell: any, row: any) => {
        let mindate = new Date();
        mindate.setMonth(mindate.getMonth() + 1);
        let date =moment(cell).format(('MM/DD/YYYY'))
        return <div>
                     {row.number != editableRow ? <div title={date} className='font16 ellipsis'>{date}</div> : 
                    <CustomDatePicker
                        placeholder='Select Date'
                        value={cell}
                        onChange={(value: any) => { handleExpiryDate(row, value) }}
                        minDate={mindate}
                        showClearButton={false}
                        customOpenIcon={<span className='padR05'><CalendarCheck/></span>}
                    />}
                </div> 
    }

    const setDocumentTypes = (documentTypes: IDocumentType[]) => {
        let filterTypes: IDocumentType[] = documentTypes;
        if (documentTypes.length > 0) {
            filterTypes = documentTypes.filter(x => x.isActive);
        }
        let documentTypesArray: any = [];
        filterTypes && isArray(filterTypes) && filterTypes.map((documentType: IDocumentType) =>
            documentTypesArray.push({
                value: documentType.id,
                label: documentType.description
            }));

        return documentTypesArray;
    }

    const updateFileData = (row: any) =>{
        if(step4State.documentUploadData[row.number - 1].documentType == 0){
            VenusNotifier.Warning(Constants.SFDocument.documentTypeRequired, null);
            return;
        }
        else if (step4State.documentUploadData[row.number - 1].expireon == null){
            VenusNotifier.Warning(Constants.SFDocument.documentExpirationDateRequired, null);
            return;
        }
        else{
            props.updateDocumentUploadData([...step4State.documentUploadData])
            setEditableRow(null)
        }

    }

    const deleteFile = (row:any) =>{
        setIsFileDeleting(true);
        
        props.deleteUploadedSFDocument(row.sasGuid, row.storageAccountId, () => {

            if (row.sourceFileGuid) {
                props.deleteUploadedSFDocument(row.sourceFileGuid, row.storageAccountId);
            }

            props.deleteDocumentData(row.sasGuid);
            var _gridData = props.documentUploadData.map(data => {
                return {...data}
            });
            _gridData = _gridData.filter(i => i.rowIndex != row.rowIndex);

            //Update row index
            for (let i = 0; i < _gridData.length; i++) {
                _gridData[i].rowIndex = i;
                _gridData[i].number = i + 1;
            }
            props.updateDocumentUploadData(_gridData);
            setIsFileDeleting(false);
            setEditableRow(null)
            });
    }

    const userList = (): { value: string, label: string }[] => {
        let columnValues: { value: string, label: string }[] = [];
        props.users.map((person: IUserModel) => {
            columnValues.push({
                value: person.userId.toString(),
                label: `${person.firstName} ${person.lastName}`,
            })
        });
        return columnValues;
    }

    const editFileData = (row : any) => {
        if(editableRow){
          const documentUploadData =  props.documentUploadData.map(data => {
                return {...data}});
        setStep4State({...step4State,documentUploadData:documentUploadData})
        }
        setEditableRow(row.number)
    }
    const actionsButton = (cell: any, row: any) => {
        return <div className='reviewFilesActions dispFlex'>          
            {row.number == editableRow  ? 
            <Button className='fontWeight700' onClick={()=>updateFileData(row)} variant='' size='sm' type='reset' >Update</Button> : 
            <Button style={{paddingLeft:'26px'}} variant='' size='sm' type='reset' onClick={()=>editFileData(row)}>Edit</Button>
            }
            <hr/>
            <Button title={props.signingInfo.documentInfo.length == 1 ? SignatureFlowConstants.LastDocumentDeleteNote : ''}
            onClick={()=>{deleteFile(row)}} variant='' size='sm' type='reset'
            disabled={(props.signingInfo.documentInfo && props.signingInfo.documentInfo.length == 1 ) }>Delete</Button>            
        </div>
    }

    const getContactPerson = () => {
        if(props.signingInfo.contactPersonId !== undefined && props.signingInfo.contactPersonId == -1){
            props.onChangeContactPerson(props.currentUserId);
            return props.currentUserId;
        }
        return props.signingInfo.contactPersonId;
    }

    const columns = [
        {
            header: 'File Name',
            key: 'name',
            isKey: true,
            width: '33%',
            dataFormat: fileNameFormat,
            className: '',
            columnClassName: 'word-Visible text-left-align fontSize16 fontWeight700 nopadding'
        },
        {
            header: 'Document Type',
            key: 'documentType',
            width:'21%',
            isKey: false,
            dataFormat: selectFormat,
            className: '',
            columnClassName: 'overflowVisible text-left-align fontSize14 nopadding',
        },
        {
            header: 'Expiration Date',
            key: 'expireon',
            isKey: false,
            width:'22%',            
            dataFormat: dateFormat,
            className: '',
            columnClassName: 'overflowVisible fontSize14  nopadding',
        },
        {
            header: 'Actions',
            key: 'button',
            isKey: false,
            width:'20%',
            className: 'padL28',
            dataFormat: actionsButton,
            columnClassName: 'fontSize16 nopadding',
        }
    ];

    return (<LoadingOverlay>
                <div className="step-4">
                    <div>
                        <Row className='fontWeight700 fontSize15'>
                            Document Details
                        </Row>
                        <Row className="form-group padT15">
                            <div className='fontSize16 fontWeight600 width118 padT03'>
                                Client ID
                            </div>
                            <div style={{display: 'inline-grid'}}>
                                <div className='d-inline-flex'>
                                    <FormControl type="text"
                                        data-test-auto={"df7bb6e1-6d36-4283-b2a7-f34fb5f87ec0"}
                                        className='input-field ellipsis'
                                        placeholder='Client ID'
                                        value={props.signingInfo.clientId ? props.signingInfo.clientId : ''} 
                                        onChange={(event: any) => updateClientId(event)}
                                        title={props.signingInfo.clientId}/>
                                    <span className={'input-clear-icon ' + 
                                                    (props.signingInfo.clientId.length == 0 ?
                                                        "hidden" : "")} 
                                        onClick={clearClientId} 
                                        data-test-auto={"bb37d0cf-d52c-4961-a482-f7c8ee712451"}>
                                            <XLg color='#212529'/>
                                    </span>
                                </div>
                            </div>
                        </Row>
                        <Row className="form-group">
                            <div className='fontSize16 fontWeight600 width118 padT03'>
                                Client Name
                            </div>
                            <div style={{display: 'inline-grid'}}>
                                <div className='d-inline-flex'>
                                    <FormControl type="text"
                                        data-test-auto={"92d7eff9-3a05-4f47-a3f9-822b2484ada7"}
                                        className={'input-field ellipsis ' + (step4State.showValidationError.clientName && 'show-validation-field')}
                                        placeholder='Client Name'
                                        value={props.signingInfo.recipientName ? props.signingInfo.recipientName : ''}
                                        onChange={(event: any) => updateClientName(event)}
                                        title={props.signingInfo.recipientName}/>
                                    <span className={'input-clear-icon ' +
                                                    (props.signingInfo.recipientName.length == 0 ?
                                                        "hidden" : "")}
                                        onClick={clearClientName} 
                                        data-test-auto={"baaa0294-1f2b-483c-aae8-137436ac6144"}>
                                            <XLg color='#212529' />
                                    </span>
                                </div>
                                {step4State.showValidationError.clientName &&  
                                    <span className='show-validation-text custom-transform'>This field is required.</span>
                                }
                            </div>
                        </Row>
                        <Row className='reviewOfficeLocationDropdown form-group drpoDown-custom-scroll-bar'>
                            <div className='fontSize16 fontWeight600 width118 padT05'> Office Location </div>
                            <div>
                                <DropdownComponent
                                    id="reviewOfficeLocationDropdown"
                                    className={step4State.showValidationError.officeLocation && 'show-location-validation-field'}
                                    options={prepareDropDownData(props.officeLocations)}
                                    arrowRenderer={() => <span><DropdownArrow/></span>}
                                    clearRenderer={() => <span> <XLg color='#212529' /> </span>}
                                    onChange={(value) => {updateOfficeLocation(value)}}
                                    selectedValue={props.signingInfo.locationId}
                                    searchable={false}
                                    menuStyle={{ maxHeight: '126px' }}
                                    data-test-auto={"395ed86f-2dba-44f7-8ca3-fb52d0b2ae94"}
                                />
                                {step4State.showValidationError.officeLocation &&  
                                    <span className='show-validation-text custom-transform'>This field is required.</span>
                                }
                            </div>
                        </Row>
                        <Row >
                            <div className={step4State.documentUploadData.length > 5 ? 'reviewFileTable' : ''} style={{width: '80%'}}>
                                <BootstrapTable data={step4State.documentUploadData}
                                    bodyContainerClass={'overflowVisible'}
                                    tableHeaderClass='file-upload-table-header'                                   
                                    trStyle={{borderBottom:'1px solid #A6A9AC', height: '40px'}}
                                    bordered={false} >
                                    {columns.map((value, index) => {
                                        return <TableHeaderColumn key={index} isKey={value.isKey} dataField={value.key}
                                        dataFormat={value.dataFormat}      
                                        className={value.className}
                                        width={value.width ? value.width : null}
                                        columnClassName={value.columnClassName}                                                             
                                        dataAlign={"left"}>
                                            <span title={value.header} className='fontWeight400'>{value.header}</span>
                                        </TableHeaderColumn>;
                                    })}
                                </BootstrapTable>
                            </div>
                        </Row>
                        <Row className='fontWeight700 fontSize15' style={{marginTop:'38px'}}>
                            Email Notification
                        </Row>
                        <Row className='defaultSigningEvents' data-test-auto="05cd93d8-7695-412a-b4da-95614219e7f2">
                        <div className='padR15 fontSize16' style={{paddingTop:'10px'}}>Notify about signing event to:</div>
                        <DropdownComponent
                            id="reviewDefaultSigningEvents"
                            options={userList()}
                            arrowRenderer={() => <span><DropdownArrow/></span>}
                            clearRenderer={() => <span> <XLg color='#212529' /> </span>}
                            onChange={(event) => {props.onChangeNotifyUser(event)}}
                            selectedValue={props.signatureFlowSettings.eventNotifySettings.notifyUserId}
                            searchable={true}
                            menuStyle={{ maxHeight: '126px' }}
                            data-test-auto="A97096D1-35C5-4428-AB53-BCBEA46B12DA"
                        />
                        </Row>
                        
                        <Row className='fontWeight700 fontSize15' style={{marginTop:'38px'}}>
                            Contact Person
                        </Row>
                        <Row className='defaultSigningEvents' data-test-auto="e19be43f-2ec7-4ce5-864b-075235d449ce">
                        <div  className='padR15 fontSize16' style={{paddingTop:'10px'}}>Please select contact person:</div>
                        <DropdownComponent
                            id="contactPersonId"
                            options={userList()}
                            arrowRenderer={() => <span><DropdownArrow/></span>}
                            clearRenderer={() => <span> <XLg color='#212529' /> </span>}
                            onChange={(event) => {props.onChangeContactPerson(event)}}
                            selectedValue={ getContactPerson()}
                            searchable={true}
                            menuStyle={{ maxHeight: '126px' }}
                            data-test-auto="A97096D1-35C5-4428-AB53-BCBEA46B12DA"
                        />
                        </Row>
                        <Row className='fontWeight700 fontSize15' style={{marginTop:'38px'}}>
                            Reminders
                        </Row>
                        <Row className='step4-reminder' data-test-auto="b5a53416-efd5-471b-b289-e454112a869d">
                            <div className="automated-reminder-row">
                            <label className="toggleSwitch">
                                <input type="checkbox"
                                    checked={props.signatureFlowSettings.reminderSettings.signingReminder.enabled}
                                    onChange={updateReminderEnabled} />
                                <span className="columnFilterSlider round"></span>
                            </label>
                            </div>
                            <div className='fontSize16' style={{paddingTop:'8px'}}>Send automatic reminders every</div>
                            <Select
                                name="signing-reminder-select"
                                autosize={false}
                                searchable={false}
                                arrowRenderer={()=><span><DropdownArrow/></span>}
                                value={props.signatureFlowSettings.reminderSettings.signingReminder.reminderDays}
                                onChange={updateReminderDays}
                                options={daysDropdown()}
                                data-test-auto=""
                                clearable={false}
                                disabled={!props.signatureFlowSettings.reminderSettings.signingReminder.enabled}
                                menuStyle={{width: '100px', backgroundColor:'#fff', maxHeight:'126px'}} />
                            <div className='fontSize16' style={{paddingTop:'8px', marginLeft:'11px'}}>until is completed.</div>
                        </Row>
                    </div>                    
                </div>          
                <Loader loading={isFileDeleting} />
                <Loader loading={props.isOutForSignature}
                    text={SignatureFlowConstants.SendingForSignatureOverlayMessage} />
            </LoadingOverlay>)
})

export default SFStep4;