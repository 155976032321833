import { ProductType } from '@sssuite-component-ui/ss-suite-layout';
import { SSSUITE_API_BASE_URL } from 'src/utils/AppConstants';
import { SenderSignOption } from '../../models/SigningInfo';

export const DaysRangeOptionsList = [

    { label: '24 Hours', value: "One_Days" },
    { label: '2 Days', value: "Two_Days" },
    { label: '3 Days', value: "Three_Days" },
    { label: '5 Days', value: "Five_Days" },
    { label: '1 Week', value: "Seven_Days" },
    { label: '15 Days', value: "Fifteen_Days" },
    { label: '30 Days', value: "Thirty_Days" },
];

export const Common = {
    ButtonLabel: {
        Cancel: "Cancel",
        Ok: "Ok",
        Save: "Save",
        Delete: "Delete"
    },
    OverlayMessages: {
        Downloading: "Downloading...Please wait..",
        Processing: "Processing...Please wait",
        Deleting: "Deleting...Please wait",
        ScreenSharing: "Starting screen share...Please wait",
        Archiving: "Archive is in progress...Please wait",
        Unlocking: "Unlocking...Please wait",
        Loading : "Loading...Please wait",
    },
    Images: {
        ContactIcon: "./dist/images/icon-contact-header.svg",
    }
}

export const UserGroupsConstants = {
    Admin: 'Admin',
    Staff: 'Staff',
    Partner: 'Partner'
}

export const UserManagementConstants = {
    SelectUserGroupWarning: 'Please select atleast one user role in Memberships.',
    LoadingUserError: 'An Error occured when loading users.Please Try Again.',
    SavingUserError: 'Saving user details failed, please re-login',
    UserUpdationError: 'Updating user details failed, please re-login',
    EditUserLoadingError: 'User Loading failed, please re-login.',
    DeletingUserError: 'Deleting user failed, please re-login',
    SelectUserWarning: 'Please select a user from the list.',
    SelectGroupWarning: 'Please select a group from the list.',
    SaveUserSuccess: 'User added successfully',
    DeleteUserSuccess: 'Selected user has been deleted',
    UserUpdationSuccess: 'User details has been updated.',
    DeleteUserConfirmation: 'Are you sure you want to delete this user?',
    AddUserDetails: 'Add User Details',
    SetPAssword: 'Set Password',
    PasswordChangeOnNextLogin: 'User must change password on next login',
    ManageMembership: 'User Group',
    UserManagement: 'User Management',
    EditUserDetails: 'Edit User Details',
    ChangePassword: 'Change Password',
    UserExists: 'User already exists.',
    UserPasswordSuccess: 'Password changed successfully',
    PasswordMustBeEightCharacters: 'Your password must be at least 8 characters.',
    DeleteLoggedInUser: 'You cannot delete the logged in user',
    EditLoggedInUser: 'You cannot Edit the logged in user',
    DependentUsers: 'Warning:  You are trying to delete a user that has been selected as the Contact Person for returns that have already been delivered (i.e. the person the taxpayer should contact if they have questions).  Please select the contact person you would like to replace the deleted user.',
    DependentDocument: 'Warning:  You are trying to remove the role for the user who has been selected as the Contact Person for returns that have already been delivered (i.e. the person the taxpayer should contact if they have questions).  Please select the contact person you would like to replace the modified user role.',
    SignatureFlowDisabledWarning: 'Signature service  is currently disabled for your company. Please enable from company settings.',
    ExportToExcel: 'Export to Excel',
    MobileNumberEmpty: 'Mobile Number can\'t be empty',
    ResetPasswordHeading: 'Send Reset Password Link',
    ResetPasswordBodyText: `An email with a reset password link will be sent to the user's registered email address. Do you wish to continue?`,
    ResendTemporaryPasswordHeading: 'Send Temporary Password',
    ResendTemporaryPasswordBody: `An email with a temporary password will be sent to the user's registered email address. Do you wish to continue?`,
    TemporaryPasswordMailConfirmation: 'Temporary password mail is successfully sent to %USEREMAIL%',
    ResetPasswordMailConfirmation: 'Reset password mail is successfully sent to %USEREMAIL%'
}

export const ValidationContants = {
    FirstNameWarning: 'Please enter first name.',
    LastNameWarning: 'Please enter last name.',
    EmailAdrressWarning: 'Please enter email address.',
    ValidEmailAddressWarning: 'Please enter valid  email address.',
    ValidIdentityServerEmailAddressWarning: "Only letters (a-z), numbers (0-9), symbol (@), dash (-), underscore (_), period (.) and apostrophe (') are allowed.",
    EmailNotMatching: 'Email and Confirm Email did not match',
    PhoneNumberWarning: 'Please enter the phone number.',
    PhoneNumberLengthWarning: 'Please enter a 10 digit phone number.',
    MobileNumberWarning: 'Please enter the mobile number.',
    MobileNumberLengthWarning: 'Please enter a 10 digit mobile number.',
    CountryCodeWarning: 'Please select the country code.',
    PTINWarning: 'PTIN should start with P followed by 8 digit number.',
    EnterPasswordWarning: 'Please enter password and confirm password.',
    PasswordNotMatchedWarning: 'Password and confirmed password did not match.',
    NewPasswordWarning: 'Please enter new password.',
    OldPasswordWarning: 'Please enter old password',
    ConfirmPasswordWarning: 'Please enter confirm password',
    PasswordLengthWarning: 'Password must be 8 characters.',
    PasswordWithoutSpaceWarning: 'Please enter password without space.',
    ZipWarning: 'Please enter zip.',
    ZipLengthWarning: 'Please enter a 5 digit zip.',
    ExtensionWarning: 'Please enter less than 7 digit extension number.',
    FaxWarning: 'Please enter a 10 digit fax number.',
    StateWarning: 'Please select state.',
    CityWarning: 'Please enter city.',
    ValidCityWarning: 'Please enter valid city name.',
    PdfFileValidation: 'Please upload Pdf files only.',
    PaymentURLValidation: 'Please enter a valid payment URL.',
    FirmKeyWarning: 'Please enter valid firm key.',
    AzureGroupIdWarning: 'Please enter valid Azure Group Id.',
    PasswordWarning: 'Please enter password.',
    K1InstructionNotAVailable: 'K1 instruction is not available in the uploaded file',
    NoContentAvailable: 'No content available',
    EINWarning: 'Please enter valid EIN.',
    FileFormatNotSupported: 'Unsupported file type attached. We support PDF, DOC, Excel, PNG and JPEG file types.',
    UpperCaseValidationError: 'Password should contain at least one uppercase character.',
    LowerCaseValidationError: 'Password should contain at least one lowercase character.',
    NumbersValidationError: 'Password should contain at least one number.',
    SpecialCharactersValidationError: 'Password should contain at least one special character.',
    CsvFileValidation: 'Please upload Csv files only.',
    SameEmailValidation: 'Email Already selected.',
    DuplicateEmail: 'Only 2 duplicate emails are allowed',
    RequireEmailNotify: 'Please Select Notify User.',
    UpdateFileDetails:'Please update file details.',
    RequireContactPerson: 'Please Select Contact Person.',
}


export const CustomColumnConstants = {
    ValidationMessages: {
        CustomColumnValueWarning: "Please enter column value.",
        CustomColumnValueAlreadyExist: "Custom column value already exists.",
        CustomColumnLength: "Please Enter Maximum of 20 characters.",
        CustomColumnAddValidation: "Unable to add values unless a column name is specified first.",
        CustomColumnSaveValidation: "Unable to add values unless a column name is saved/updated."
    },
    StatusMessage: {
        CustomColumnValueAddSuccess: 'Custom column value added successfully.',
        CustomColumnValueAddError: 'Error occured while adding custom column value.',
        CustomColumnValueLength: "Please Enter Maximum of 100 characters.",
        CustomColumnValueUpdateSuccess: 'Custom column value updated successfully.',
        CustomColumnValueUpdateError: 'Error occured while updating custom column value.',
        CustomColumnValueDeleteSuccess: 'Custom column value deleted successfully.',
        CustomColumnValueDeleteError: 'Error occured while deleting custom column value.',
        CustomColumnNameUpdateSuccess: 'Custom column name updated successfully.',
        CustomColumnNameUpdateError: 'Error occured while updating custom column name.',
        CustomColumnHeaderDeleteSuccess: 'Custom column header deleted successfully.',
        CustomColumnHeaderDeleteError: 'Error occured while deleting custom column header.'
    },
    OverlayMessage: {
        UpdatingCustomColumnName: 'Updating custom column name, please wait...',
        AddingCustomColumnValue: 'Adding custom column value, Please wait...',
        UpdatingCustomColumnValue: 'Updating custom column values, Please wait...',
        DeletingCustomColumnValue: 'Deleting custom column values, Please wait...',
    },
    WarningMessage: {
        DeletingCustomColumnHeader: "The Custom Column you are trying to delete will delete all its corresponding values and it cannot be recovered. Are you sure you want to delete it?",
    }
}
export const DocumentTypeConstants = {
    ValidationMessages: {
        DocumentTypeValueWarning: "Please enter column value.",
        DocumentTypeAlreadyExist: "Document type already exists.",
        DocumentTypeValueLength: "Please Enter Maximum of 40 characters.",
    },
    StatusMessage: {
        DocumentTypeAddSuccess: 'Document type value added successfully.',
        DocumentTypeValueAddError: 'Error occured while adding Document type value.',
        DocumentTypeUpdateSuccess: 'Document type value updated successfully.',
        DocumentTypeValueUpdateError: 'Error occured while updating Document type value.',
        DocumentTypeValueDeleteSuccess: 'Document type value deleted successfully.',
        DocumentTypeValueDeleteError: 'Error occured while deleting Document type value.',
    },
    OverlayMessage: {
        AddingDocumentTypeValue: 'Adding document type value, Please wait...',
        UpdatingDocumentTypeValue: 'Updating document type value, Please wait...',
        DeletingDocumentTypeValue: 'Deleting document type value, Please wait...',
    },
}

export const CompanySettingsConstants = {
    StatusMessage: {
        CompanySettingsError: 'Error occured while fetching company settings',
        CompanyProfileError: 'Error while loading company profile',
        CompanyLogoError: 'Error while loading company logo',
        UpdateCompanySettingsSuccess: 'Company settings updated successfully.',
        UpdateCompanySettingsError: 'Error occured while updating company settings',
        UpdateRetentionPeriodError: 'Error occured while applying retention period for all documents',
        UpdateCompanyProfileSuccess: 'Company Information saved',
        UpdateRetentionForAllDocuments: 'Retention Settings applied successfully',
        UpdateCompanyLogoError: 'Unable to update logo, Please try again',
        AdminProfileError: 'Error while loading company profile',
        ADAzureExist: 'Group ID has already been added on a different Account. Please enter a different Group ID'
    },
    OverlayMessage: {
        UpdatingCompanySettings: 'Updating company settings...',
        ApplicationLoading: 'Loading, please wait...'
    },
    Validate: {
        CompanyNameWarning: 'Please enter company name.',
        AddressWarning: 'Please enter street address 1.',
        StateWarning: 'Please select state.',
        WebsiteWarning: 'Please enter valid website.',
        ImageWarning: 'Please upload only image.',
        ImageDimensionWarning: 'Max dimensions are 300x100px.',
        ImageValidation: 'Image validation failed! try again...',
        TrustedDeviceExpiryDays: 'Please enter the days between 1 to 60.'
    },
    HeaderName: {
        MyCompany: 'My Company',
        CompanyDetails: 'Company Details',
        PrimaryAdmin: 'Primary Admin',
        CompanyLogo: 'Company Logo',
        EditCompany: 'Edit Company Details',
        ChangePrimaryAdmin: 'Change Primary Admin',
        UploadCompanyLogo: 'Upload Company Logo',
        CompanyLogoMessage: 'Your selected logo will be used on all web pages, emails and notifications (Company Logo Dimension should be less than 300x100px)',
        PrimaryAdminMessage: 'Primary Admin is always a system administrator'
    },
    MFASettings: {
        MFASettingsError: 'Error occured while fetching MFA settings',
        HelpText: "When mobile number is provided in Client info, access code will be sent through text/SMS. If the mobile number is not provided then access code will be sent through email."
    }

}

export const ReturnMailingAddressConstants = {
    ValidationMessages: {
        TitleWarning: 'Please enter title',
        NameWarning: 'Please enter name',
        StreetAddress1Warning: 'Please enter street address1.',
        ManualAddressTitlePresentWarning: 'Title is already present, please enter different title',
    },
    StatusMessage: {
        ManualAddressUpdateSuccess: 'Manual address updated successfully.',
        ManualAddressAddSuccess: 'Manual address added successfully.',
        SavingManualAddress: 'Saving manual address, please wait...',
        Loading: 'Loading Manual Address, please wait...',
        ManualAddressDeleteSuccess: 'Manual Address deleted successfully.',
        ManualAddressDeleteFailed: 'You can\'t delete this manual address as it is being used.',
        FecthingManualAddress: "Fetching manual address, please wait...",
        AddingManualAddress: "Adding manual address, please wait...",
        UpdatingManualAddress: "Updating manual address, please wait...",
        DeletingManualAddress: "Deleting manual address, please wait...",
    }
}

export const ReportFilterConstants = {
    StatusMessage: {
        SavedSuccessMessage: "Filter saved successfully.",
        SavedErrorMessage: "Failed to save the filter!",
        UpdateSuccessMessage: "Filter updated successfully.",
        UpdateErrorMessage: "Failed to update the filter!",
        DeleteSuccessMessage: "Filter deleted successfully.",
        DeleteErrorMessage: "Failed to delete the filter!",
        GetAllErrorMessage: "Failed to fetch the filters!",
    },
    ValidationMessage:
    {
        NoRecentFilters: "There is no recent filter to apply!",
    },
    ControlsLabel:
    {
        ApplyRecentFilterBtn: "Apply recent filter",
        SaveCurrentFilterBtn: "Save current filter",
        SetAsDefaultFilter: "Set as Default Filter",
        DeleteFilter: "Delete Filter",
        ExportToExcel: "Export to Excel",
        ClearFilter: "Clear Filter",
        RemoveDefault: "Remove Default",
        PleaseEnterTheFilterName: "Please enter the filter name",
        Apply: "Apply",
        SetDefault: "Set Default",
        Delete: "Delete",
        AppliedFilter: "Applied Filter"
    },
    OtherMessage: {
        DeleteFilter: "Are you sure you want to delete this filter?",
    },
    ControlsTitle: {
        ApplyMostRecentFilter: "Apply Most Recent Filter",
        ToggleDropdownMenu: "Toggle dropdown menu"
    },
    DefaultFilterBuilder: "_D_FILTER",//This suffix is trimmed to maintain total length to 25,
    QuickReports: {
        LinkNotAccessed: "Link Not Accessed",
        KBAFailed: "KBA Failed"
    }
}

export const DeliveredReturnsTableConstants = {
    Title: {
        FilterSaveUpdate: "Save/Update Filter",
    },
    OtherMessage: {
        DoYouWantToCreateNewFilterOrUpdateTheCurrent: "Do you want to create New Filter or Update the Current?",
        NoReturnsFound: "No returns found"
    },
    ControlLabel: {
        YesCreateNew: "Yes, Create New",
        NoUpdateTheCurrent: "No, Update The Current"
    }
}

export const VoucherReminderManagementConstants = {
    OtherMessage: {
        NoDataFound: "No records found"
    },
    ControlsLabel:
    {
        ExportToExcel: "Export to Excel"
    }
}


export const BulkOperationMessage = {
    Processing: "This may take several minutes. Thank you for your patience.",
    ArchiveError: 'We are unable to archive the selected documents.',
    DeleteError: 'We are unable to delete the selected documents.',
    RestoreError: 'We are unable to restore the selected documents.',
    ChangeOfficeLocationError: 'We are unable to change the office location for the selected documents.',
    ReturnExpiredOnRestore: "You have selected one or more expired returns. You can't restore the expired return.",
    SendReminderWarning: `The signing reminder will not be sent for the selected returns which has the following status
                          "Esigned", "Declined", "Cancelled", "Signature Stamping Failed" and "Delivery Failed".
                          <br>The signing reminder can only be sent for the returns which are in 'Out for Signature', 'Partially Signed' and 'Processing' status.
                          <br>Do you wish to continue ?`,
    SendReminderWaitProcessing: "We are processing the reminders for emailing. This may take several minutes",
    UpdateReminderWaitProcessing: "We are updating the reminder settings. This may take several minutes",
    SendReminderError: 'We are unable to send reminder for these documents.',
    UpdateReminderSettingError: 'We are unable to update reminder settings for these documents.',
}


export const InUseReturnsConstants = {
    ValidationMessage: {
        NoDocumentSelectedWarning: "Please select document(s) to Make available!"
    }
}

export const RecyleReturnsConstants = {
    SelectDocumentWarning: "Please select document(s) to delete!",
    SuccessMessages: {
        Delete: "Deletion in progress, Please refresh after sometime."
    },
    ConfirmMessages: {
        Delete: `Are you sure you want to delete the selected return(s)? On Delete, System will
                \npermanently delete all the return(s) from the SafeSend Returns application and they
                \nwill be no longer available to anyone and the Taxpayer's access link will be
                \ndisabled permanently.`,
    },
    Title: {
        RecyleReturns: "<i class='text-danger fas fa-trash-alt'/> Permanent Delete"
    }
}

export const RestoreReturnConstants = {
    SelectDocumentWarning: "Please select document(s) to restore!",
    Progress: "Restore in progress...",
    SuccessMessages: {
        Restore: "Selected return(s) are restored successfully."
    },
    ConfirmMessages: {
        Restore: `Are you sure you want to restore the selected return(s)?`,
    },
    Title: {
        Restore: "<i class='fas fa-undo'/> Restore"
    },
    ReturnExpired: {
        Restore: "You have selected one or more expired returns. You can't restore the expired return."
    },
    BulkOperationMessage: {
        Processing: "This may take several minutes. Thank you for your patience.",
        Error: 'We are unable to restore the selected documents.'
    }
}

export const SavedMessageConstants = {
    SaveMessageSuccess: 'Message added successfully',
    SaveMessageNameError: 'Name already exists',
    SaveMessageError: "Failed to save message",
    UpdateMessageError: "Failed to update message",
    UpdateMessageSuccess: 'Message Updated successfully',
    DeleteMessageSuccess: 'Message deleted successfully',
    ValidateName: 'Please enter a message name.',
    ValidateSubject: 'Please enter a message subject.',
    ValidateBody: 'Please enter a message body.',
    DeleteMessageWarning: 'The default message cannot be deleted.',
    SavedMessageWarning: "Message details",
    SetDefaultMessageWarning: "This message will be set as default message",
    RemoveDefaultMessageWarning: "This message will be removed as default message",
    SelectDefaultMessageWarning: "Please select a default message first",
}

export const ClientInstructionsConstants = {
    DeleteInstructionWarning : 'The default instruction cannot be deleted.',
    SaveInstructionError: "Failed to save instruction.",
    SelectDefaultInstructioneWarning: "Please select default instruction first.",
    UpdateInstructionError: "Failed to update instruction.",
    SaveInstructionSuccess: 'Instruction added successfully.',
    UpdateInstructionSuccess: 'Instruction updated successfully.',
    DeleteInstructionSuccess: 'Instruction deleted successfully.',
    ValidateName: 'Please enter instruction name.',
    ValidateBody: 'Please enter instruction body.',
    SaveInstructionNameError: 'Name already exists.',
    SetDefaultInstructionWarning: "This instruction will be set as default instruction.",
    RemoveDefaultInstructionWarning: "This instruction will be removed as default instruction.",
}

export const QuestionConstants = {
    SaveQuestionSuccess: 'Authentication Question added successfully',
    UpdateQuestionSuccess: 'Authentication Question updated successfully',
    DeleteQuestionSuccess: 'Authentication Question deleted successfully',
}

export const UserSettings = {
    fetchUserSettings: "Failed to fetch User Settings",
    saveUserSettingsSuccess: "User Settings saved successfully",
    saveUserSettingsError: "Failed to save User Settings",
    saveSignatureDelegations: "Failed to save delegates",
    fetchUserNotificationSettingsError: "Failed to fetch User Notification Settings",
    saveUserNotificationSettingsError: "Failed to save User Notification Settings",
    saveUserNotificationSettingsSuccess: "User Notification Settings saved successfully"
}
export const ArchiveReturnsConstants = {
    downloadEformFilesState: "Could not find downloadable Efile document(s).",
    downloadDocument: "Could not find downloadable document(s).",
    PleaseSelectDocumentToRestore: "Please select document(s) to restore!",
    PleaseSelectOneOrMoreDocuments: "Please select one or more documents",
    UploadedESignedEfileFormsCanOnlyDownloadable: "Error: Only Uploaded & E-Signed e-file forms can be downloaded",
    OneOrMoreItemsWereNotRemovedAsThierValuesDidNotMatchTheSelectedValue: "One or more items were not removed as thier values did not match the selected value.",
    documentRestoreConfirmation: "Are you sure you want to restore the selected document(s)?"
}


export const RestoreSignatureReturnsConstants = {
    PleaseSelectDocumentToRestore: "Please select document(s) to restore!",
    PleaseSelectOneOrMoreDocuments: "Please select one or more documents",
    documentRestoreConfirmation: "Are you sure you want to restore the selected document(s)?",
    SuccessMessage: "Successfully restored the selected document(s)!",
    DocumentExpired: "You have selected one or more expired documents. You can't restore the expired document.",
    Progress: "Restore in progress...",
    SingleRestoreWarning:"The document selected for restore has automated signing reminder enabled.",
    MultiRestoreWarning:"One or more documents selected for restore have automated signing reminder enabled.",
    BulkRestoreWarning: "Note: Any previously scheduled reminders will be turned on for Signature(s) restored from Recycle Bin."
}


export const ReportProblemConstants = {
    OverlayMessage: {
        SubmittingIssue: "Submitting your issue, Please wait..."
    },
    SuccessMessage: 'Problem reported successfully.Your Tracking ID is ',
    ErrorMessage: 'Failed to report the problem!'
}
export const AttachmentConstants = {
    FilesizeWarning: 'File size limit should not exceed to 1 GB.',
    validDocument: 'Please select a valid document, currently selected file is empty.',
    AddAttachments: 'Add Attachments',
    UploadedFiles: 'Uploaded Files',
    MaxFileSize: '1091900799',
    UploadingIncomplete: 'Please wait for uploading to complete before adding instructions'
}

export const UploadtaxReturnConstants = {
    SetEROSignerWarning: "Please select a ERO / Signer to submit.",
    SubmitTaxReturnSuccess: "The following return was successfully submitted",
    SubmitTaxReturnFailed: "Failed to submit the following return, please try again",
    AbortingUploadWarning: "Aborting upload cannot be processed in between , please wait.",
    CloseConfirmationMessage: "Closing this will delete the files, do you want to proceed?",
    UploadingFailedError: "Uploading failed, please try again",
    DeleteFileMessage: "Are you sure you want to delete this file?",
    Recall: {
        FileAddingWarning: "one file is already added.",
        MultipleFileAddingWarning: "only one file can be uploaded."
    },
    ReturnSubmitSuccess: "The following return(s) were successfully submitted",
    EmptyFileListMessage: "Files list will appear here.",
    OverlayMessage: {
        ProcessTaxReturn: 'Processing tax return...',
        ApplicationLoading: 'Loading, please wait...'
    },
    StatusMessage: {
        TaxReturnLinkError: 'Error while fetching tax return link.',
        ProcessTaxReturnError: 'Error occured while processing tax return',
        UpdateTaxReturnError: 'Unable to update tax return, Please try again',
    },
}

export const AdditionalEsignDocuments = {
    uploadAdditionalSignFileWarning: 'Please upload .doc, .docx or .pdf files only.',
    deleteConfirmMessage: "Are you sure, you want to delete this document?",
    deleteDocument: "Document deleted successfully.",
    documentRequired: "Please upload document(s).",
    documentTypeRequired: "Please select a document(s) type.",
    emptyFileListMessage: "File(s) list will appear here.",
    fetchDocumentError: "Error while fetching document link.",
    uploadInProgress: "File(s) upload in progress",
    abortingUploadWarning: "Aborting upload cannot be processed in between, please wait."
}

export const MyAccountConstants = {
    //API response
    APIResponse: {
        UserUpdateSuccess: "User updated successfully."
    },
    UserUpdateFailedMessage: "Updating user details failed, please re-login",
}

export const ChangePasswordConstants =
{
    PasswordChangeLogoutWarning: "Changing your Password will require you to login again. Make the change?",
    PasswordUpdateSuccess: "Password updated successfully.",
    PasswordUpdateFail: "Updating password failed, please re-login",
}

export const SaveandCloseProcessReturn = {
    StatusMessage: {
        Success: "Your changes are saved successfully",
        Fail: "Error occured while saving your changes.",
    }
}

export const FinishProcessReturn = {
    MenuItems: {
        SendForReview: 'Send for Review',
        SendToEROSigner: 'Send to ERO / Signer',
        ApproveForDelivery: 'Approve for Delivery',
        DeliverToClient: 'Deliver to Client',
        DeliverToCompany: 'Deliver to Company',
        ReturnToProcessor: 'Return to Processor',
        DeliverToTaxCaddy: 'Deliver to TaxCaddy',
        DeliverWithTaxCaddy: 'Deliver with TaxCaddy',
        RetentionPeriod: 'Modify Retention Period',
        PaperDelivery: 'Download PDF',
        Empty: "",
    },
    StatusMessage: {
        SendToEROSuccess: "Sent to ERO successfully.",
        SendForReviewSuccess: "Sent for review successfully.",
        ApproveForDeliverySuccess: "Sent to approve for delivery successfully.",
        DeliverToClientSuccess: "Delivered to client successfully.",
        SendToEROError: "Error occured while sending to ERO.",
        SendForReviewError: "Error occured while sending for review.",
        ApproveForDeliveryError: "Error occured while sent to approve for delivery.",
        EROError: "The ERO User you have selected has been modified or deleted. Choose another ERO User.",
        ContactPersonError: "The Contact Person you have selected has been modified or deleted. Choose another Contact Person.",
        NotifiError: "The Notify User you have selected has been modified or deleted. Choose another Notify User.",
        DeliverToClientError: "Error occured while delivering to client.",
        GenerateTaxpayerViewSuccess: "Loaded taxpayer view successfully.",
        GenerateTaxpayerViewError: "Error occured while loading the taxpayer view",
        DeliverToClientWithoutInvoiceError: "You are required to include an invoice and amount due with this Tax Return. Please return to the Invoice Step and ensure and invoice is included and the amount is entered in the proper field.",
        SelectApprover: "Please select a user to approve for delivery.",
        SelectDeactivatedDeletedAuthority: "Please Select Alternate Authority for Deleted/DeActivated Authority.",
        TaxCaddyLookupError: "Unable to communicate to TaxCaddy server, please contact support.",
        RetentionValidationMessage: "Please specify a valid retention period.",
        AttachmentValidationMessage: "Attachments status are either uploading or failed to upload!",
    },
    InfoMessage: {
        AttchmentNotDeliverToTaxCaddy: "Attachments will not be delivered to TaxCaddy",
        TaxCaddyApprove: "On Accept, this return will be delivered to TaxCaddy",
        TaxCaddyReject: "On Reject, this return will not be delivered to TaxCaddy",
        NotDeliveredToTaxcaddy: ' This return will not be delivered to TaxCaddy'
    }
}

export const PredefinedFileName = {
    K1InstructionBlob: "6c1525a7-69d4-4ca2-b67b-495049f1d132",
    FileNotFound: "FileNotFound"
}

export const K1InstructionFileName = {
    E1065_2015: "Partner`s Instructions for Schedule K-1 (Form 1065)(2015).pdf",
    E1065_2016: "Partner`s Instructions for Schedule K-1 (Form 1065)(2016).pdf",
    E1065_2017: "Partner`s Instructions for Schedule K-1 (Form 1065)(2017).pdf",
    E1120S_2015: "Shareholder`s Instructions for Schedule K-1 (Form 1120S)(2015).pdf",
    E1120S_2016: "Shareholder`s Instructions for Schedule K-1 (Form 1120S)(2016).pdf",
    E1120S_2017: "Shareholder`s Instructions for Schedule K-1 (Form 1120S)(2017).pdf"
}



export const InvoiceReplaceConstants = {
    //API response
    InvoiceAmountWarning: "Please enter a invoice amount.",
    BookmarkTextWarning: "Please enter bookmark text.",
    NonPdfFileWarning: "Please upload PDF file only.",
    MultipleFileWarning: "Please upload one file."
}

export const Assignment = {
    Description: "DESCRIPTION"
}

export const AssignUserConstants = {
    SelectDocumentWarning: "Please select document(s) to assign to another user!",
    RecalledReturnWarning: "Return(s) cannot be assigned when their status is RECALLED."
}

export const SetAccessConstants = {
    SelectDocumentWarning: "Please select document(s) to set access for other users!",
    RecalledReturnWarning: "Access cannot be set when status is RECALLED."
}

export const ChangeStatusConstants = {
    SelectedStatus: "Please select status",
    Title: "Change Status",
    Error: "Cannot change status of the selected document!",
    changeStatusConfirmation: "You are about to change the status of the document manually. Do you want to proceed?",
    ChangeDeliveredReturnStatus: "Warning: This document has not been E-Signed by all parties. Changing the status to \"Signed & ESigned\" indicates that you have obtained the remaining signatures manually (i.e. fax, email, mail etc.). The audit trail for this document will only include those signers who have E-Signed.",
    EmptyReason: "Please enter a reason for changing status"
}

export const UploadCorrectedReturnWarning = {
    message: "If you upload a different return type from the recalled one, it will count towards your usage."
}

export const DeleteTaxReturnConstants = {
    Title: "Delete Tax Return",
    SelectDocumentWarning: "Please select document(s) to delete!",
    InUseReturnWarning: "Warning: In-use returns are not allowed to be deleted!",
    ConfirmDeletion: 'You are about to delete the selected document(s). This cannot be undone.\nDo you want to proceed?',
}

export const DeleteDraftConstants = {
    ConfirmDeletion : "You are about to delete the selected document(s). This cannot be undone. Do you want to proceed?"
}

export const DeleteSignatureConstants = {
    Title: "Delete Tax Return",
    SelectDocumentWarning: "Please select document(s) to delete!",
    InUseReturnWarning: "Warning: In-use returns are not allowed to be deleted!",
    ConfirmDeletion: 'Are you sure you want to proceed with deleting the selected document(s)?',
    SuccessMessage: "Successfully deleted the selected document(s)!",
    ConfirmRecycleDeletion: `Are you sure you want to delete the selected document(s)? <br>
                            This action will permanently remove them from the application, 
                            making them inaccessible to everyone, and disabling
                            the signer's access link permanently.`,
    ErrorMessage: "Failed to delete selected document(s)."
}
export const ArchiveSignatureConstants = {
    SuccessMessage: "Successfully archived the selected document(s)!"
}

export const CustomWindowConstants = {
    closePopoverMessage: "Your changes will be discarded, Are you sure you want to close the return?"
}

export const CompanySettingConstants = {
    closePopoverMessage: "Your proposed change will delete %COUNT% delivered returns on %DATE%.  Please confirm you would like to apply this change."
}

export const SecuritySettingConstants = {
    SelectDefaultQuestionWarning: "Please select a default question first",
    UpdateQuestionError: "Failed to update question",
    DeleteQuestionError: "Failed to delete question",
    SaveQuestionError: "Failed to save question",
    ValidateTitle: 'Please enter a question title.',
    ValidateText: 'Please enter a question text.',
    SaveQuestionTitleError: 'Title already exists',
    SetDefaultQuestionWarning: "This question will be set as default question",
    RemoveDefaultQuestionWarning: "This question will be removed as default question",

}

export const ClientInstructionMessageVariables = {
    CompanyName: "The official name of your company as registered on your account",
    TaxPayerName: "The listed Taxpayer on the Tax Return",
    SpouseName: "The listed Spouse on the Tax Return",
    HelpContact: "The name of the user designated for customer support",
    HelpPhone: "The phone number of the user designated for customer support",
    HelpEmail: "The email address of the designated help contact",
    PrepareName: "The listed Preparer on the Tax Return",
    DueDate: "The due date of the filing",
    NumberOfays: "Number of days set for voucher reminder",
    Recipient: "The person name for which the email is being sent",
    TaxYear: "The Taxyear on the Tax Return",
    EROName: "The name of the assigned ERO Signer",
    SenderName: "The name of the user selected as the 'Sender' of the return",
    ClientId: "The unique identifier assigned to the client on the delivered document",
    ClientName: "The full name of the client as it appears on the delivered document",
    ContactPerson: "The name of the designated user responsible for customer support interactions" 
}

export const ClientInfoConstants = {
    emailUpdatedConfirmation: "You have updated the email address on file, would you like to resend the Return to the new email address?",
    clientDetailUpdateConfirmation:"Client Details updated successfully",
    clientDetailUpdateError:"Failed to update client details",
}

export const ReGroupConstants = {
    PageMoveSuccess: "Page moved successfully",
    DeletedPageMoveSuccess: "Page moved to Deleted successfully",
    SameSourceAndDestinationError: "Unable to move selected forms as source and destination groups are same",
    PageDeleteNotAllowed: "Action Not Allowed: There must be at least one page existing in this group."
}

export const PageToolBarConstants = {
    moveToDeleteConfirmation: "Are you sure you want to move this page to deleted?"
}

export const ReturnDownloadOptionsConstants = {
    IncludeInTaxReturnPdf: "Include in the Tax Return PDF",
    CreateSeperatePdf: "Create a Separate PDF",
    SeparateByQuarterlyDueDates: "Separate by Quarterly Due Dates",
    GroupInSinglePDF: "Group in Single PDF"
}

export const ProcessReturnConstants = {
    ReturnAlreadyOpenedMessage: "Return already opened !!",
    ReturnInUse: "Return In-Use",
    ReturnNotAssignedToLoggedInUser: "This return is assigned to another user. Please make sure the return is assigned to you in order to make any changes.",
    ProcessReturnOpenMessage: "Opening Return ...",
    DuplicateReturnMessage: '<br/>We have detected that this return has already been delivered. Please review the Delivered Returns or Archived Returns reports to ensure you are not delivering a duplicate tax return <br/><br/><br/> Please press <b>OK</b> to continue and <b>Cancel</b> to go back'
}

export const ReProcessReturnConstants = {
    ReProcessInitiatedModalLoader: "Reprocessing initiated..."
}

export const EsignHelpTextConstant = {
    ClientSignature: "Drag and drop to place client's signature",
    SignatureDate: "Drag and drop to place date of signature",
    NameAndTitle: "Drag and drop to place Name and Title",
    EroSignatureStamp: "Drag and drop to place ERO signature stamp",
    Initial: "Drag and drop to place Initials control",
    Text: "Drag and drop to place Text control",
    Company: "Drag and drop to place Company control",
    Name: "Drag and drop to place Name control",
    Title: "Drag and drop to place Title control",
    PrintName: "Drag and drop to place Print Name control",
    CheckBox: "Drag and drop to place CheckBox control",
    Radio: "Drag and drop to place Radio control"
}

export const SignatureControlConstants = {
    ControlIdPrefix: "control_",
    ControlWidth: 150,
    ControlHeight: 30,
    ControlWidth_RadioButton: 140,
    ControlHeight_RadioButton: 40,
    ControlWidth_Checkbox: 42,
    DefaultNumberOfRadioButtons: '2',
    MinimumHeightToViewDragAndEditIcon: 60,
}

export const PasswordPolicyConstants = {
    NoChanges: 'There are no changes to save.',
    MinimumCharacter: 'Minimum numbers of characters should be greater than 8.',
    MinimumOption: 'Select at least two options in character type.'
}

export const SignatureFlowReportConstants = {
    DocumentNote: {
        SaveSuccess: 'Successfully saved document note.',
        UpdateSuccess: 'Successfully updated document note.',
        DeleteSuccess: 'Successfully deleted document note.',
        Error: 'Error occured while saving document note.',
        MessageLimit : 5000,
        MessageLimitExceedsError: 'The text exceeds the 5000 character limit, including formatting. Please shorten it to proceed.'
    },
    CancelDocument: {
        Description: 'By canceling this document, recipients can no longer view it or sign enclosed documents. Recipients will receive an email notification, which includes your reason for canceling the document.',
        CanceledTitle: 'Canceled Document',
        CancelTitle: 'Cancel Document',
        DeclinedTitle: 'Declined Document',
        CanceledReasonTilte: 'Reason for canceling this document.',
        DeclinedReasonTitle: 'Reason for declining this document.',
        UpdateSuccess: 'Successfully updated your changes.',
        Error: 'Error occured while saving your changes.',
        EmptyDescriptionWarningMsg: 'Please enter reason for canceling this document'
    },
    SendReminder: {
        Success: 'Reminder has been sent successfully for the selected document(s)',
        Error: 'Failed to send reminder for the selected document(s)!'
    },
    DeleteReturn: {
        Success: 'Successfully deleted the selected return(s)!',
        Error: 'Failed to delete the selected document(s)!'
    },
    SetAccess: {
        Success: 'Document Access set successfully for all selected document(s)',
        Error: 'Setting document access for the users failed!'
    },
    AssignTo: {
        Success: 'Document Assignment set successfully for all selected document(s)',
        Error: 'Setting Document Assignment for the users failed!'
    },
    ResendAccessLink: {
        Success: 'Access Link has been sent successfully for the selected returns',
        Error: 'Failed to resend access link for the selected document(s)!'
    },
    AccessCode: {
        Success: 'Access Code generated successfully',
        Error: 'Failed to generated OTP for the return'
    },
    CancelDocumentMail: {
        Success: 'Cancel document notification has been sent successfully for the selected document signers',
        Error: 'Failed to send cancel document notification for the selected document(s)!'
    },
    DeleteDocument: {
        Success: 'Successfully deleted the selected signature report',
        Error: 'Failed to deleted the selected signature report'
    },
    DownloadHistory: {
        Error: 'Failed to fetch download history for the selected document.'
    },
    DownloadDocument: {
        Success: 'Document(s) downloaded successfully.',
        Error: 'Failed to download the selected document(s).',
        ModalTitle: 'Download Document(s)',
        ModalMessage: 'Click the below links to download',
        DocumentNotSignedError: "Document(s) with 'E-SIGNED' status only can be downloaded.",
        DownloadLaterSuccess: 'Download process may take some time , the downloaded E-signed documents will be available in "My Downloads"',
        DownloadLimitError: 'Cannot download more than 5GB.',
        BulkDownloadNotification: 'Please wait as we are preparing your files'
    },
    MyDownloads:{
        DeleteSuccess: 'Successfully deleted the selected zip file(s)',
        DeleteAllSuccess: 'All Downloaded files has been deleted',
        DeleteError: 'Failed to deleted the selected zip file(s)'
    },
    SignatureSigners: {
        Error: 'Error occured while fetching signature signers'
    },
    UpdateSignatureSigners: {
        Error: 'Error occured while updating signature signers',
        Success: 'Updated the signature signer(s) details successfully'
    },
    ExportExcel: {
        Error: 'Download failed',
    },
    UnlockDocuments:{
        Success:'Document(s) unlocked successfully',
        Error:'Failed to unlock the selected document(s)'
    },
    UnlockRecipients:{
        Success: 'Client Portal has been unlocked!',
        Error: 'Failed to unlock the Client Portal.'
    },
    FetchLockedRecipients:{
        Error: 'Failed to fetch locked recipients.'
    }
}

export const SignatureFlowReportCustomColumnConstants = {
    StatusMessage: {
        ErrorFetchingCustomColumns: 'Error occured while fetching signature report custom columns.',
        ErrorUpdatingCustomColumns: 'Error occured while updating signature report custom columns.',
        ErrorSavingCustomColumns: 'Error occured while saving signature report custom columns.',
        UpdatingCustomColumnsSuccess: 'Successfully updated signature report custom columns.',
        SavingCustomColumnsSuccess: 'Successfully saved signature report custom columns.'
    }
}

export const SignatureFlowReportFilterConstants = {
    StatusMessage: {
        SavedSuccessMessage: "Filter saved successfully.",
        SavedErrorMessage: "Failed to save the filter!",
        UpdateSuccessMessage: "Default filter has been changed successfully!.",
        UpdateErrorMessage: "Failed to update the filter!",
        DeleteSuccessMessage: "Filter deleted successfully.",
        DeleteErrorMessage: "Failed to delete the filter!",
        GetAllErrorMessage: "Failed to fetch the filters!",
    },
    ValidationMessage:
    {
        NoRecentFilters: "There is no recent filter to apply!",
        EmptyDocumentNote: "Please enter a document note."
    },
    ControlsLabel:
    {
        ApplyRecentFilterBtn: "Apply recent filter",
        SaveCurrentFilterBtn: "Save current filter",
        SetAsDefaultFilter: "Set as Default Filter",
        DeleteFilter: "Delete Filter",
        ExportToExcel: "Export to Excel",
        ClearFilter: "Clear Filter",
        RemoveDefault: "Remove Default",
        PleaseEnterTheFilterName: "Please enter the filter name",
        Apply: "Apply",
        SetDefault: "Set Default",
        Delete: "Delete",
        AppliedFilter: "Applied Filter"
    },
    OtherMessage: {
        FilterNameAlreadyExists: "Filter name already exists.",
        FilterFieldsAreEmpty: "Filter fields are Empty! Please provide at least one.",
        PleaseEnterFilterName: "Please enter filter name",
        FilterDoesNotExists: "Filter doesn't exists.",
        DeleteFilter: "Are you sure you want to delete this filter?",
        CreateOrUpdateFilter: "Do you want to create New Filter or Update the Current?",
        NoReturnsFound: "No returns found"
    },
    FilterSuccessMessage: {
        RemoveDefaultFilterSuccess: "Default filter has been Removed successfully!.",
    },

    FilterConfirmationModalTitle: {
        FilterSaveUpdate: "Save/Update Filter",
    },
    ControlLabel: {
        YesCreateNew: "Yes, Create New",
        NoUpdateTheCurrent: "No, Update The Current"
    }
}

/////////////////////////////////////////Tab Constants Start/////////////////////////////////////////////////
export const K1TabConstants = {
    K1UploadConfirmation: "Document replaced successfully."
}

export const ClientInfoTab = {
    Page: {
        DeleteConfirm: "Are you sure you want to delete this page?"
    },
    AddedRefund: {
        DeleteConfirm: "Are you sure to delete?",
        AuthorityWarning: "Please select a taxing authority to add.",
        AmountEmptyWarning: "Please enter valid amount.",
        OverpaymentLessThanWarning: "Overpayment amount should be greater than overpayment applied.",
        DOBWarning: "Please enter valid date of birth."
    },
    UpdateRefund: {
        RefundUpdateSuccess: "Refunds & payments Due updated successfully",
    }
}

export const TabEFileConstants = {
    Info: {
        EROStampInfoMessage: "The ERO stamp will be applied in all pages",
        DoNotInsertDates: "Do not insert dates on the E-File forms"
    },
    SignatureControlWarning: {
        Taxpayer: "There should be at least one signature control for the taxpayer to sign.",
        Partner: "There should be at least one signature control for the partner to sign.",
        Spouse: "There should be at least one signature control for the spouse to sign.",
        EroStamp: "There should be at least one ERO signature stamp."
    },
    EfileForm: {
        Authority: "Please select a taxing authority "
    }
}

export const TabAdditionalEsignDocumentConstants = {
    Info: {
        EROStampInfoMessage: "The ERO stamp will be applied in all pages"
    },
    SignatureControlWarning: {
        Taxpayer: "There should be at least one signature control for the taxpayer to sign in all the additional document.",
        Partner: "There should be at least one signature control for the partner to sign in all the additional document.",
        Spouse: "There should be at least one signature control for the spouse to sign in all the additional document.",
        EroStamp: "There should be at least one ERO signature stamp in all the additional document."
    }
}

export const VoucherTabConstants = {
    AddedVoucher: {
        DeleteConfirm: "Are you sure you want to delete this Voucher?",
        AmountEmptyWarning: "Please enter valid amount.",
        AuthorityWarning: "Please select a taxing authority.",
        PaymentTypeWarning: "Please select a payment type.",
        DueDateWarning: "Please select the due date.",
        FormNameWarning: "Please enter form name",
        OnlinePaymentUrlWarning: "Please enter payment url.",
        OnlinePaymentValidUrlWarning: "Please enter valid payment url.",
        CustomTypeWarning: "Please select the watermark option.",
        CustomTextWarning: "Please enter a custom watermark.",
        OneVoucherWarning: "You must create at least one Voucher by entering the related Amount ($)."
    },
    AddVoucher: {
        Authority: "Authority",
        PaymentType: "Payment Type",
        DueDate: "DueDate",
        Amount: "Amount",
        FormName: "Form Name",
        AddVoucher: "Add Payment",
        PaymentUrl: "Payment Url",
        VoucherAmount: "Voucher Amount",
        voucherNumbers: [
            { value: '0', label: 'Select' },
            { value: '1', label: 'Payment Voucher' },
            { value: '2', label: 'Q1 Estimated Payment' },
            { value: '3', label: 'Q2 Estimated Payment' },
            { value: '4', label: 'Q3 Estimated Payment' },
            { value: '5', label: 'Q4 Estimated Payment' }
        ],
        paymentMethods: [
            { value: '1', label: 'Select' },
            { value: '2', label: 'DO NOT PAY:  Scheduled for automatic withdrawal' },
            { value: '3', label: 'Online payment required' },
            { value: '4', label: 'Add Custom' },
        ],
        voucherPdfPrepareMessageFailed: "Preparing voucher failed",
        voucherDeleteFailed: "Cannot Delete Vouchers apart from Manually added",
        voucherEditWarning: "No Fields Updated",
        btnAddVoucherText: "Add",
        voucherDueDateUpdate: "Voucher DueDate Update Success",
        voucherAddSuccess: "Data updated successfully for "
    },
    DatabaseValues:
    {
        FederalAuthorityName: "Federal"
    },
    VoucherSortBookmarkWordMatchCount: 1,
    DeleteZeroAmountVoucher_1: "Are you sure you want to delete ",
    DeleteZeroAmountVoucher_2: " '$0' vouchers?",
    VoucherUpdateSuccess: "Voucher details updated successfully",
    VoucherDueDateInvalid: "Please update the due date for voucher ",
    VoucherPaymentType: "Please update the Payment Type for voucher ",
    VoucherAuthorityWarning: "Please Update the Authority for voucher ",
    PaymentMethods: [
        { value: '0', label: 'Payment Voucher' },
        { value: '1', label: 'Q1 Estimated Payment' },
        { value: '2', label: 'Q2 Estimated Payment' },
        { value: '3', label: 'Q3 Estimated Payment' },
        { value: '4', label: 'Q4 Estimated Payment' }
    ]
}

export const EfileTabConstats = {
    UpdatedAuthority: {
        AuthorityUpdateSuccess: "Authority successfully updated"
    }
}

export const GroupTabConstants = {
    singlePageDragGhostText: "1 Page.",
    Page: {
        DeleteConfirm: "Are you sure you want to delete this page?",
        WarningValueNotMatch: " One or more items were not removed as thier values did not match the selected value."
    }
}

export const ClientInfoValidation = {
    inCorrectEmail: "Please enter valid email address.",
    inValidSSN: "Please enter valid SSN/EIN.",
    inCorrectTaxpayerEmail: 'Please enter valid taxpayer email address.',
    inCorrectSpouseEmail: 'Please enter valid spouse email address.',
    inCorrectCompanyEmail: 'Please enter valid company email address.',
    inValidTaxpayerSSN: "Please enter valid taxpayer SSN/EIN.",
    inValidSpouseSSN: "Please enter valid spouse SSN/EIN.",
    inCorrectPartner: "Please select ERO / Signer",
    inCorrectERO: "Please select an ERO / Signer from drop-down",
    inValidTaxPayerName: "Please enter valid taxpayer name.",
    inValidSpouseName: "Please enter valid spouse name.",
    invalidCountryCodeTaxpayer: "Please select a country code for taxpayer's mobile number",
    invalidCountryCodeSpouse: "Please select a country code for spouse's mobile number",
    invalidCountryCodeCompany: "Please select a country code for company's mobile number",
    invalidMobileNumberTaxpayer: "Please enter mobile number for taxpayer",
    invalidMobileNumberSpouse: "Please enter mobile number for spouse",
    invalidMobileNumberCompany: "Please enter mobile number for company",
    mobileNumberLengthWarningTaxpayer: "Please enter 10 digit mobile number for taxpayer",
    mobileNumberLengthWarningSpouse: "Please enter 10 digit mobile number for spouse",
    mobileNumberLengthWarningCompany: "Please enter 10 digit mobile number for company"
}

export const DeliveryOptionsValidation = {
    InCorrectContactPerson: "Please select a Contact Person.",
    InValidMessageFromPreparer: "Please select a Preparer Message.",
    InValidSenderName: "Please select a Sender Name.",
    InValidNotifyAboutSigningsEvents: "Please select a contact to Notify About Signing Events.",
    InvalidNumberofDaysVoucherReminder: "Please select a number of days for the Voucher Reminders.",
    InvalidNumberofDaysSigningReminder: "Please select a number of days for the Signing Reminder."
}


export enum SignatureStatusIds {
    None,
    ESigned,
    ManuallySigned,
    Uploaded,
    AwaitingESign,
    AwaitingUpload,
    Locked,
    MailOrFax,
    SignedAndESigned
};
export const CustomAuthoritys = {
    DuplicateCustomAuthority: "Dupliate Authority and State",
    AddCustomAuthority: "Authority Saved Successfully.",
    ActivateAuthority: "Status Changed Successfully.",
    DeleteAuthority: "Authority Deleted Successfully.",
    message: "The Authority is in Use So Can't be Deleted",
    deleteConfirmMessage: "Are you sure, you want to delete this Custom Authority?",
    stateWarning: 'Please select state.',
    cityWarning: 'Please enter city.',
    OnlinePaymentValidUrlWarning: "Please enter valid online payment url.",
    InUseWarnig: "This authority you are trying to edit is currently in use. Please remove the Authority from below tax returns and try again. Click",

}

export const FileConstants = {
    MaximumFileSize: '536870912'
}

export const FileIsEncrypted = (fileName: string) => `Upload canceled. ${fileName} is an encrypted document and cannot have signature controls placed on it. Please remove the encryption and try again.`;

export const FileSizeExceedsLimit = (fileName: string) => `${fileName} cannot be uploaded since the size is greater than 512 MB.`;

/////////////////////////////////////////Tab Constants End/////////////////////////////////////////////////

export const K1SettingsConstants = {
    K1MailCycleOptions: [
        { value: 1, label: 'Before' },
        { value: 2, label: 'After' },
    ]
}

export const DownloadEfileZip = (date: string) => `efile_download_${date}.zip`;

export const DownloadReturnsZip = (clientId: string, taxYear: number) => `${clientId}_Tax Returns_${taxYear}.zip`;

export const DownloadUnsignedReturnsZip = (clientId: string, taxYear: number) => `${clientId}_Tax Returns-Copy_${taxYear}.zip`;

export const DownloadAttachmentsZip = () => `Attachments.zip`;


export const ClientTrackingEvents = {
    InitialEmail: 'Initial Email - ',
    VoucherReminderEmail: 'Voucher Reminder Email - ',
    AccessCodeEmail: 'Access Code Email - ',
    TaxPayerKBASuccess: 'Taxpayer KBA Successful',
    SpouseKBASuccess: 'Spouse KBA Successful ',
    TaxPayerKBAFailed: 'Taxpayer KBA Failed',
    SpouseKBAFailed: 'Spouse KBA Failed',
    SigningReminderEmail: 'Signing Reminder Email - ',
    StatusChangeToSignedAndEsigned: 'Status changed to Signed and E-Signed - '
}

export enum DocumentEventValue {
    "None",
    "Created" = 1,
    "Document Assign To" = 2,
    "Document ERO Stamp Decline" = 3,
    "ERO Sign Decline" = 4,
    "Document Delivery Approved" = 5,
    "Emailed" = 6,
    "Document Change Status Manual" = 7,
    "Tax Return Accessed" = 8,
    "Document Authenticated" = 9,
    "Authentication Failed" = 10,
    "Access Locked" = 11,
    "OTP Authentication Failed" = 12,
    "OTP Access Locked" = 13,
    "Tax Return Reviewed" = 14,
    "Viewed" = 15,
    "Efile Forms Downloaded" = 16,
    "Efile Forms Uploaded" = 17,
    "Taxpayer Esigned - eFile Forms" = 18,
    "Spouse Esigned - eFile Forms" = 19,
    "Vouchers Reviewed" = 20,
    "Voucher Reminder Disabled" = 21,
    "Voucher Reminder Enabled" = 22,
    "Attachment Downloaded" = 23,
    "All Attachment Downloaded" = 24,
    "All K1 Document Downloaded" = 25,
    "Document Attachments Reviewed" = 26,
    "Payment System Accessed" = 27,
    "Invoice Reviewed" = 28,
    "Finished" = 29,
    "Access Link Emailed - " = 30,
    "Transmittal Reviewed" = 31,
    "Document TaxSaved" = 32,
    "K-1 Distributed To" = 33,
    "Document Download All" = 34,
    "Document Filing Instructions Downloaded" = 35,
    "Document Tax Return Downloaded" = 36,
    "Document Vouchers Downloaded" = 37,
    "Document Invoice Downloaded" = 38,
    "Document Signed Efile Downloaded" = 39,
    "KBA Passed" = 40,
    "KBA Failed" = 41,
    "Document KBA Success" = 42,
    "Document KBA Failed" = 43,
    "DOB Changed" = 44,
    "Document Opt Out ESign Option Selected" = 45,
    "Document Signed" = 46,
    "Signed Document Reviewed" = 47,
    "Voucher Reminder Updated" = 48,
    "Deleted" = 49,
    "Efile Forms Deleted" = 50,
    "Shareholder Consent Received" = 51,
    "Shareholder Consent Declined" = 52,
    "K-1 Document Downloaded" = 53,
    "K-1 Instruction Document Downloaded" = 54,
    "Document Mail Status" = 55,
    "Tax Return - Recalled" = 56,
    "Voucher Remainder Mail Status" = 57,
    "Document Request Recall Return" = 58,
    "Document Request Delete Return" = 59,
    "Shared" = 60,
    "Cancelled" = 61,
    "Filed" = 62,
    "Document Rejected" = 63,
    "Access Code Email" = 64,
    "Partnership Esigned - eFile Forms" = 65,
    "Document Signed Efile Downloaded By CPA" = 66,
    "Reminder Sent For Signing" = 67,
    "Download Link Sent" = 68,
    "Payment Completed" = 69,
    "Not Defined" = 75,
    "Delivered To TaxCaddy" = 77,
    "Taxcaddy Delivery Failed" = 78,
    "Return - Archived" = 84,
    "Return - Restored From Archive" = 85,
    "Return Status - Changed To Manually Signed" = 86,
    "Access link Sent" = 87,
    "Additional Document Downloaded" = 89,
    "All Additional Document Downloaded" = 90,
    "Taxpayer eSigned - other Documents" = 91,
    "Spouse eSigned - other Documents" = 92,
    "Partnership eSigned - other Documents" = 93,
    "Documents Forwarded To CC Recipient(s)" = 94,
    "Return Restored" = 95,
    "Tax Return - Uploaded New Return" = 96,
    "Tax Return - Reprocessed" = 97,
    "Payment Details Added for" = 100,
    "Payment Details Updated for" = 101,
    "TP Disabled Voucher Reminder" = 102,
    "Document Signing Delegated" = 103,
    "Delegatee Updated" = 104,
    "Delegation Cancelled" = 105,
    "Delegatee Declined Signing" = 106,
    "Delegatee Esigned - eFile Forms" = 107,
    "Delegatee eSigned - other Documents" = 109
}

export const DownloadReturnsConstants = {
    downloadReturn: "Click on the links below to download"
}

export const InboxConstants = {
    InboxFetchError: "Unable to fetch notifications.",
    InboxMessageUpdateError: "Unable to update notification.",
    InboxMessageClearError: "Unable to clear notifications."
}

export const ScreenShareConstants = {
    screenShareInfo: "Taxpayer should enter the given below code to start screen sharing"
}
// SF Constants

export const SFDocument = {
    sfUploadFileWarning: 'Invalid format - Please upload .doc .docx or .pdf files only',
    sfUploadUnsupportedFileWarning: 'Failed to Upload : File format not supported.',
    deleteConfirmMessage: "Are you sure, you want to delete this document?",
    deleteDocument: "Document deleted successfully.",
    documentRequired: "Please upload document(s).",
    documentTypeRequired: "Please select a document(s) type.",
    emptyFileListMessage: "Files list will appear here.",
    documentExpireOnRequired: "Please select expire(s) on.",
    documentExpirationDateRequired: "Please Enter Expiration Date.",
    documentExpireOnLesser: "Document expiry date should be on or after ",
    fetchDocumentError: "Error while fetching document link.",
    uploadInProgress: "File(s) upload in progress",
    signatureControlRequired: "Document(s) must have one signature control placement.",
    BackgroundProcessInProgress: "Background process is in progress, Please wait...",
    outForSignature: "Document(s) successfully out for signature.",
    saveSignatureDocument: "Document(s) saved successfully.",
    documentCoversionError: "Error while coverting the document.",
    senderSignatureControlRequired: "Document(s) must have one signature control placement for sender.",
    signerControlRequired: "Document(s) must have atleast one control placement for each recipient.",
    clientNameRequired: "Please enter Client Name.",
    taxYearRequired: "Please select Tax Year.",
    SendToRecognizeSignatureDocumentInfoError: "Error while processing the signature controls from the uploaded word document with signature controls.",
    RecognizedSignatureDocumentInfoError: "Error while fetching controls from the uploaded word document with signature controls.",
    WaitDocumentProcessInProgress: "We are processing the document. This may take few seconds, Please wait.",
}

export const SFMessageConstants = {
    MessageRequiredWarning: "Message cannot be empty."
}

export const SFStep2Constants = {
    GetSenderSignOptionDisplayText: (senderSignOption: SenderSignOption): string => {
        let text: string = '';
        switch (senderSignOption) {
            case 0: text = "Sender does not sign";
                break;
            case 1: text = "Sender signs first";
                break;
            case 2: text = "Sender signs last";
                break;
        }
        return text;
    },
    RecipientsRequiredWarning: "Recipients cannot be empty.",
    CCInfo: "Recipients will receive signed documents after signed by all parties.",
    SubjectRequiredWarning: "Subject cannot be empty.",
    RecipientTypeWarning: "Recipient type cannot be empty",
    InvalidEmail: "Please enter valid email address",
    CountryCodeWarning: "Please select a country code for %RECIPIENT%",
    MobileNumberWarning: "Please enter a mobile number for %RECIPIENT%",
    First4SSNWarning: "Please enter first 4 SSN/EIN for %RECIPIENT%",
    Last4SSNWarning: "Please enter last 4 SSN/EIN for %RECIPIENT%",
    AnswerWarning: "Please enter an answer for %RECIPIENT%",
    QuestionWarning: "Please select an authentication question for %RECIPIENT%",
    AuthenticationTypeWarning: "Please select a valid link authentication for %RECIPIENT%",
    SSNFormatWarning: "Please enter valid SSN/EIN for %RECIPIENT%",
    EmptySignerWarning: "Please enter at least one signer",
    ValidMobile: "Please enter a valid mobile number for %RECIPIENT%",
    validSigningOrderRecipient: "Please enter a valid signing order for %RECIPIENT%",
    validSigningOrder: "Please enter a valid signing order",
    validZeroSigningOrder: "Serial number should be more then "+ 0 +".",
    clientNameRequired : "Please enter Client Name.",
    officelocationRequired : "Please select Office Location.",
}



export const SigFlowCompanySettingsConstants = {
    DocumentType: {
        DocumentTypeAddSuccess: 'Document Type added successfully.',
        DocumentTypeAddError: 'Error occured while adding Document Type.',
        DocumentTypeUpdateSuccess: 'Document Type updated successfully.',
        DocumentTypeUpdateError: 'Error occured while updating Document Type.',
        DocumentTypeFetchError: 'Error occured while fetching Document Types.',
        DocumentTypeEditAlert: 'Previously delivered documents will not be affected by the change in the setting.'
    }
}

export const SignatureFlowConstants = {
    ContactsFetchError: 'Error occured while fetching Contacts.',
    SendForSignatureFailed: 'Unable to Send for Signature, Please try again',
    MailMergeFailed: 'Mail Merge failed, Please try again',
    OutForMailMerge: 'Document successfully out for Mail Merge.',
    MailMergeValidateBtnInfo: 'Click validate to verify your data',
    MailMergeInvalidCsvWarning: 'Columns mismatch, please refer template.',
    MailMergeInvalidEmailAddressText: 'Please enter valid Email Address',
    MailMergeRequiredEmailAddressText: 'This is a required field, Please enter valid Email Address.',
    MailMergeCustomFieldText: 'Value upto 80 characters can be entered.',
    SendingForSignatureOverlayMessage: 'Sending for Signature...',
    MailMergeMaxCharsExceededErrorMsg: 'Value of custom fields cannot exceed 80 characters',
    SignatureFieldNotPlacedWarningMsg: "Signature not placed for <Recipient Mail ID> do you want to proceed any way? ",
    SignatureFieldNotPlacedWarningMsgPlaceholder: "<Recipient Mail ID>",
    SignatureFlowSettingsEnableNote: "Note: You will need to re-login to see the changes take effect.",
    SignatureFlowSettingsDownloadNote: "Click to download the SS Mail merge setup file.",
    LastDocumentDeleteNote: "Unable to Delete Last Document",
    SaveSendForSignatureFailed: 'Unable to Save, Please try again',
    DocumentsInUse: 'Document(s) in-use'
}

export const OrganizerConstants = {
    SettingsEnableNote: "Note: You will need to re-login to see the changes take effect.",
    EnableWarning: "Additional charges are incurred for enabling this service.",
    GatherSourceDocuments: "Enable gathering for taxpayer source documents"
}

export const MailMergeDocument = {
    CsvUploadFileWarning: 'Please upload .csv file only.',
    FileInfoMessage: "File info will appear here.",
    MailMergeTemplateFileName: 'MailMergeTemplate.csv',
    DownloadSampleTemplate: 'Download Template',
    HeadingUploadCsv: 'Upload CSV',
    HeadingValidationResult: 'Validation Result',
    SendingForMailMergeOverlayMessage: 'Sending for Mail Merge...',
    DownloadExcelBtnText: 'Download'
}

export enum SignatureReportClientTrackingEvents {
    "None" = 0,
    "Created",
    "Document Mailed",
    "Document Viewed",
    "Document E-Signed",
    "Document Signed and Filed ",
    "Shared ",
    "SendForSignature",
    "Manual reminder sent for Signing",
    "Document Downloaded By CPA",
    "All Documents Downloaded By CPA",
    "Cancelled Document",
    "Signed Document Downloaded By CPA",
    "All Signed Documents Downloaded By CPA",
    "Access Code Email",
    "Declined Email",
    "Download Signed Document(s) Mailed",
    "Signed Document Downloaded",
    "All Signed Documents Downloaded",
    "Access Link Emailed",
    "Document Mailed - Delegated To",
    "Partially Signed Document Downloaded ",
    "Delegation Cancelled",
    "Document Signed And Downloaded",
    "Delegatee Updated",
    "Signing Completion Mailed",
    "Document - Archived",
    "Document - Recycled",
    "Document - Restored From Archive",
    "Document - Restored",
    "KBA Authenticated",
    "KBA Authentication Failed",
    "KBA Validated",
    "KBA Validation Failed",
    "KBA Retry Exceeded",
    "Access Code Mobile",
    "Client Authentication Modified ",
    "Client Access Locked",
    "Signature Stamping Failed",
    "Document Mailed -",
    "Automated reminder sent for Signing",
    "Delivery Failed",
    "Documents downloaded by CPA"
}

export const IPAddressConstant = {
    FromIPWarning: 'Please enter from IP address.',
    ToIPWarning: 'Please enter to IP address.',
    ValidFromIPWarning: 'Please enter a valid from IP address.',
    ValidToIPWarning: 'Please enter a valid to IP address.',
    InvalidIPRangeWarning: 'Invalid IP address range!',
    CloseEditingWarning: 'Close current editing.',
    DeleteIpAddress: 'Are you sure, you want to delete?',
    SaveSuccessfully: 'IP Address saved successfully',
    UpdateSuccessfully: 'IP Address updated successfully',
    DeleteSuccessfully: 'IP Address deleted successfully'
}

export const OTPVerify = {
    InvalidOTP: 'Invalid access code',
    validOTP: 'Access code verified successfully',
}

export const BrandingConstants = {
    StatusMessage: {
        UpdateCompanyWhiteLogoError: 'Unable to update white logo, Please try again',
        DeleteWhiteLogoConfirmation: 'Are sure you want to delete the Uploaded Company White Logo?',
        CompanyWhiteLogoDeleted: 'Company White Logo has been deleted Successfully',
        DeleteCompanyPrimaryLogoConfirmation: 'Are sure you want to delete the Uploaded Company Primary Logo and Set Safesend Returns Logo as Company Logo?'
    },
    OverlayMessage: {
        UpdatingCompanySettings: 'Updating company settings...',
        ApplicationLoading: 'Loading, please wait...'
    },
    Validate: {
        ImageWarning: 'Please upload only image.',
        ImageDimensionWarning: 'Max dimensions are 300x100px.',
        ImageValidation: 'Image validation failed! try again...'
    },
    HeaderName: {
        Branding: 'Branding Section',
        CompanyWhiteLogo: 'Company White Logo',
        ColorPalette: 'Color Palette',
        CompanyWhiteLogoMessage: 'Your selected white logo will be used in all areas that contain a colored background (Company White Logo Dimension should be less than 300x100px)',
        UploadCompanyWhiteLogo: 'Upload Company White Logo',
        ColorPaletteMessage: 'Customize Taxpayer Page colors and Email format colors ',
        ChangeColors: 'Change Colors'
    },
    ContentName: {
        HeaderBgColor: "Header Background Color",
        HeaderFontColor: "Header Font Color",
        LandingPageFolderBgColor: "Folder Background Color",
        LandingPageFolderFontColor: "Folder Font Color"
    }
}

export const customAuthority = {
    customAuthorityId: 100001
}

export const watermarkConstants = {
    NameWarning: 'Please enter name',
    TextWarning: 'Please enter text',
    FontSizeWarning: 'Please enter font size',
    FontSizeValueWarning: 'Please enter the font size between 1 to 72',
    FontFamilyWarning: 'Please select font',
    OpacityWarning: 'Please select opacity',
    DeleteWatermark: 'Are you sure, you want to delete?',
    SaveSuccessfully: 'Watermark saved successfully',
    UpdateSuccessfully: 'Watermark updated successfully',
    DeleteSuccessfully: 'Watermark deleted successfully'
}

export const MyDownloadsConstants = {
    StatusMessage: 'Download process may take some time, the downloaded <FILENAME> will be available in "Downloads"',
    DeleteMyDownload: 'Selected File has been deleted',
    ClearAllMyDownloads: 'All Downloaded files has been deleted',
    DeleteSingleFileWarning: 'File cannot be deleted, since Download is in progress.',
    DialogBox: {
        ConfirmDeletionSingle: 'Are you sure you want to delete this file?',
        ConfirmDeletionAll: 'Are you sure you want to clear all files?',
        Confirm: 'Yes',
        Cancel: 'Cancel',
        Title: 'Confirm Deletion'
    }
}

export const PreviewConstants = {
    Scope: {
        ClientPreview: "client_preview",
        ClientView: "client_view"
    }
}

export const GeneralSettingsConstants = {
    NoTaxSoftwareMessage: "Please select atleast one Tax Software."
}

export const UpdateType = {
    Membership: "membership",
    UserDetail: "userdetail"
}

export enum VoucherPaymentConstants {
    "None" = 0,
    "Payment Voucher" = 1,
    "Q1 Estimated Payment" = 2,
    "Q2 Estimated Payment" = 3,
    "Q3 Estimated Payment" = 4,
    "Q4 Estimated Payment" = 5
}

export const authenticationOptions = [
    { value: "1", label: "First 4 of SSN/EIN" },
    { value: "2", label: "Last 4 of SSN/EIN" },
    { value: "3", label: "Access code (email)" },
    { value: "4", label: "Access code (SMS)" },
    { value: "5", label: "Question & Answer" }
    // { value: "6", label: "KBA *" } hidden as suggested in Task 62085
]

export const recipientOptions = [
    { value: "1", label: "Signer" },
    { value: "2", label: "CC" }
]

export const clientOptions = [
    { value: "1", label: "Signer" },
    { value: "2", label: "CC" },
    { value: "3", label: "Sender" },
]

export const SendReminderConstants = {
    SigningReminderInfo:'Signing Reminder will be sent for statuses: "Out for Signature", "Partially Signed" and "Processing".',
    CannotUpdateReminder: 'User cannot update reminder for the Signature statuses as "E-Signed", "Declined", "Canceled", "Signature Stamping Failed" and "Delivery Failed"',
    CannotSendReminder: 'User cannot send reminder for the Signature statuses as "E-Signed", "Declined", "Canceled", "Signature Stamping Failed" and "Delivery Failed"'
}

export const HeaderConstants = {
    SUPPORT: "Support",
    SS_SUITE: "SafeSend Suite",
    TOOLS: "Tools",
    profile: "Profile",
    mySettings: "My Settings",
    myDownloads: "My Downloads",
    loginHistory: "Login History",
    logout: "Logout"
  };


export const UserAutoLogoutConstants = {
    PermissionChanges: 'Permission Changed'
}

export const UserAutoLogoutMessageConstants = {
    PermissionChanges: 'Your SafeSend permissions have been changed. You must login again for the changes to take effect.'

}

export const HeaderResourceId = 'X-Resource-Id'

export const AccessDeniedConstants ={
    ACCESSDENIED_MESSAGE_USERPERMISSION : "We are sorry, you do not have permission to access Safesend Signatures.\n \b Please contact your system administrator to update your user profile.",
    ACCESSDENIED_MESSAGE_SCREENPERMISSION : " Please consult your system\nadministrator for assistance."
}

export const RESET_USER_STATUS_CACHE_API = `${SSSUITE_API_BASE_URL}/api/user-management/reset-user-status-cache/${ProductType.SignaturesOneOff}`;

export const SizePerPageList = [10,20,30,40,50]

export const AssignToConstant = {
    SelectDocumentWarning: "Please select document(s) to assign to another user!",
    NoUserSelected: "Please select a user to assign document(s)!"
}

export const SearchDelayTimeConstant = 2500;

export const ClientManagementMismatchModalTitle = {
    ReviewAndConfirm: "Review and Confirm Client Information",
    NewClient: "Add New Client Information"
};

export const ClientManagementMismatchModalHeader = {
    NewClient: "Do you wish to add this data into your Client Management records?",
    DataMismatch: "You have changed some information that differs from Client Management. \nWould you like us to update Client Management with this new data?",
    NoDataMismatch: "No Data Mismatch",
};

export enum typeOfSettings {
    None = 0,
    Delegation = 1,
    SavedMessages = 2,
    AuthenticationQuestions = 3,
    DocumentType = 4,
    AccessOptions = 5,
}

export const ChangeOfficeLocationConstants = {
    ChangeOfficeLocationSuccess: "Office Location update may take some time. Thank you for your patience.",
    ChangeOfficeLocationError: "Failed to change the office location.",
    ChangeOfficeLocationMessage : "You've chosen to modify the Office Location for one or more Client Records. This action will be reflected in the Client Management system. Also if the client records are being moved to location to which you are not assigned to then you will no longer have access to it. Are you sure you want to proceed with these updates?",
    ChangeContactPersonMessage : "Client Records are being relocated to a location where the assigned contact person may not be available. Please choose a new contact person from the updated location."
}

export enum keyCodes {
    Enter = 13,
    ArrowUp = 38,
    ArrowDown = 40,
    KeyE = 69,
    NumpadDecimal = 110,
    Period = 190,
    Minus = 189,
    NumpadSubtract = 109,
    NumpadAdd = 107,
    Equal = 187
}

export const ConditionalControlConstant = {
  selectingFieldsError: "Please select the field to apply the rule.",
  SelectingDisabledRuleOption: "This control is already conditional.",
}


export const GetControlColorCode = (recipientNumber: number) => {
    if(recipientNumber > 10) {
        recipientNumber = recipientNumber % 10 == 0 ? 10 : recipientNumber % 10;
    }

    let colorCode = '';
    
    switch (recipientNumber) {
        case 1:
            colorCode = '#542D87';
            break;
        case 2:
            colorCode = '#355ABF';
            break;
        case 3:
            colorCode = '#669440';
            break;
        case 4:
            colorCode = '#AE1B6A';
            break;
        case 5:
            colorCode = '#D36D13';
            break;
        case 6:
            colorCode = '#8B1655';
            break;
        case 7:
            colorCode = '#3A8FC8';
            break;
        case 8:
            colorCode = '#88C656';
            break;
        case 9:
            colorCode = '#CC4A43';
            break;
        case 10:
            colorCode = '#D69F38';
            break;
        default:
            colorCode = '#542D87';
            break;
    }
    return colorCode;
}
