import { IUserBaseModel } from '../../Core/ViewModels/User/UserViewModel';
import { IFilters } from '../components/common/Filters';
import { SigningType } from './SigningInfo';

export interface IDocumentEventData {
    fileName: string;
    ipAddress: string;
    clientType: ClientTypes;
    assignedValue: string;
    mailEventId: MailEvent;
    recipientInfo: IDocumentTransactionUser;
    token: string;
    dob: Date;
    remarks: string;
    isAccessedFromOneHub: boolean;
}

export interface IDocumentTransactionUser {
    firstName: string;
    lastName: string;
    emailAddress: string;
}

export enum MailEvent {
    None,
    Click = 1,
    Delivered = 2,
    Opened = 3,
    Processed = 4,
    Dropped = 5,
    Bounce = 6,
    Deferred = 7,
    SpamReport = 8,
    ScheduleFailed = 9
}

export interface ISignatureFlowReportDocument {
    signingId: number;
    signingInfoGuid: string;
    senderName: string;
    signerInfo: ISignerModel[];
    loggedUser: string;
    signatureStatus: SignatureFlowReportSignatureStatus;
    title: string;
    downloadCount: number;
    documentCount: number;
    documentName: string;
    documentType: string;
    documentNote: string;
    cancelledDocumentDescription: string;
    deliveredOn: Date | undefined;
    expiration: Date | undefined;
    isExpired: boolean;
    deliveredUserDetails: IUserBaseModel;
    signedCount: number;
    signersCount: number;
    signersEmailAddress: string;
    sentOnBehalfOfFirm: string;
    companyName: string;
    documentAccess: IDocumentAccess;
    documentInfo: ISignatureFlowReportsDocumentInfo[];
    downloadableDocuments: IDownloadableDocuments[];
    accessCode: IAccessCode[];
    clientTracking: IDocumentTransaction[];
    downloadHistory: IDocumentTransaction[];
    isMigratedSigningIdForAudit: boolean;
    taxYear: any;
    recipientName: string;
    clientId: string;
    documentStatusChangedOn: Date | undefined;
    DeletedBy: string;
    isValidSender: boolean;
    lastReminder: Date | undefined;
    assignedTo: string;
    documentAssignedTo: IDocumentAssignedTo;
    officeLocation: string;
    signingType: SigningType;
    isUTELinked: boolean;
    hasLockedUteEmail:boolean;
}

export enum SignatureFlowReportSignatureStatus {
    None = 0,
    Processing = 1,
    OutForSignature = 2,
    ESigned = 3,
    Declined = 4,
    Cancelled = 5,
    PartiallySigned = 6,
    SignatureStampingFailed = 7,
    DeliveryFailed =  8
}

export interface IDownloadableDocuments {
    documentId: number;
    fileName: string;
    documentGuid: string;
    signingGuid: string;
    signingId: number;
    isSigningCompleted: boolean;
    fileDownloadName: string;
    size: number;
    storageAccountId: number;
}

export interface ISignerModel {
    clientId: number;
    signingId: number;
    signerEmail: string;
    isSigned: number;
    clientGuid: string;
    signedOn: Date;
    order: number;
    signerType: ClientTypes;
    delegateeId?: number;
}

export enum ClientTypes {
    None = 0,
    Signer = 1,
    CC = 2,
    Sender = 3,
    Delegatee = 4
}

export interface ISignatureFlowReportsDocumentInfo {
    documentId: number;
    signingId: number;
    documentName: string;
    documentGuid: string;
    documentType: string;
    size: string;
    expiresOn: Date;
    storageAccountId: number;
}

export interface ISignatureFlowReportFilter extends IFilters {
    isDefault: number;
}

export interface IDocumentAccess {
    signingId: number;
    userIds: number[];
}

export interface IDocumentAssignedTo {
    signingId: number;
    userId: number;
}

export interface IMailData {
    signers: ISignerModel[];
    documents: ISignatureFlowReportsDocumentInfo[];
    deliveredUser: IUserBaseModel;
    title: string;
}

export interface IAccessCode {
    signerEmail: string,
    clientGuid: string,
    signingId: number,
    clientType: number,
    createdOn: Date,
    otp: string,
    retryCount: number
}

export interface IDocumentTransaction {
    signingId: number,
    eventId: SignatureFlowDocumentEvent,
    actedOn: Date,
    actedBy: IDocumentTransactionUser,
    eventData: IDocumentEventData,
}

export enum SignatureFlowDocumentEvent {
    None = 0,
    Created = 1,
    Emailed = 2,
    Viewed = 3,
    DocumentSigned = 4,
    Filed = 5,
    Shared = 6,
    SendForSignature = 7,
    ReminderSendForSigning = 8,
    DocumentDownloadedByCPA = 9,
    AllDocumentDownloadedByCPA = 10,
    CancelledDocument = 11,
    SignedDocumentDownloadedByCPA = 12,
    AllSignedDocumentDownloadedByCPA = 13,
    AccessCodeEmail = 14,
    DeclinedEmail = 15,
    DownloadEmail = 16,
    SignedDocumentDownloaded = 17,
    AllSignedDocumentDownloaded = 18,
    AccessLinkEmailed = 19,
    EmailedToDelegatee = 20,
    PartialSignedDocumentDownloaded = 21,
    DelegationCancelled = 22,
    SignedAndDownloaded = 23,
    DelegationUpdated = 24,
    SigningCompletionMail = 25,
    Archived = 26,
    Recycled = 27,
    RestoreFromArchive = 28,
    RestoreFromRecycle = 29,
    KBAAuthenticated = 30,
    KBAAuthenticationFailed = 31,
    KBAValidated = 32,
    KBAValidationFailed = 33,
    KBARetryExceeded = 34,
    AccessCodeMobile = 35,
    ClientAuthenticationModified = 36,
    ClientAccessLocked = 37,
    SignatureStampingFailed = 38,
    AccessLinkEmailedToDelegatee = 39,
    AutomatedSigningReminderMailStatus = 40,
    DeliveryFailed = 41,
    BulkDownloadsDownloadedByCPA = 42
}

export type ILinkedMergedDetails = {
    emailId: string;
    clientId: string;
    name: string;
    isPrimaryUTE: boolean;
    isSpouse: boolean;
};

export type ILinkedMergedDetailsResponse = {
    linkedDetails: ILinkedMergedDetails[];
    error: boolean;
};