import * as React from 'react';
import { Modal, Row, Col, Button, Form, Alert } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Link } from 'react-router-dom';
import { ISignatureFlowReportDocument } from '../../../models/SignatureFlowReportState';
let moment = require('moment');
import { OverlayLoader } from '../../../components/helper/OverlayLoader';
import { InfoIcon, InfoIconFill, SuccessTickIcon } from '../../svg/CustomSvgIcons';

interface IDirectDocumentAccessCodeProps {
    selectedDocument: ISignatureFlowReportDocument;
    show: boolean;
    onGenerateOTP(documentId: number, clientType: number, clientGUID: string, callback: () => void): void;
}

interface IColumnValues {
    signersName: string,
    createdOn: Date,
    OTP: string,
    status: string,
    signingId: number,
    clientType: number,
    clientGUID: string,
    date: Date,
}

const msg = {
    saving: 'Generating OTP...',
    loading: 'Loading, please wait...',
    info: "Please note that generating a new code will not result in the sending of a new email."
}

interface IDirectDocumentAccessCodeState {
    message: string;
    isOtpGenerated: boolean;
    alert: string;
    hideAlert: boolean;
}

const successMsg = {
    generatedAccessCode: "Access code generated successfully"
}

export class DirectDocumentAccessCode extends React.Component<IDirectDocumentAccessCodeProps, IDirectDocumentAccessCodeState>{
    constructor(props: IDirectDocumentAccessCodeProps) {
        super(props);
        this.state = {
            message: msg.loading,
            isOtpGenerated: false,
            alert: "success",
            hideAlert: false,
        }
        this.defaultType = this.defaultType.bind(this);
        this.generateOTPButton = this.generateOTPButton.bind(this);
        this.onGenerateOTP = this.onGenerateOTP.bind(this);
        this.getDifferenceInDate = this.getDifferenceInDate.bind(this);
    }
    UNSAFE_componentWillReceiveProps(props: IDirectDocumentAccessCodeProps) {
        if (!props.show) {
            this.setState({ message: msg.loading });
        }
    }
    componentWillUnmount() {
        this.setState({ isOtpGenerated: false, hideAlert: true })
    }

    private defaultType(cell: any, row: IColumnValues) {
        return  <div title={cell} className="ellipsis">{cell}</div>;
    }

    private defaultSignatureStatusType(cell: any, row: IColumnValues) {
        const SignatureStatusClassName = cell == "Active" ? "signature-status-signed" : "signature-status-not-signed"
        return <span className={SignatureStatusClassName}>{cell}</span>;
    }

    private generateOTPButton(cell: any, row: IColumnValues) {

        return <Link to={"#"} onClick={(event) => { this.onGenerateOTP(row) }} style={{color:'#05386B'}}> {"Generate Access Code"}</Link >;
    }
    _toolTip = (cell: any, row: any) => {
        let accessAction;
        this.props.selectedDocument.accessCode.map((value, index) => {
            accessAction = ((this.getDifferenceInDate(value.createdOn) > 20) ? "Generate Access Code" : "");
        });
        return `${accessAction}`;
    }
    private onGenerateOTP(row: IColumnValues) {
        this.setState({ message: msg.saving, isOtpGenerated: true, hideAlert: true },
            () => {
                this.props.onGenerateOTP(row.signingId, row.clientType, row.clientGUID, this.triggerAlert);
            }
        );
    }

    private getDifferenceInDate(createdDate: Date) {
        let date1: any = new Date();
        let date2: any = moment.utc(createdDate).toDate();
        let minute = (date1.getTime() - date2.getTime()) / 60000;
        return minute
    }
   
    private triggerAlert = () => {
        this.setState({ hideAlert: false, alert: "success" })
    }

    private renderNoDataText = () => {
        return (
            <div style={{ textAlign: 'center' }}>
                No data to display
            </div>
        );
    };
    
    public render() {
        let viewAccessCodeData: any[] = [];
        const viewAccessCodeColumn = [
            {
                header: "Email",
                key: 'signersEmail',
                isKey: false,
                dataFormat: this.defaultType,
                width:'235px',
                toolTip: true,
                className: '',

            },
            {
                header: 'Date',
                key: 'createdOn',
                isKey: true,
                dataFormat: this.defaultType,
                toolTip: true,
                width:'155px',
                className: '',
            },
            {
                header: 'Access Code',
                key: 'OTP',
                isKey: false,
                dataFormat: this.defaultType,
                toolTip: true,
                width:'155px',
                className: '',
            },
            {
                header: 'Status',
                key: 'status',
                isKey: false,
                dataFormat: this.defaultSignatureStatusType,
                toolTip: true,
                width:'155px',
                className: '',
            },
            {
                header: 'Action',
                key: 'action',
                isKey: false,
                dataFormat: this.generateOTPButton.bind(this),
                toolTip: this._toolTip,
                width:'195px',
                className: '',
            }
        ];
        if (this.props.selectedDocument && this.props.selectedDocument.accessCode) {
            viewAccessCodeData = this.props.selectedDocument.accessCode.map((value, index) => {
                return {
                    signersEmail: value.signerEmail,
                    createdOn: moment.utc(value.createdOn).local().format('MM/DD/YYYY'),
                    OTP: value.otp,
                    status: this.getDifferenceInDate(value.createdOn) > 20 ? "Expired" : "Active",
                    signingId: value.signingId,
                    clientType: value.clientType,
                    clientGUID: value.clientGuid,
                    date: value.createdOn,
                }
            })
        }
        return this.props.selectedDocument && this.props.selectedDocument.accessCode ?
            (
                <>
                <Alert variant="success" hidden={!this.state.hideAlert}>
                    <SuccessTickIcon />
                    <span className="toaster-message">{successMsg.generatedAccessCode}</span>
                </Alert>
                <Alert variant="info" hidden={this.state.hideAlert}>
                    <InfoIcon />
                    <span className="toaster-message">{msg.info}</span>
                </Alert>
                <div className="direct-document-table" style={{ marginTop: "25px" }}>
                <BootstrapTable data={viewAccessCodeData}
                 headerStyle={{ background: "lightgray" }} 
                 options={{
                    noDataText: this.renderNoDataText()
                }}
                bordered={false}
                striped
                >
                {viewAccessCodeColumn.map((value, index) => {
                    return <TableHeaderColumn
                        key={index}
                        isKey={value.isKey}
                        dataField={value.key}
                        dataFormat={value.dataFormat}
                        className={value.className}
                        columnTitle={value.toolTip}
                        width={value.width}>
                        {value.header}
                    </TableHeaderColumn>;
                })}
            </BootstrapTable></div></>) :( <OverlayLoader
                show={this.props.show}
                text={this.state.message}
                width='94%' />);
       
    }
}
export default DirectDocumentAccessCode;