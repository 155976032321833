import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension'
import { createStore, applyMiddleware,combineReducers } from 'redux';

import { ApplicationState} from './types';
import authReducer from './auth/reducer';
import * as UserProfileStore from './userManagement/UserProfileStore';
import * as UserSettingStore from './userManagement/UserSettingStore';
import * as SuiteMFASettingStore from './Company/SuiteMFASettingStore';
import * as CompanyStore from './Company/CompanyStore';
import * as NotificationStore from '../store/common/NotificationStore';
import * as UserLoginHistory from '../store/userManagement/UserLoginHistoryStore';
import * as MyDownloads from '../store/MyDownloadsStore';
import { reducer as PostAuthStoreReducer } from '../store/PostAuth/PostAuthStore';

//Reports
import * as LockedReportStore from './ReportsStore/LockedReport/LockedReportStore';
import * as RecycleReportStore from './ReportsStore/RecycleReport/RecycleReportStore';
import * as DraftReportStore from './ReportsStore/DraftReport/DraftReportStore';
import * as ArchiveReportStore from './ReportsStore/ArchivedReport/ArchivedReportStore';
import * as DeliveredReportStore from './ReportsStore/DeliveredReport/DeliveredReportsStore';

//SF
import * as DocumentStore from './../store/DocumentStore';
import * as ContactsStore from './../store/ContactsStore';
import * as SFUsersStore from './../store/SFUserStore';
import * as SignatureStore from './../store/SignatureStore';
import * as SignatureFlowReportPagesStore from './ReportsCommon/SignatureFlowReportPageStore';
import * as SignatureFlowReportPopupModelStore from './ReportsCommon/SignatureFlowReportPopupModelStore'
import * as SignatureFlowSettingsStore from './../store/SignatureFlowSettingsStore';
import * as SignatureFlowSavedMessageStore from './../store/settings/SavedMessage/SavedMessageStore';
import * as SignatureFlowReportCustomColumnStore from './ReportsCommon/SignatureFlowReportCustomColumnStore';
import * as SignatureFlowReportFilterStore from './ReportsCommon/SignatureFlowReportFilterStore';
import * as OneTimePasswordStore from '../store/common/OneTimePasswordStore';
import * as SignatureFlowClientInstructionsStore from '../store/settings/ClientInstructions/ClientInstructionsStore';
import * as SignatureFlowStore from '../store/SFStore';
import * as SigningInfoSettingsStore from '../store/SigningInfoSettingsStore';
import * as OfficeLocationStore from '../store/MultiOffice/OfficeLocationStore';
import * as OneHubStore from '../store/common/OneHubStore';
import {reducer as SSSuiteStoreReducer}  from './common/SSSuiteStore';
import {reducer as SSSuiteCompanySettingsStoreReducer} from './Company/SSSuiteCompanySettingsStore';
import * as SignatureFlowAuthenticationQuestionsStore from './../store/settings/AuthenticationQuestions/AuthenticationQuestionsStore';

export const reducers = {
    auth: authReducer,
    postAuthDataStore: PostAuthStoreReducer,
    userProfile: UserProfileStore.reducer,
    userSettings: UserSettingStore.reducer,
    mfaSettings: SuiteMFASettingStore.reducer,
    companyData: CompanyStore.reducer,
    userLoginHistory: UserLoginHistory.reducer,
    signatureFlowSettingsData: SignatureFlowSettingsStore.reducer,
    sfUploadStore: DocumentStore.reducer,
    contactsStore: ContactsStore.reducer,
    signatureFlowReportsDocumentPages: SignatureFlowReportPagesStore.reducer,
    signatureFlowReportCustomColumns: SignatureFlowReportCustomColumnStore.reducer,
    signatureFlowReport: SignatureFlowReportPopupModelStore.reducer,
    signatureFlowReportFilter: SignatureFlowReportFilterStore.reducer,
    sfUserList: SFUsersStore.reducer,
    oneTimePasswordStore: OneTimePasswordStore.reducer,
    sfStore: SignatureFlowStore.reducer,
    signatureStore: SignatureStore.reducer,
    notification: NotificationStore.reducer,
    recognizedSignatureFlowDocumentsInfo: DocumentStore.fetchSignatureDocumentControlsReducer,
    signingInfoSettingsStore: SigningInfoSettingsStore.reducer,
    SSSuiteStore: SSSuiteStoreReducer,
    SSSuiteCompanySettingsStore: SSSuiteCompanySettingsStoreReducer,
    myDownloads: MyDownloads.reducer,
    officeLocation: OfficeLocationStore.officeLocationReducer,
    userOfficeLocation:OfficeLocationStore.userOfficeLocationReducer,
    LockedReportDocuments: LockedReportStore.reducer,
    RecycleReportDocuments: RecycleReportStore.reducer,
    DraftReportDocuments: DraftReportStore.reducer,
    ArchivedReportDocuments : ArchiveReportStore.reducer,
    DeliveredReportDocuments: DeliveredReportStore.reducer,
    SavedMessageData : SignatureFlowSavedMessageStore.reducer,
    ClientInstructionsData: SignatureFlowClientInstructionsStore.reducer,
    AuthenticationQuestionsData: SignatureFlowAuthenticationQuestionsStore.reducer,
    oneHubData: OneHubStore.reducer
}

export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction | AppThunkAction<TAction>) => void, getState: () => ApplicationState): void;
}


export function configureStore(initialState?: ApplicationState) {
    // Build middleware. These are functions that can process the actions before they reach the store.
    const createStoreWithMiddleware = composeWithDevTools(applyMiddleware(thunk))(createStore)

    // Combine all reducers and instantiate the app-wide store instance
    const allReducers = combineReducers(reducers);
    const store = createStoreWithMiddleware(allReducers, initialState)
    return store;
}

const store = configureStore();

export default store;