import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/types';
import * as ArchiveReportStore from '../../../store/ReportsStore/ArchivedReport/ArchivedReportStore';
import * as SignatureFlowReportFilterStore from '../../../store/ReportsCommon/SignatureFlowReportFilterStore';
import * as Component from '../SignatureArchiveReports';
import * as UserStore from '../../../store/SFUserStore';
import * as SignatureFlowReportCustomColumnStore from '../../../store/ReportsCommon/SignatureFlowReportCustomColumnStore';
import * as SignatureFlowReportPopupModelStore from '../../../store/ReportsCommon/SignatureFlowReportPopupModelStore';
import * as UserProfileStore from '../../../store/userManagement/UserProfileStore';
import * as UserSettingsStore from '../../../store/userManagement/UserSettingStore';
import * as SignatureFlowSettingsStore from '../../../store/SignatureFlowSettingsStore';
import * as SignatureFlowAuthenticationQuestionsStore from '../../../store/settings/AuthenticationQuestions/AuthenticationQuestionsStore';
import * as SignatureStore from '../../../store/SignatureStore';
import * as ContactStore from '../../../store/ContactsStore';
import * as SFStore from '../../../store/SFStore';
import * as SigningInfoSettingsStore from '../../../store/SigningInfoSettingsStore';
import * as BulkOperationsStore from '../../../store/SFBulkOperationsStore';
import * as OfficeLocationStore from '../../../store/MultiOffice/OfficeLocationStore';
import * as OneHubStore from '../../../store/common/OneHubStore';

export default connect(
    (state: ApplicationState) => ({
        ArchivedReportDocuments: state.ArchivedReportDocuments, // Selects which state properties are merged into the component's props
        reportCustomColumns: state.signatureFlowReportCustomColumns,
        userStore: state.sfUserList,
        signatureFlowReportDetails: state.signatureFlowReport,
        signatureFlowReportFilter: state.signatureFlowReportFilter,
        profile: state.userProfile.user,
        settings: state.userSettings,
        signatureFlowSettingsData: state.signatureFlowSettingsData,
        companyData: state.companyData,
        signingInfoData: state.signatureStore,
        sfStore: state.sfStore,
        contactsStore: state.contactsStore,
        userOfficeLocationState: state.userOfficeLocation,
        officeLocationState: state.officeLocation,
        signingInfoSettingsData: state.signingInfoSettingsStore,
        pageTitle: 'Archive',
        authenticationQuestionsData: state.AuthenticationQuestionsData,
        oneHubData: state.oneHubData,
    }),
    {
        ...ArchiveReportStore.actionCreators,
        ...SignatureFlowReportCustomColumnStore.actionCreators,
        ...UserStore.actionCreators,
        ...SignatureFlowReportFilterStore.actionCreators,
        ...SignatureFlowReportPopupModelStore.actionCreators,
        ...UserProfileStore.actionCreators,
        ...UserSettingsStore.actionCreators,
        ...SignatureFlowSettingsStore.actionCreators,
        ...BulkOperationsStore.actionCreators,
        ...SignatureStore.actionCreators,
        ...SFStore.actionCreators,
        ...ContactStore.actionCreators,
        ...OfficeLocationStore.actionCreators,
        ...SigningInfoSettingsStore.actionCreators,
        ...SignatureFlowAuthenticationQuestionsStore.actionCreators,
        ...OneHubStore.actionCreators,
    }
)(Component.SignatureArchiveReports as any);
