import * as React from 'react';
import { useImperativeHandle, forwardRef, useState } from 'react';
import { Col, Card, Row } from 'react-bootstrap';
import { ConditionControlOptionsTypes } from './ConditionalControlOptions';
import { ChoosableControlData, IConditionalControlRule, IControlOptionsRule, ISignatureControl, SignatureControlType } from '../../../SignatureFlow/models/SignatureData';
import { CheckBoxComponent } from '../common/CheckBoxComponent';
import { BigXlg, BuleDownownArrow, CirclefillCircle, DashCircle, DisableCheckBoxSvg, PlusCircle } from '../svg/CustomSvgIcons';
import ConditionalControlRuleCard from './ConditionalControlRuleCard';
import { VenusNotifier } from '../helper/VenusNotifier';
import { ConditionalControlConstant } from '../helper/Constants';


export interface IConditionalControlPanelProps {
    updateConditionalControl: (control: ISignatureControl, type : string, item? : ChoosableControlData) => void;
    condtionalControlPanelState: IConditionalControlPanelState;
    closeConditionalControlPanel: () => void;
    updateConditionalControlState: (state : IConditionalControlPanelState) => void;
    onRef?: (ref: any) => any;
    isEditText: boolean;
}

export interface IConditionalControlPanelState {
   selectedPanelOption: ConditionControlOptionsTypes;
   selectedControl : ISignatureControl;
   conditionalControlsRules : IConditionalControlRule[];
   selectingFields: boolean;
   selectingFeildOption: ChoosableControlData | null;
   showDeleteConditionalControlPopup: boolean;
}


export const initialConditionalControlPanelState : IConditionalControlPanelState = {
    selectedPanelOption : ConditionControlOptionsTypes.None,
    selectedControl: {} as ISignatureControl,
    conditionalControlsRules : [] as IConditionalControlRule[],
    selectingFields: false,
    selectingFeildOption: null,
    showDeleteConditionalControlPopup: false
}

const intialRuleCardError = {
    ruleId: 0,
    errorType: ''
}

const ConditionalControlPanel= forwardRef((props: IConditionalControlPanelProps , ref:any) => {
    const [ruleCardError, setRuleCardError] = useState(intialRuleCardError);

    const onRequiredChange = (event: any) => {
        const tempControl = {...props.condtionalControlPanelState.selectedControl};
        tempControl.required = event.target.checked;
        props.updateConditionalControl(tempControl, "updateRequired");
    }

    const onCheckBoxLabelChange = (event: any) => {
        const tempControl = {...props.condtionalControlPanelState.selectedControl};
        tempControl.tooltip = event.target.value;
        props.updateConditionalControl(tempControl, "updateTooltip");
    }

    const generateSettingsHeader= () => {
        return  <div className='conditional-control-settings-panel-header div-flex'>
                {props.condtionalControlPanelState.selectedControl.type == SignatureControlType.Radio &&
                    <>
                    <span><CirclefillCircle/></span>
                    <div className='gray-700-text-color font16 fontWeight700'>Radio Buttons </div>
                    </>
                }
                {props.condtionalControlPanelState.selectedControl.type == SignatureControlType.CheckBox &&
                    <>
                    <span><DisableCheckBoxSvg/></span>
                    <div className='gray-700-text-color font16 fontWeight700'>Checkbox </div>
                    </>
                }
                 <span style={{cursor: 'pointer'}} data-test-auto={"f0b2dfdc-ae79-4d07-af81-3ac418967ad3"} onClick={props.closeConditionalControlPanel}><BigXlg/></span>
             </div>
    }

    const onRemoveControlOption = (item: ChoosableControlData) => {
        if(props.condtionalControlPanelState.selectedControl.items.length >= 2){
            const isRulePresentinOption = props.condtionalControlPanelState.conditionalControlsRules.filter((rule) => rule?.controlOptionsRules.filter((controlRule) => controlRule.selectedOption.id == item.id).length > 0).length > 0;
            if(isRulePresentinOption){
                VenusNotifier.Error("Conditional rule is currently applied to this option. please delete the rule first.", null);
            }
            else{
                const tempControl = {...props.condtionalControlPanelState.selectedControl};
                tempControl.items = tempControl.items.filter((option) => option.id != item.id);
                props.updateConditionalControl && props.updateConditionalControl(tempControl,'deleteControlOption', item);
            }
        }
    }

    const generateSettingsPanel = () => {
        return <div className='conditional-control-settings-panel'>
            <div>
                 {generateSettingsHeader()}
            </div>
            <div style={{padding: "0px 12px"}}>
                <Row> 
                    <Col className='font16 gray-500-text-color fontWeight600' style={{margin: "15px 0 8px 0"}}>
                        SETTINGS
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CheckBoxComponent
                                id={"isConditionlControlRequired"}
                                className='custom-curve-check-box'
                                text={"Required"}
                                checked={props.condtionalControlPanelState.selectedControl.required}
                                onChange={onRequiredChange}
                                datatestAuto='6d57414f-72bb-4850-ae39-b2697e0ef287'
                            />
                    </Col>
                </Row>
                {props.condtionalControlPanelState.selectedControl.type == SignatureControlType.CheckBox &&
                    <Row>
                        <Col  sm={12} className='gray-500-text-color font12 fontWeight600' style={{margin: "10px 0 0 0"}}> TOOLTIP </Col>
                        <Col sm={12}>
                            <input type="text" 
                                   className='checkbox-label'
                                   data-testid = '6f736afe-f703-4b34-91b8-889606078258'
                                   title={props.condtionalControlPanelState.selectedControl.tooltip} 
                                   onChange={onCheckBoxLabelChange} 
                                   value={props.condtionalControlPanelState.selectedControl.tooltip} />
                        </Col>
                    </Row>
                }
                {props.condtionalControlPanelState.selectedControl.type == SignatureControlType.Radio &&
                    <Row>
                        <Col  sm={12} className='gray-500-text-color font12 fontWeight600' style={{margin: "10px 0 0 0"}}> OPTIONS </Col>
                        {props.condtionalControlPanelState.selectedControl.items.map((item, index) => {
                            return (<Col sm={12} className='conditional-control-settings-options'>
                                <input type="text" value={`Option ${item?.controlNumber ? item.controlNumber : index+1}`} disabled/>
                                <span data-testid ={`d25871c2-7fce-49e5-98f8-6731be4457c${index}`} onClick={() => onRemoveControlOption(item)}><DashCircle fill='#CC4A43'/></span>
                            </Col>);
                        })}
                        <Col sm ={12} style={{margin: "8px 5px"}}>
                            <button disabled={props.condtionalControlPanelState.selectedControl.items.length == 5}
                                onClick={()=> props.updateConditionalControl(props.condtionalControlPanelState.selectedControl, "addControlOption")}>
                                    <span data-testid='e5d46e0f-24c9-45fe-a79b-f2ce0ed9b294'><PlusCircle/></span>Add
                            </button>
                        </Col>
                    </Row>
                }
            </div>
        </div>
    }

    const generateRulesHeader= () => {
        const showWhenText = props.condtionalControlPanelState.selectedControl.type == SignatureControlType.Radio ? "RadioButton" : "Checkbox";

        return  <div className='conditional-control-rules-panel-header'>
                 {props.isEditText ? <div className='fontWeight700 font16'>Edit Rule</div>
                 :<>
                 <div className='fontWeight700 font16'>Add Rule</div>
                 <div className='when-box'>
                    <div className='fontWeight700'> When </div>
                    <div className='when-control-box'><span>{showWhenText}</span></div>
                    <span>is selected</span>
                 </div>

                 <div style={{paddingLeft:'2px'}}><BuleDownownArrow/></div></>}
                 
             </div>
    }

    const getSelectedControlRules = (condtionalControlPanelState : IConditionalControlPanelState) => {    
        return condtionalControlPanelState?.conditionalControlsRules.filter((rule) => rule.conditionalControl.controlGuid == condtionalControlPanelState.selectedControl.controlGuid)[0];
    }

    const isLastRuleOptionSelected = () => {
        return getSelectedControlRules(props.condtionalControlPanelState)?.controlOptionsRules.slice(-1).pop().selectedOption?.id;
    }

    const onChangeSelectedOption = (value: string,ruleId: number) => {
        let temp_condtionalControlPanelState = Object.assign({},props.condtionalControlPanelState);
        let selectedoptionData = props.condtionalControlPanelState.selectedControl.items.filter((option) => option.id == value)[0];
        let temp_ruleObject = getSelectedControlRules(temp_condtionalControlPanelState).controlOptionsRules.find((rule) => rule.ruleId == ruleId);
        temp_ruleObject.selectedOption = value == "" ? {} as ChoosableControlData : selectedoptionData;
        props.updateConditionalControlState && props.updateConditionalControlState(temp_condtionalControlPanelState)
    }

    const isOptionAlreadySelected = (optionId: string) => {
        return getSelectedControlRules(props.condtionalControlPanelState)?.controlOptionsRules.filter((rule) => rule.selectedOption.id == optionId).length > 0;
    }

    const addAnotherRule = () => {        
        const getControlRules = getSelectedControlRules(props.condtionalControlPanelState)
        if(getControlRules?.controlOptionsRules?.length == props.condtionalControlPanelState.selectedControl.items.length || props.condtionalControlPanelState.selectingFields){
            return;
        }
        
        if(getControlRules?.controlOptionsRules?.length < 5 && isLastRuleOptionSelected()){
            let temp_condtionalControlPanelState = Object.assign({},props.condtionalControlPanelState);
            let selectedControlRule = getSelectedControlRules(temp_condtionalControlPanelState);
            selectedControlRule.controlOptionsRules.push({ ruleId: selectedControlRule.controlOptionsRules[selectedControlRule.controlOptionsRules.length -1].ruleId + 1, 
                                                            selectedOption: {} as ChoosableControlData, 
                                                            selectedControls: [] as ISignatureControl[]});
                
            props.updateConditionalControlState && props.updateConditionalControlState(temp_condtionalControlPanelState)                                               
        }
    }

    const onChangeSelctingFields = (option : ChoosableControlData,value: boolean) => {
        let temp_condtionalControlPanelState = Object.assign({},props.condtionalControlPanelState);
        temp_condtionalControlPanelState.selectingFields = value;
        temp_condtionalControlPanelState.selectingFeildOption = option;
        props.updateConditionalControlState && props.updateConditionalControlState(temp_condtionalControlPanelState)
    }

    const validatesignatureRules = () => {
        let selectedControlRules = getSelectedControlRules(props.condtionalControlPanelState);

        if(props.condtionalControlPanelState.selectingFields){
            VenusNotifier.Error(ConditionalControlConstant.selectingFieldsError,null);
            return false;
        }

        if(props.condtionalControlPanelState.selectedControl.type == SignatureControlType.CheckBox){
            if(selectedControlRules.controlOptionsRules[0].selectedControls.length == 0){
                 setRuleCardError({ruleId : selectedControlRules.controlOptionsRules[0].ruleId, errorType : 'showControlError'});
                return false;
            }
        }

        else{
            const findEmptyRuleOption = selectedControlRules.controlOptionsRules.filter((rule) => !Object.keys(rule.selectedOption)?.length);
            const findEmptyRuleFields = selectedControlRules.controlOptionsRules.filter((rule) => rule.selectedControls.length == 0);

            if(selectedControlRules.controlOptionsRules.length == 0){
                return false;
            }
            else if(findEmptyRuleOption.length > 0){
                setRuleCardError({ruleId : findEmptyRuleOption[0].ruleId, errorType : 'showOptionError'});
                return false;
            }
            else if(findEmptyRuleFields.length > 0){
                setRuleCardError({ruleId : findEmptyRuleFields[0].ruleId, errorType : 'showControlError'});
                return false;
            }
        }

        return true;
    }

    const onRemoveSelectedControlRule = (type: string, selectedRule : IControlOptionsRule, control? : ISignatureControl) => {
        let temp_condtionalControlPanelState = Object.assign({},props.condtionalControlPanelState);
        let selectedControlRules = getSelectedControlRules(temp_condtionalControlPanelState);

        if(type == 'Remove-Rule'){
            if(selectedControlRules.controlOptionsRules.length == 1){
                selectedControlRules.controlOptionsRules=[ { ruleId: 1, selectedOption: {} as ChoosableControlData, selectedControls: [] as ISignatureControl[]}];
            }
            else{
                selectedControlRules.controlOptionsRules = selectedControlRules.controlOptionsRules.filter((controlRule) => controlRule.ruleId != selectedRule.ruleId);
            }

        }
        else if(type == 'Remove-Fields'){
            selectedControlRules.controlOptionsRules = selectedControlRules.controlOptionsRules.map((controlRule) => controlRule.ruleId == selectedRule.ruleId ? {...controlRule, selectedControls:[]} : controlRule);
        }

        else if(type == 'Remove-Field'){
            selectedControlRules.controlOptionsRules = selectedControlRules.controlOptionsRules.map((controlRule) => controlRule.ruleId == selectedRule.ruleId ? {...controlRule, selectedControls: controlRule.selectedControls.filter((x) => x.controlGuid != control.controlGuid)} : controlRule);
        }
        props.updateConditionalControlState && props.updateConditionalControlState(temp_condtionalControlPanelState)
    }

    const removeRuleCardError = () =>{
       setRuleCardError(intialRuleCardError);
    }

    const generateRulesPanel = () => {

        let selectedControlRules = getSelectedControlRules(props.condtionalControlPanelState);
        
        return <div className='conditional-control-rules-panel'>
                    {generateRulesHeader()}
                    <div>
                        {selectedControlRules?.controlOptionsRules.length >= 1 &&
                                selectedControlRules.controlOptionsRules.map((rule,index) => { 
                                    return <ConditionalControlRuleCard
                                                key={rule.ruleId+index}
                                                rule={rule} 
                                                selectedContorl = {props.condtionalControlPanelState.selectedControl}
                                                onChangeSelectedOption={onChangeSelectedOption}
                                                onChangeSelctingFields = {onChangeSelctingFields}
                                                isOptionAlreadySelected={isOptionAlreadySelected}
                                                onRemoveSelectedControlRule={onRemoveSelectedControlRule}
                                                selectingFields= {props.condtionalControlPanelState.selectingFields}
                                                selectingFeildOption={props.condtionalControlPanelState.selectingFeildOption}
                                                ruleCardError= {ruleCardError}
                                                removeRuleCardError= {removeRuleCardError}/>
                        })}                                                             
                    </div>
                    {props.condtionalControlPanelState.selectedControl.type == SignatureControlType.Radio &&
                        <div className='add-new-rule' style={{textAlign:'center'}}>
                            <a className={getSelectedControlRules(props.condtionalControlPanelState)?.controlOptionsRules?.length >= 5 || !isLastRuleOptionSelected() || props.condtionalControlPanelState.selectingFields || getSelectedControlRules(props.condtionalControlPanelState)?.controlOptionsRules?.length == props.condtionalControlPanelState.selectedControl.items.length ? 'disabled' : ''}
                                    onClick={addAnotherRule}>
                                        <span><PlusCircle/></span>Add Another Condition
                            </a>
                        </div>
                    }
                </div>
    }

    const renderConditionalPanel = () => {
        switch(props.condtionalControlPanelState.selectedPanelOption){
        
        case ConditionControlOptionsTypes.Settings:
            return generateSettingsPanel();
        
        case ConditionControlOptionsTypes.Rules:
            return generateRulesPanel();

        default : 
            return <div></div>;
        }
    }

    useImperativeHandle(ref, () => ({
        validatesignatureRules
    }));

    return (<Card className='conditional-control-panel'>
            <Card.Body>
                    {renderConditionalPanel()}
            </Card.Body>
        </Card>);
})

export default ConditionalControlPanel;