import * as React from "react";
import { Modal, Row, Col, Button, Tabs, Tab, Form } from "react-bootstrap";
import "../../../../assets/custom/ViewAccessCodeModal.css";
import { InfoIconFill } from "../../svg/CustomSvgIcons";
import { DirectDocumentAccessCode } from "./DirectDocumentAccessCode";
import { IOnehubAccessCodeModel } from "../../common/OneHubAccessCodeModal";
import { ISignatureFlowReportDocument } from "src/SignatureFlow/models/SignatureFlowReportState";
import OnehubAccessCode from "./OneHubAccessCode";

interface IViewAccessCodeModalProps {
    show: boolean;
    onCancel(): void;
    selectedDocument: ISignatureFlowReportDocument;
    oneHubAccessModel: IOnehubAccessCodeModel[];
    onGenerateOTP(documentId: number, clientType: number, clientGUID: string, callback: () => void): void;
    onGenerateOnehubAccessCode(primaryEmailAddress: string, callback: () => void): void;
}

interface ICustomModalState {
    activeKey: string | null;
}

export class ViewAccessCode extends React.Component<IViewAccessCodeModalProps, ICustomModalState> {
    constructor(props: IViewAccessCodeModalProps) {
        super(props);
        this.state = {
            activeKey: "direct_document"
        };
    }

    // Reset the tab to the first tab on close
    UNSAFE_componentWillReceiveProps(nextProps: IViewAccessCodeModalProps) {
        if (!nextProps.show) {
            this.setState({ activeKey: "direct_document" });
        }
    }

    componentWillUnmount() {
        this.setState({ activeKey: "direct_document" });
    }

    // Handle tab selection
    private handleTabSelect = (activeKey: string | null) => {
        this.setState({ activeKey: activeKey });
    };

    private onCancel = () => {
        this.props.onCancel();
    };

    public render() {
        return (
            <div>
                <Modal centered={true} className="individual-view-access-code-modal" show={this.props.show} onHide={this.onCancel}>
                <Modal.Header closeButton onClick={this.onCancel} data-test-auto="C98D7824-8C63-4A74-95E6-4D3C73B08677">
                    <Modal.Title className="view-acces-code-header">Access Code</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        <Tabs
                            className="custom-tab"
                            fill
                            id="direct_document"
                            activeKey={this.state.activeKey}
                            onSelect={(key) => this.handleTabSelect(key)}
                        >
                            <Tab
                                eventKey="direct_document"
                                title={
                                <div>
                                    <span className="view-acces-code-tab">Direct Document</span>
                                </div>}
                                style={{ overflowX: "hidden" }}
                            >
                                   <DirectDocumentAccessCode
                                        selectedDocument={this.props.selectedDocument}
                                        onGenerateOTP={this.props.onGenerateOTP}
                                        show={this.props.show}
                                   />
                            </Tab>
                            {this.props.oneHubAccessModel?.length > 0 && (
                            <Tab
                                eventKey="onehub_access"
                                title={
                                <div>
                                    <span className="view-acces-code-tab">Client Portal</span>
                                </div>}
                                style={{ overflowX: "hidden" }}
                            >
                                    <OnehubAccessCode
                                    selectedDocument={this.props.selectedDocument}
                                        oneHubAccessModel={this.props.oneHubAccessModel}
                                        show={this.props.show}
                                        onGenerateOnehubAccessCode={this.props.onGenerateOnehubAccessCode}
                                    />
                            </Tab>
                            )}
                        </Tabs>
                    </Modal.Body>
                    <Modal.Footer>
                        <Row style={{ display: "contents" }}>
                            <Col sm={4}>
                                <Button
                                    data-test-auto="DAE73B5C-9114-46EB-BDE7-0EC8BA0D4B0D"
                                    onClick={this.onCancel}
                                    className="close-button"
                                >
                                    <span className="close-button-text">Close</span>
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default ViewAccessCode;
