import { addTask } from 'domain-task';
import { Reducer } from 'redux';
import { AppThunkAction } from '../store';
import { API_BASE_URL } from '../../utils/AppConstants';
import { actionTypes } from '../store/ActionTypes';
import { ISignatureFlowSettings, IMessage, initialSignatureFlowSettings, IDocumentType, IAuthenticationQuestion } from '../models/SignatureFlowSettings';
import { handleResponse } from './Library';
import { NotificationAction, StatusType } from '../store/common/NotificationStore';
import { IReportProblemDetails } from '../components/common/ReportProblemModel';
import * as Constants from '../components/helper/Constants';
import { validateError } from '../components/helper/Validations';

import { logger } from '../../routes/LoggedIn';

export interface ISignatureFlowSettingsData {
    signatureFlowSettings: ISignatureFlowSettings;
    userDelegations: number[];
    userDelegatees: number[];
    documentTypes: IDocumentType[];
    authenticationQuestions: IAuthenticationQuestionDictionary;
    storageAccountId: number;

    //Meta infomation
    isLoading: boolean;
    error: boolean;
    message: string;
}

export interface ISavedMessageDictionary {
    [index: number]: SavedMessageState;
}

export interface SavedMessageState {
    messageDetails: IMessage;
}

export interface IAuthenticationQuestionDictionary {
    [index: number]: AuthenticationQuestionState;
}

export interface AuthenticationQuestionState {
    authenticationQuestion: IAuthenticationQuestion;
}

export interface RequestSignatureFlowSettingsAction {
    type: actionTypes.REQUEST_SIGNATURE_FLOW_SETTINGS;
    message: string;
}

export interface ReceiveSignatureFlowSettingsAction {
    type: actionTypes.RECEIVE_SIGNATURE_FLOW_SETTINGS;
    settings: ISignatureFlowSettings;
}

export interface ErrorSignatureFlowSettingsAction {
    type: actionTypes.ERROR_SIGNATURE_FLOW_SETTINGS;
    reason: string;
}

export interface ReceiveUserDelegation {
    type: actionTypes.RECEIVE_SIGNATURE_FLOW_USER_DELEGATION;
    delegatedUsers: number[]
}

export interface ReceiveUserDelegatee {
    type: actionTypes.RECEIVE_SIGNATURE_FLOW_USER_DELEGATEE;
    delegateeUsers: number[]
}

export interface FetchSignerDelegation {
    type: actionTypes.FETCH_SIGNATURE_FLOW_SIGNER_DELEGATION;
    signerDelegation:boolean;
}

export interface PutSignerDelegation {
    type: actionTypes.PUT_SIGNATURE_FLOW_SIGNER_DELEGATION;
    signerDelegation: boolean
}

export interface ReceiveDocumentTypes {
    type: actionTypes.RECEIVE_SIGNATURE_FLOW_DOCUMENT_TYPES;
    documentTypes: IDocumentType[];
}

export interface ReceiveStorageAccountId {
    type: actionTypes.RECEIVE_STORAGE_ACCOUNT_ID;
    storageAccountId: number;
}

export interface ReceiveDocumentType {
    type: actionTypes.RECEIVE_SIGNATURE_FLOW_DOCUMENT_TYPE;
    documentType: IDocumentType;
    documentTypeId: number
}

export interface ResetSignatureFlowSettingsData {
    type: actionTypes.RESET_SF_SETTINGS_DATA;
}

export interface ReceiveSignatureAuthenticationQuestions {
    type: actionTypes.RECEIVE_SIGNATURE_FLOW_AUTHENTICATION_QUESTIONS;
    authenticationQuestions: IAuthenticationQuestion[];
}

export interface ReceiveSignatureAuthenticationQuestion {
    type: actionTypes.RECEIVE_SIGNATURE_FLOW_AUTHENTICATION_QUESTION;
    question: IAuthenticationQuestion;
    questionId: number;
}

export interface DeleteSignatureFlowAuthenticationQuestion {
    type: actionTypes.DELETE_SIGNATURE_FLOW_AUTHENTICATION_QUESTION;
    question: IAuthenticationQuestion;
}

type DispatchAction = RequestSignatureFlowSettingsAction |
    ReceiveSignatureFlowSettingsAction |
    ErrorSignatureFlowSettingsAction |
    ReceiveUserDelegation |
    ReceiveUserDelegatee |
    ReceiveDocumentTypes |
    ReceiveDocumentType |
    ReceiveSignatureAuthenticationQuestions |
    ReceiveSignatureAuthenticationQuestion |
    DeleteSignatureFlowAuthenticationQuestion |
    ResetSignatureFlowSettingsData |
    NotificationAction|
    FetchSignerDelegation |
    PutSignerDelegation |
    ReceiveStorageAccountId;

export const actionCreators = {
    requestCompanySignatureSettings: (callback?: (data: ISignatureFlowSettings) => void): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/SignatureFlowSettings/GetSignatureFlowSettings', {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(json => json as Promise<ISignatureFlowSettings>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_SIGNATURE_FLOW_SETTINGS, settings: data });
                if(callback){
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.CompanySettingsConstants.StatusMessage.CompanySettingsError,
                    statusType: StatusType.Error,
                    statusCode:error?.status
                })
                dispatch({
                    type: actionTypes.ERROR_SIGNATURE_FLOW_SETTINGS,
                    reason: error.message,
                });
            });
        addTask(fetchTask);
        dispatch({
            type: actionTypes.REQUEST_SIGNATURE_FLOW_SETTINGS,
            message: Constants.CompanySettingsConstants.OverlayMessage.ApplicationLoading
        });
    },

    updateCompanySettings: (signatureFlowSettings: ISignatureFlowSettings, settingsType?: Constants.typeOfSettings): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +`api/SignatureFlow/SignatureFlowSettings/?settingsType=${settingsType}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, *',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(signatureFlowSettings)
        })
            .then(handleResponse)
            .then((response) => {
                dispatch({ type: actionTypes.RECEIVE_SIGNATURE_FLOW_SETTINGS, settings: signatureFlowSettings });

                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.CompanySettingsConstants.StatusMessage.UpdateCompanySettingsSuccess,
                    statusType: StatusType.Success
                })
            })
            .catch((error) => {
                const message = validateError(error);
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.CompanySettingsConstants.StatusMessage.UpdateCompanySettingsError,
                    statusType: StatusType.Error, statusCode:error?.status
                });
                dispatch({
                    type: actionTypes.ERROR_SIGNATURE_FLOW_SETTINGS,
                    reason: message,
                });

                logger.trackError(`updateCompanySettings failed for the request having parameters ${JSON.stringify(signatureFlowSettings)} with error ${message}`)
            })

        addTask(fetchTask);
        dispatch({
            type: actionTypes.REQUEST_SIGNATURE_FLOW_SETTINGS,
            message: Constants.CompanySettingsConstants.OverlayMessage.UpdatingCompanySettings
        });
    },

    reportTaxDocumentProblem: (problemDetails: IReportProblemDetails, urlPath: string, callback?: () => void): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        const fetchTask = fetch(urlPath, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(problemDetails),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then((response) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.ReportProblemConstants.SuccessMessage + response,
                    statusType: StatusType.Success
                });
                if (callback) {
                    callback();
                }
            })
            .catch(error => {

                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.ReportProblemConstants.ErrorMessage,
                    statusType: StatusType.Error, statusCode:error?.status
                });

                logger.trackError(`reportTaxDocumentProblem failed for the request having parameters ${JSON.stringify(problemDetails)} with error ${validateError(error)}`)
            });
        addTask(fetchTask);
    },
    requestSignatureFlowUserDelegatee: (): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/SignatureFlowSettings/GetSignatureFlowUserDelegatee', {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(data => {
                dispatch({
                    type: actionTypes.RECEIVE_SIGNATURE_FLOW_USER_DELEGATEE, delegateeUsers: data
                });
            })
            .catch(error => {
                const message = validateError(error);
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: message,
                    statusType: StatusType.Error, statusCode:error?.status
                })
                logger.trackError(`requestSignatureFlowUserDelegatee failed  with error ${message}`)
            });
        addTask(fetchTask);
    },

    updateSignatureFlowUserDelegation: (delegateIds: number[]): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/SignatureFlowSettings/SaveSignatureFlowUserDelegation/', {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(delegateIds),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.RECEIVE_SIGNATURE_FLOW_USER_DELEGATION, delegatedUsers: delegateIds
                });
            }).catch(error => {
                const message = validateError(error);
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: message, statusType: StatusType.Error, statusCode:error?.status
                });

                logger.trackError(`updateSignatureFlowUserDelegation failed for the request having delegateIds ${JSON.stringify(delegateIds)} with error ${message}`)
            });
        addTask(fetchTask);
    },

    requestDocumentTypes: (resourceId: string): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/DocumentType/GetAllDocumentOptions', {
            method: 'GET',
            credentials: 'include',
            headers: { [Constants.HeaderResourceId]: resourceId }
        })
            .then(handleResponse)
            .then(json => json as Promise<IDocumentType[]>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_SIGNATURE_FLOW_DOCUMENT_TYPES, documentTypes: data });
            })
            .catch(error => {
                const message = validateError(error);

                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.SigFlowCompanySettingsConstants.DocumentType.DocumentTypeFetchError,
                    statusType: StatusType.Error, statusCode:error?.status
                })
                dispatch({
                    type: actionTypes.ERROR_SIGNATURE_FLOW_SETTINGS,
                    reason: message,
                });

                logger.trackError(`requestDocumentTypes failed with error ${message}`)
            });
        addTask(fetchTask);
    },

    updateDocumentType: (documentType: IDocumentType, callback?: () => void): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/DocumentType', {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(documentType),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.RECEIVE_SIGNATURE_FLOW_DOCUMENT_TYPE, documentType: documentType, documentTypeId: documentType.id
                });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.SigFlowCompanySettingsConstants.DocumentType.DocumentTypeUpdateSuccess,
                    statusType: StatusType.Success
                });
                if (callback) {
                    callback();
                }
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.SigFlowCompanySettingsConstants.DocumentType.DocumentTypeUpdateError,
                    statusType: StatusType.Error, statusCode:error?.status
                });

                logger.trackError(`updateDocumentType failed for the request having parameters ${JSON.stringify(documentType)} with error ${validateError(error)}`)
            });
        addTask(fetchTask);
    },

    saveDocumentType: (documentType: IDocumentType, callback?: () => void): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/DocumentType', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(documentType),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then((newId) => {
                let savedDocumentType = Object.assign({}, documentType);
                savedDocumentType.id = newId
                dispatch({
                    type: actionTypes.RECEIVE_SIGNATURE_FLOW_DOCUMENT_TYPE, documentType: savedDocumentType, documentTypeId: newId
                });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.SigFlowCompanySettingsConstants.DocumentType.DocumentTypeAddSuccess,
                    statusType: StatusType.Success
                });
                if (callback) {
                    callback();
                }
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.SigFlowCompanySettingsConstants.DocumentType.DocumentTypeAddError,
                    statusType: StatusType.Error, statusCode:error?.status
                });

                logger.trackError(`saveDocumentType failed for the request having parameters ${JSON.stringify(documentType)} with error ${validateError(error)}`)
            });
        addTask(fetchTask);
    },


    resetSignatureFlowSettings: (): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.RESET_SF_SETTINGS_DATA })
    },
    requestSignatureFlowSignerDelegation: (): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/SignatureFlowSettings/GetSignatureFlowSignerDelegation', {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(data => {
                dispatch({
                    type: actionTypes.FETCH_SIGNATURE_FLOW_SIGNER_DELEGATION,
                    signerDelegation:data
                });
                console.log("data from reducer",data);
            })
            .catch(error => {
                const message = validateError(error);
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.CompanySettingsConstants.StatusMessage.CompanySettingsError,
                    statusType: StatusType.Error, statusCode:error?.status
                })
                logger.trackError(`requestSignatureFlowSignerDelegation failed  with error ${message}`)
            });
        addTask(fetchTask);
    },
    updateSignatureFlowSignerDelegation: (signerAllowedToDelegate:boolean): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/SignatureFlowSettings/SaveSignatureFlowSignerDelegation?signerAllowedToDelegate='+signerAllowedToDelegate,{
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value,
                // 'X-Resource-Id' : 'SIGS_L_GENSETTINGS'
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.PUT_SIGNATURE_FLOW_SIGNER_DELEGATION, signerDelegation : signerAllowedToDelegate
                });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.CompanySettingsConstants.StatusMessage.UpdateCompanySettingsSuccess,
                    statusType: StatusType.Success
                })
            }).catch(error => {
                const message = validateError(error);
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.CompanySettingsConstants.StatusMessage.UpdateCompanySettingsError, statusType: StatusType.Error, statusCode:error?.status
                });

                logger.trackError(`updateSignatureFlowSignerDelegation failed with error ${message}`)
            });
        addTask(fetchTask);
    },
    requestStorageAccountId: (): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/Document/StorageAccount/Id', {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(json => json as Promise<number>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_STORAGE_ACCOUNT_ID, storageAccountId: data });
            })
            .catch(error => {
                const message = validateError(error);

                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.SigFlowCompanySettingsConstants.DocumentType.DocumentTypeFetchError,
                    statusType: StatusType.Error, statusCode:error?.status
                })
                dispatch({
                    type: actionTypes.ERROR_SIGNATURE_FLOW_SETTINGS,
                    reason: message,
                });

                logger.trackError(`requestStorageAccountId failed with error ${message}`)
            });
        addTask(fetchTask);
    }
};

const savedMessageDic: ISavedMessageDictionary = [];
const authenticationQuestionDic: IAuthenticationQuestionDictionary = [];

const unloadedState: ISignatureFlowSettingsData = {
    signatureFlowSettings: initialSignatureFlowSettings,
    documentTypes: [] as IDocumentType[],
    error: false,
    isLoading: false,
    message: "",
    userDelegations: [] as number[],
    userDelegatees: [] as number[]
} as ISignatureFlowSettingsData;

export const reducer: Reducer<ISignatureFlowSettingsData> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_SIGNATURE_FLOW_SETTINGS:
            var received = { ...state };
            received.isLoading = true;
            received.error = false;
            return received;
        case actionTypes.RECEIVE_SIGNATURE_FLOW_SETTINGS:
            var received = { ...state };
            received.signatureFlowSettings = action.settings;
            received.isLoading = false;
            received.error = false;
            received.message = Date();
            return received;
        case actionTypes.ERROR_SIGNATURE_FLOW_SETTINGS:
            return {
                signatureFlowSettings: Object.assign({}, state.signatureFlowSettings),
                documentTypes: Object.assign({}, state.documentTypes),
                userDelegations: Object.assign({}, state.userDelegations),
                authenticationQuestions: Object.assign({}, state.authenticationQuestions),
                isLoading: false,
                error: true,
                message: action.reason
            } as ISignatureFlowSettingsData;
        case actionTypes.RECEIVE_SIGNATURE_FLOW_USER_DELEGATION:
            var received = { ...state }
            received.userDelegations = action.delegatedUsers;
            received.isLoading = false;
            return received;
        case actionTypes.RECEIVE_SIGNATURE_FLOW_USER_DELEGATEE:
            var received = { ...state }
            received.userDelegatees = action.delegateeUsers;
            received.isLoading = false;
            return received;
        case actionTypes.RECEIVE_SIGNATURE_FLOW_DOCUMENT_TYPES:
            var received = { ...state };
            received.documentTypes = action.documentTypes;
            received.isLoading = false;
            return received;
        case actionTypes.RECEIVE_SIGNATURE_FLOW_DOCUMENT_TYPE:
            var currentState = { ...state };
            let index = currentState.documentTypes.findIndex(type => type.id === action.documentType.id);
            index !== -1 ?
                currentState.documentTypes[currentState.documentTypes.findIndex(type => type.id === action.documentType.id)] = action.documentType :
                currentState.documentTypes.push(action.documentType)
            return currentState;
       
        case actionTypes.RESET_SF_SETTINGS_DATA:
            return { ...unloadedState } as ISignatureFlowSettingsData;
   
        case actionTypes.PUT_SIGNATURE_FLOW_SIGNER_DELEGATION:
            var received = {...state}
            received.signatureFlowSettings.delegationSettings.signerDelegation = action.signerDelegation;
            return received;
        case actionTypes.FETCH_SIGNATURE_FLOW_SIGNER_DELEGATION:
            var received = {...state}
            received.signatureFlowSettings.delegationSettings.signerDelegation = action.signerDelegation;
            return received;
        case actionTypes.RECEIVE_STORAGE_ACCOUNT_ID:
            var received = {...state}
            received.storageAccountId = action.storageAccountId;
            return received;
    }   

    return state;
};
