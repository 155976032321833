import ControlBase from 'awesome-pdf-viewer/dist/Controls/ControlBase';
import { PlaceholderControlProps } from 'awesome-pdf-viewer/dist/Controls/PlaceholderControl/PlaceholderControl';
import { PageSize } from 'awesome-pdf-viewer/dist/layout/LayoutBase';
import { ISignatureControl, ISignatureData, SignatureControlRole, SignatureControlType } from '../../../models/SignatureData';

export const CONTROL_ID_PREFIX = "control_";
export const CONTROL_KEY_PREFIX = "control_key_";

export class StepsHelper {

    public static updateFormControls(controls: any[], signatureData: ISignatureData, pageSize: PageSize): ISignatureData {

        signatureData.signatureControls = [];

        let pageControls: any[] = controls.filter(x => x && x.props.page == signatureData.pageNo);

        pageControls.forEach(function (control: any, index: number, controls: any[]) {
            if (control.state.top == 0) {
                control.state.top = 3;
            }

            const controlProps: PlaceholderControlProps = control.state.top ? control.state : control.props;
            const controlData: PlaceholderControlProps = control.props.data;


            var backendtop = ControlBase.getBackendControlTopPosition(pageSize.height, controlProps.top);
            var backendleft = ControlBase.getBackendControlLeftPosition(controlProps.left);

            let signatureControl: ISignatureControl = {
                controlGuid: (control as any).props.id.replace(CONTROL_ID_PREFIX, ""),
                signer: (controlData as any).signer,
                type: (controlData as any).type,
                top: backendtop,
                left: backendleft,
                role: (controlData as any).role,
                tooltip: ((controlData as any).type === SignatureControlType.Radio) ? (controlData as any).tooltip : (control as any).props.helptext || "",
                required: (control as any).props.isRequired || false,
                height: ((controlData as any).type === SignatureControlType.Radio) ? (control as any).props.height : null,
                width: ((controlData as any).type === SignatureControlType.Radio) ? (control as any).props.width : null,
                items: (controlData as any).items,
                controlName: ((controlData as any).type === SignatureControlType.Radio || (controlData as any).type === SignatureControlType.CheckBox) ? (controlData as any).props?.controlName ? (controlData as any).props?.controlName : '' : ''
            };

            signatureData.signatureControls.push(signatureControl);

        });

        return signatureData;
    }


}