import React from 'react';
import { useState, useEffect } from 'react';
import * as CompanyStore from '../../../store/Company/CompanyStore';
import { IMenuSection } from '@sssuite-component-ui/ss-suite-layout/dist/SideMenu/SideMenu';
import { headerConfig, initialHeaderConfig, sideMenuConfig } from './SSSuiteLayoutConfig';
import * as SSSuiteStore  from 'src/SignatureFlow/store/common/SSSuiteStore';
import history from '../../../../routes/history';
import SSSuitLayout, { ProductType } from "@sssuite-component-ui/ss-suite-layout";
import { ISectionData } from '@sssuite-component-ui/ss-suite-layout/dist/Header/NavigationWidget/NavigationWidget';
import { IUserProfileData } from 'src/SignatureFlow/store/userManagement/UserProfileStore';
import { ISFUserData } from 'src/SignatureFlow/store/SFUserStore';
import { IAuthState } from 'src/SignatureFlow/store/auth/reducer';
import { PostAuthStoreState } from 'src/SignatureFlow/store/PostAuth/PostAuthStore';
import { ICompanySettings, ISuiteMFASetting } from '../../../../Core/ViewModels/Company/CompanySettingsViewModel';
import * as OneTimePasswordStore from '../../../store/common/OneTimePasswordStore';
import * as UserLoginHistory from '../../../store/userManagement/UserLoginHistoryStore';
import * as MyDownloadsStore from '../../../store/MyDownloadsStore';
import * as UserProfileStore from '../../../store/userManagement/UserProfileStore';
import * as UserSettingsStore from '../../../store/userManagement/UserSettingStore';
import { actionCreators as sfUserActionCreators } from '../../../store/SFUserStore';
import * as SignatureFlowSettingsStore from '../../../store/SignatureFlowSettingsStore';
import { isAllRouteDisabled } from '../../helper/HelperFunctions';
import MyLoginHistory from '../profile/MyLoginHistory';
import Logout from '../profile/Logout';
import { MySettings } from '../profile/MySettings';
import MyAccount from '../profile/MyAccount';
import * as SSSuiteCompanySettingsStore from '../../../store/Company/SSSuiteCompanySettingsStore'
import * as SignatureFlowReportCustomColumnStore from '../../../store/ReportsCommon/SignatureFlowReportCustomColumnStore';
import {IDownloadedZipFilesModel, MyDownloads} from '../profile/MyDownloads';
import * as OfficeLocationStore from '../../../store/MultiOffice/OfficeLocationStore';
import * as mfaSettingStore from '../../../store/Company/SuiteMFASettingStore';
import { ReportProblemModal } from '../../common/ReportProblemModal_FC';
import { ReportProblemTypes, IReportProblemDetails } from '../../common/ReportProblemModel';
import { CommonPaths } from '../../helper/UrlPaths';
import { useForethought } from '../../CustomHooks/Forethought';
export interface IHeaderProps {
    children?: React.ReactNode;    
}

export interface IHeaderModal {
    profile: boolean
    mySettings: boolean
    loginHistory: boolean
    myDownloads: boolean
    logOut: boolean
    support: boolean
}

type HeaderProps = IHeaderProps &
    {
        profile: IUserProfileData,
        sfUserState: ISFUserData,
        authState : IAuthState,
        PostAuthStoreState:PostAuthStoreState
        settings: UserSettingsStore.UserSettings,
        companyData: CompanyStore.ICompanyData,
        SSSuiteCompanySettings: SSSuiteCompanySettingsStore.ISSSuiteCompanySettingsState,
        userLoginHistory: UserLoginHistory.IUserLoginHistoryState,
        myDownloads: MyDownloadsStore.IMyDownloadsState,
        oneTimePasswordData: OneTimePasswordStore.IOneTimePasswordState,
        rbacStore: SSSuiteStore.ISSSuiteState,
        reportCustomColumns: SignatureFlowReportCustomColumnStore.ISignatureFlowReportCustomColumnState,
        officeLocationState: OfficeLocationStore.IOfficeLocationState,
        userOfficeLocationState: OfficeLocationStore.IUserOfficeLocationState,
        mfaSettings: ISuiteMFASetting,
    }
    & typeof SSSuiteStore.actionCreators
    & typeof UserProfileStore.actionCreators
    & typeof UserSettingsStore.actionCreators
    & typeof CompanyStore.actionCreators
    & typeof SignatureFlowReportCustomColumnStore.actionCreators
    & typeof SSSuiteCompanySettingsStore.actionCreators
    & typeof UserLoginHistory.actionCreators
    & typeof OneTimePasswordStore.actionCreators
    & typeof sfUserActionCreators
    & typeof MyDownloadsStore.actionCreators
    & typeof OfficeLocationStore.actionCreators
    & typeof SignatureFlowSettingsStore.actionCreators
    & typeof mfaSettingStore.actionCreators
    
    
const SSSuiteLayoutWrapper :React.FC <HeaderProps> = (props)=> {
    const initialModatState : IHeaderModal ={
        profile: false,
        mySettings: false,
        loginHistory:false,
        myDownloads: false,
        logOut: false,
        support: false
    }
    const [headerData,setHeaderData] = useState(initialHeaderConfig);
    const [suiteUrl,setsuiteUrl] = useState('');
    const [widgetDropDownData, setWidgetDropDownData] = useState(initialHeaderConfig.widgetData.dropDownData)
    const [sideMenuData,setsideMenuData] = useState(undefined);
    const [showModal, setShowModal] = useState<IHeaderModal>(initialModatState);   
    const [showLoader, setLoader] = useState(true);
    const [forethoughtClick] = useForethought();
    
    
    useEffect(()=>{
        props.getSidemenuData(setSideMenuData);
        props.getSuiteUrl(setSuiteUrl);
        props.getResources(true);
        props.requestAllSFUsers();
        props.requestUserProfile();
        props.requestCompanyLogo(true);
        props.requestUserSettings(true);
        props.requestSignatureUserSettings(true);
        props.requestCompanyProfile();
        props.requestUserLoginHistory();
        props.requestMFAOTPLength();
        props.requestCompanySettings();
        props.getPasswordPolicySettings();
        props.getNavigationMenu(ProductType.SignaturesOneOff,setNavigationWidgetMenuData);
        props.requestSignatureArchiveReportCustomColumn();
        props.requestSignatureFlowReportCustomColumn();
        props.getOfficeLocationDropdown();
        props.fetchUserOfficeLocation();
        props.requestMFASetting();
    },[])
    
    var setSideMenuData = (data :IMenuSection[]) =>{
        isAllRouteDisabled(data) ? setsideMenuData(undefined) : setsideMenuData(sideMenuConfig(data)); 
    }

    const setNavigationWidgetMenuData = (navigationWidgetMenuResponse: ISectionData[]) => {
        setWidgetDropDownData(navigationWidgetMenuResponse);
    }

    var setSuiteUrl = (url:string) => {
        setsuiteUrl(url);
    };

    let downloadMyDownloads = (bulkDownloadsId: number, documentGuid: string, filePath: string, fileName: string, storageAccountId: number)=>{
        setLoader(true);
        props.downloadMyDownloads(bulkDownloadsId, documentGuid, filePath, fileName, storageAccountId, ()=>{setLoader(false)});
    };

    let downloadAllMyDownloads = (bulkdownloads: IDownloadedZipFilesModel[])=>{
        setLoader(true);
        props.downloadAllMyDownloads(bulkdownloads, ()=>{setLoader(false)});
    };

    let fetchMyDownloads = (pageNo: number)=>{
        setLoader(true);
        props.fetchMyDownloads(pageNo, 20, ()=>{setLoader(false)});
    }

    let deleteMyDownloads = (bulkDownloadsId: number, documentGuid: string, filePath: string, storageAccountId: number)=>{
        setLoader(true);
        props.deleteMyDownloads(bulkDownloadsId, documentGuid, filePath, storageAccountId, ()=>{setLoader(false); fetchMyDownloads(1)});
    }

    let deleteAllMyDownloads = ()=>{
        setLoader(true);
        props.deleteAllMyDownloads(()=>{setLoader(false); fetchMyDownloads(1)});
    }

    const onReportProblemSave = (id: number, problemDetails: IReportProblemDetails, callback?: () => void) => {
        props.reportTaxDocumentProblem(problemDetails, CommonPaths.ReportedProblem, callback);
        setShowModal({ ...showModal, support: false });
    }

    useEffect(()=>{
        setHeaderData(headerConfig(initialModatState,setShowModal, widgetDropDownData, suiteUrl, history.push, forethoughtClick));
    },[suiteUrl,widgetDropDownData,props.profile.user,props.companyData]);
    
        return (<div>
            <SSSuitLayout headerProps={headerData} sideMenuProps={sideMenuData}>
                {props.children}
            </SSSuitLayout>
            
            <MyAccount
                    showState={showModal.profile}
                    onHide={() => setShowModal({ ...showModal, profile: false })}
                    profile={props.profile.user}
                    saveUserProfile={props.saveMyAccount}
                    updatePassword={props.updatePassword}
                    companySettings={props.companyData.companySettings as ICompanySettings}
                    saveOneTimePassword={props.saveOneTimePassword}
                    otpData={props.oneTimePasswordData.otpState}
                    validateOTP={props.validateOTP}
                    verifyLoading={props.oneTimePasswordData.isVerifyLoading}
                    passwordPolicySettings={props.SSSuiteCompanySettings.passwordPolicySettings}
                    mfaSettings={props.mfaSettings}
            />
            
            <MySettings
                users={props.sfUserState.users}
                onHide={() => setShowModal({ ...showModal, mySettings: false })}
                showState={showModal.mySettings}
                mySettings={props.settings}
                updateSignatureSettings={props.updateUserSettings}
                updateSignatureSettingsState={props.updateSignatureSettingsState}
            />
            
            <MyDownloads 
                showState={showModal.myDownloads}
                onHide={() => setShowModal({ ...showModal, myDownloads: false })}
                myDownloads={props.myDownloads}
                fetchMyDownloads={fetchMyDownloads}
                downloadMyDownloads={downloadMyDownloads}
                downloadAllMyDownloads={downloadAllMyDownloads}
                deleteMyDownloads={deleteMyDownloads}
                deleteAllMyDownloads={deleteAllMyDownloads}
                showLoader = {showLoader}
            />

            <Logout showState={showModal.logOut} onHide={() => setShowModal({ ...showModal, logOut: false })} />

            <MyLoginHistory 
                showState ={showModal.loginHistory}
                onHide={() => setShowModal({ ...showModal, loginHistory: false })}
                requestUserLoginHistory={props.requestUserLoginHistory}
                userLoginHistory={props.userLoginHistory}
                userSettings={props.settings}
                saveSettings={props.saveUserSettings}
            />

            <ReportProblemModal
                onCancel={() => setShowModal({ ...showModal, support: false })}
                onReportProblemSave={onReportProblemSave}
                show={showModal.support}
                loggedInCPA={props.profile.user}
                companyName={props.companyData.companyProfile.companyInfo.companyName}
                reportProblemType={ReportProblemTypes.SignatureSpecific}
                hideStatus={true}
                hideSentBy={true}
                fromHeader={true}
            />

        </div>)
}        

export default SSSuiteLayoutWrapper;
