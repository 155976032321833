import * as React from 'react';
import { BookmarksPane } from '../components/common/BookmarkPane';
import { ConditionalControlConstant, EsignHelpTextConstant, GetControlColorCode, SignatureControlConstants } from './helper/Constants';
import { ClientType, IClient, ISigningInfo, SenderSignOption } from '../models/SigningInfo';
import {
    ISigner, SignatureControlPanel, InputType,
    SFPdfPageSignatureControls
} from './SignatureControlPanel';
import {
    ISignatureData,
    ISignatureControl, SignatureControlRole, SignatureControlType, SignatureGroupType,
    initialSignatureControl,
    ISignatureControlsHistory,
    ControlHistoryType,
    ChoosableControlData,
    IConditionalControlRule
} from './../models/SignatureData';
import { IDocumentData, IDocumentInfo } from './../models/DocumentInfo';
import { IPdfDocumentFacade } from '../../Core/Utilities/PdfDocumentFacade';
import * as Helper from '../components/helper/HelperFunctions';
import SignerTooltipPopup from './common/SignerTooltipPopup';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { getControlText, getControlIcon } from './../components/helper/SignatureHelper';
import { Guid } from '../../Core/Utilities/Guid';
import SignatureControl from '../components/common/SignatureControl';

import { PdfViewer } from 'awesome-pdf-viewer';
import { ControlBase } from 'awesome-pdf-viewer/dist/Controls/ControlBase';
import { PlaceholderControl } from 'awesome-pdf-viewer/dist/Controls/PlaceholderControl/PlaceholderControl';
import ControlLayer from 'awesome-pdf-viewer/dist/Layers/ControlLayer';
import { Header } from 'awesome-pdf-viewer/dist/layout/Header';
import { PageProperties } from 'awesome-pdf-viewer/dist/layout/LayoutBase';
import { LeftPanel } from 'awesome-pdf-viewer/dist/layout/LeftPanel';
import { RightPanel } from 'awesome-pdf-viewer/dist/layout/RightPanel';
import ViewPanel from 'awesome-pdf-viewer/dist/layout/ViewPanel';
import { CustomOptions } from 'awesome-pdf-viewer/dist/toolbar/CustomOptions';
import { Pagination } from 'awesome-pdf-viewer/dist/toolbar/Pagination';
import { Toolbar } from 'awesome-pdf-viewer/dist/toolbar/Toolbar';
import Zoom from 'awesome-pdf-viewer/dist/toolbar/Zoom';
import { PageMode, PdfSource} from 'awesome-pdf-viewer/dist/viewer/ViewerBase';
import {PageSize} from "awesome-pdf-viewer/dist/layout/LayoutBase";
import { CONTROL_ID_PREFIX, CONTROL_KEY_PREFIX, StepsHelper } from './helper/steps/StepsHelper';
import { loadPdf, setDocumentRadioControlNumber } from '../components/helper/HelperFunctions';
import { logger } from '../../routes/LoggedIn';
import { validateError } from './helper/Validations';
import ConditionalControlPanel, { IConditionalControlPanelState, initialConditionalControlPanelState } from './ConditionalControl/ConditionalControlPanel';
import { ConditionControlOptionsTypes } from './ConditionalControl/ConditionalControlOptions';
import DeleteConditionalControlPopup from './ConditionalControl/DeleteConditionalControlPopup';
import ConditionalControlPanelButtons from './ConditionalControl/ConditionalControlPanelButtons';
import { VenusNotifier } from './helper/VenusNotifier';
const Draggabilly: any = require('draggabilly/draggabilly');

export const PdfProperties = {
    PageHeight: 792,
    PageWidth: 612,
    DefaultScale: 1
}
export const INVALID_PAGE = -100;

export const signersControlBoxItems = [
    {
        caption: 'Signature',
        icon: 'fas fa-signature',
        controlType: SignatureControlType.Signature,
        helpText: EsignHelpTextConstant.ClientSignature
    },
    {
        caption: 'Initials',
        icon: 'fas fa-font',
        controlType: SignatureControlType.Initial,
        helpText: EsignHelpTextConstant.Initial
    },
    {
        caption: 'Date Signed',
        icon: 'fas fa-calendar-alt',
        controlType: SignatureControlType.Date,
        helpText: EsignHelpTextConstant.SignatureDate
    },
    {
        caption: 'Print Name',
        icon: 'fas fa-address-card',
        controlType: SignatureControlType.PrintName,
        helpText: EsignHelpTextConstant.PrintName
    },
    {
        caption: 'Company Field',
        icon: 'fas fa-building',
        controlType: SignatureControlType.Company,
        helpText: EsignHelpTextConstant.Company
    },
    {
        caption: 'Title',
        icon: 'fas fa-address-card',
        controlType: SignatureControlType.Title,
        helpText: EsignHelpTextConstant.Title
    },
    {
        caption: 'Text',
        icon: 'fas fa-text-width',
        controlType: SignatureControlType.Text,
        helpText: EsignHelpTextConstant.Text
    },
    {
        caption: 'Check Box',
        icon: 'fas fa-check-square',
        controlType: SignatureControlType.CheckBox,
        helpText: EsignHelpTextConstant.CheckBox
    },
    {
       caption: 'Radio Button',
       icon: 'fas fa-check-circle',
       controlType: SignatureControlType.Radio,
       helpText: EsignHelpTextConstant.Radio
    }
];
const senderControlBoxItems = [{
    caption: 'Signature',
    icon: 'fas fa-signature',
    controlType: SignatureControlType.Signature,
    helpText: EsignHelpTextConstant.ClientSignature
}];
export interface SignatureProps {
    signingInfo: ISigningInfo;
    currentStep: number;
    documentData: IDocumentData[];
    loadRecognizedDataForUnloadedDocuments: (documents: IDocumentData[]) => void;
    updateSFData: (signingInfo: ISigningInfo) => void;
    updateDocumentData: (documentData: IDocumentData) => void;
    loadRecognizedDataForSelectedDocument: (documentGuid: string) => void;
    loadRecognizedDataForSelectedDocumentIfRecipientsUpdated: (documentGuid: string) => void;
    isRecipientsUpdated: boolean;
    isNavigatedFromDraft: boolean;
}
export interface PdfDocumentState {
    currentPage: number;
    zoomEnabled: boolean;
}
export interface SignaturePropsState extends PdfDocumentState {
    signers: ISigner[],
    selectedSigner: ISigner,
    sender: ISigner,
    selectedESignDocument: IDocumentData,
    isBusy: boolean,
    focusedGroup: SignatureGroupType,
    toolTipControl: ISignatureControl,
    showToolTipPopup: boolean,
    undoDisable: boolean,
    scale: number,
    pdfData: any,
    conditionalControlState: IConditionalControlPanelState
    isDraftDocumentsControlsUpdated: boolean;
}
export class SFStep3 extends React.Component<SignatureProps, SignaturePropsState>{
    private _viewPanel: any;
    private _controlLayer: any;
    private _controlList: any[] = [];
    private _toolbar: any;
    private _conditionalControlPanel: any;
    //private _bookmarkPanel: any;
    private _controlDisplayPanel: any;
    constructor(props: SignatureProps) {
        super(props);
        this._conditionalControlPanel = React.createRef();  
        this.state = {
            signers: [],
            selectedSigner: {
                value: "",
                label: "",
                signatureRole: SignatureControlRole.None
            },
            sender: {
                value: "",
                label: "",
                signatureRole: SignatureControlRole.SFSender
            },
            currentPage: INVALID_PAGE,
            selectedESignDocument: {} as IDocumentData,
            isBusy: false,
            zoomEnabled: false,
            focusedGroup: SignatureGroupType.None,
            toolTipControl: initialSignatureControl,
            showToolTipPopup: false,
            scale: PdfProperties.DefaultScale,
            pdfData: '',
            undoDisable: true,
            conditionalControlState: initialConditionalControlPanelState,
            isDraftDocumentsControlsUpdated: false
        }

    }

    UNSAFE_componentWillMount() {
        //this.setState({ isBusy: true });
        // receipent signers
        var tempSFSigners: ISigner[] = [];
        for (let i = 0; i < this.props.signingInfo.recipients.length; i++) {
            if (this.props.signingInfo.recipients[i].clientType.toString() === ClientType.Signer.toString()) {
                tempSFSigners.push({
                    value: this.props.signingInfo.recipients[i].clientGuid.toString(),
                    label: this.props.signingInfo.recipients[i].emailAddress,
                    signatureRole: SignatureControlRole.SFReceipient
                });
            }
        }
        // sender
        var tempSender: ISigner = this.state.sender;
        tempSender.value = this.props.signingInfo.clientSender.clientGuid.toString();
        tempSender.label = this.props.signingInfo.sender.email.concat(' (You)');
        tempSender.signatureRole = SignatureControlRole.SFSender;


        if (this.props.signingInfo.senderSignOption === SenderSignOption.SIGNS_FIRST) {
            tempSFSigners.unshift(tempSender);
        }
        else if (this.props.signingInfo.senderSignOption === SenderSignOption.SIGNS_LAST) {
            tempSFSigners.push(tempSender);
        }

        let history: boolean = this.props.signingInfo.documentInfo[0]?.signatureData[0] ?
            (this.props.signingInfo.documentInfo[0]?.signatureData[0]?.signatureControlsHistory && this.props.signingInfo.documentInfo[0]?.signatureData[0]?.signatureControlsHistory?.length > 0) : false;

        this.setState({
            selectedSigner: tempSFSigners[0],
            signers: tempSFSigners,
            sender: tempSender,
            focusedGroup: this.props.signingInfo.senderSignOption === SenderSignOption.SIGNS_FIRST ? SignatureGroupType.Sender
                : SignatureGroupType.Receiver,
            selectedESignDocument: this.props.documentData[0],
            currentPage: 1,
            undoDisable: !history
        });
    }

    setReferences = () => {
        this._toolbar && this._toolbar.setViewerReference(this._viewPanel);
        this._viewPanel && this._viewPanel.setToolbarReference(this._toolbar);
        this._viewPanel && this._viewPanel.setControlsReference(this._controlList);
        this._viewPanel && this._viewPanel.setControlLayerReference(this._controlLayer);
        this._viewPanel && this._controlLayer.setViewerReference(this._viewPanel);
        this._controlLayer && this._controlLayer.setControlsReference(this._controlList);
        this._controlLayer && this._controlLayer.setcontrolDisplayPanelReference(this._controlDisplayPanel);
        this._controlDisplayPanel && this._controlDisplayPanel.setControlsReference(this._controlList);
        this._controlDisplayPanel && this._controlDisplayPanel.setViewerReference(this._viewPanel);
    }

    componentDidUpdate() {
        this.setReferences();
    }

    UNSAFE_componentWillReceiveProps(nextProps: SignatureProps) {
        if (this.state.selectedESignDocument &&
            nextProps.documentData.filter(x => x.documentGuid == this.state.selectedESignDocument.documentGuid)[0].sasUrl == "") {
            nextProps.loadRecognizedDataForSelectedDocument(this.state.selectedESignDocument.documentGuid);
        }
    }

    componentDidMount() {
        this.setReferences();
        if (this.props.isRecipientsUpdated || !this.state.selectedESignDocument.isPDFloaded) {
            this.props.loadRecognizedDataForSelectedDocumentIfRecipientsUpdated(this.state.selectedESignDocument.documentGuid);
        }
        if (this.state.selectedESignDocument && !this.state.selectedESignDocument.isPDFloaded) {
            var selected: IDocumentData = { ...this.state.selectedESignDocument }
            if (this.props.documentData[0].sasUrl && this.props.documentData[0].sasUrl != "") {
                loadPdf(this.props.documentData[0].sasUrl).then((data: IPdfDocumentFacade) => {
                    selected.pdf = data;
                    selected.isPDFloaded = true;
                    this.props.updateDocumentData(selected);
                    this.setState({ selectedESignDocument: selected, isBusy: false });
                }).catch (err => {
                    logger.trackError(`Error occurred at loadPDF - SFtep3:  ${validateError(err)}`);
                });
            }
        }

        let pdf_viewer_element = document.getElementById('pdfViewer');
        if (pdf_viewer_element) {
            pdf_viewer_element.addEventListener('click', () => { 
            if(this.state.conditionalControlState.selectedPanelOption == ConditionControlOptionsTypes.None && this.state.conditionalControlState.selectedControl?.controlGuid) 
                {
                    this.resetConditionalControlState();
                }
            });
        }

        if(this.props.isNavigatedFromDraft && !this.state.isDraftDocumentsControlsUpdated){
            this.setDraftDocumentsControls();
        }
    }

    private setDraftDocumentsControls = () => {
        const { signingInfo } = this.props;

        const temp_signingInfo: ISigningInfo = { ...signingInfo };

        if(temp_signingInfo.documentInfo && temp_signingInfo.documentInfo.length > 0){
            temp_signingInfo.documentInfo.map((doc: IDocumentInfo) => {
                setDocumentRadioControlNumber(doc.signatureData);
            });
            this.props.updateSFData(temp_signingInfo);
            this.setState({ isDraftDocumentsControlsUpdated: true });
        }
    }

    signerChange = (selectedOption: any) => {
        if (selectedOption) {
            const selectedValue = this.state.signers.find(s => s.value == selectedOption);
            if (selectedValue) {
                const signatureControlRole = selectedValue.signatureRole ? selectedValue.signatureRole : SignatureControlRole.None;
                const focusedGroup = this.getFocusedGroup(signatureControlRole);

                this.setState({
                    selectedSigner: selectedValue,
                    focusedGroup: focusedGroup
                });
            }
            else {
                this.setState({
                    selectedSigner: {
                        value: selectedOption.value,
                        label: selectedOption.label,
                        signatureRole: SignatureControlRole.None,
                        disabled: selectedOption.disabled
                    },
                    focusedGroup: SignatureGroupType.None
                });
            }

        }

        //HIDE CONDITIONAL CONTROL OPTIONS
        this.resetConditionalControlState();
       
    }

    dragStartSignatureControl = (event: any, controlType: SignatureControlType, signatureRole: SignatureControlRole, signer: string) => {
        this.registerDropEvents();
        event.dataTransfer.setData('controlType', controlType.toString());
        event.dataTransfer.setData('signatureRole', signatureRole.toString());
        event.dataTransfer.setData('signer', signer);

        var element = document.createElement("div");
        element.id = "dragging-element";
        element.style.position = "absolute";
        element.style.top = "-1000px";

        let controlText = getControlText(controlType);
        let icon = getControlIcon(controlType);
        element.innerHTML = '<p style="border-style: solid; border-width: 1px;"><i class="'
            + icon + '" style="padding: 5px; background: rgb(240, 240, 241);"></i><label style="font-size: '
            + (12 * this.state.scale).toString() + 'px; font-weight: normal; padding: 0px 5px 0px 5px;">'
            + controlText + '</label></p>';

        document.body.appendChild(element);
        event.dataTransfer.setDragImage(element, 0, 0);
    }

    private dragEndSignatureControl = (event: any) => {
        let element = document.getElementById("dragging-element");
        if (element) {
            element.remove();
        }
    }

    registerDropEvents = () => {
        const _this = this;
        const elements = document.getElementsByClassName("page");
        for (var i = 0; i <= elements.length - 1; i++) {
            const tmpElement: any = elements[i];
            tmpElement.ondrop = function (ev: any) {
                ev.preventDefault();
                const tmpPageElement = ev.target.offsetParent;
                if (tmpPageElement.attributes["data-page-number"]) {
                    _this.dropSignatureControl(ev);
                }
            };
            tmpElement.ondragover = function (ev: any) {
                ev.preventDefault();
            };
        }
    }

    private dropSignatureControl = (event: any) => {
        let controlType = event.dataTransfer.getData('controlType');
        let signatureRole = event.dataTransfer.getData('signatureRole');
        const signer = event.dataTransfer.getData('signer');
        if (controlType && signatureRole) {
            controlType = parseInt(controlType);
            signatureRole = parseInt(signatureRole);
            if (!Number.isNaN(controlType) && !Number.isNaN(signatureRole)) {
                this.addSignatureControl(controlType, signatureRole, signer, event.offsetX, event.offsetY);
            }
        }
    }

    private addControlItems = (tooltip: string, isupdate: boolean, control?: ISignatureControl): ChoosableControlData[] => {
        let items: ChoosableControlData[] = [];
        let controlLeft = 20;
        let tooltipNumber = Number(tooltip);
        
            for (let i = 1; i <= tooltipNumber; i++) {
                items.push({
                    id: isupdate ? control.items[i-1].id : Guid.newGuid().toString(),
                    controlNumber: isupdate && control.items[i-1]?.controlNumber ? control.items[i-1]?.controlNumber : i,
                    name: Guid.newGuid().toString(),
                    top: isupdate ? control.items[i-1].top: (controlLeft * i),
                    left: isupdate ? control.items[i-1].left : 35,
                    width: 0,
                    height: 0,
                    value: { checked: false, value: '' }
                });
            }
        return items;
    }

    private getControlNumber = (type: SignatureControlType, signer: string): number => {
        const allRelatedSignerControls = this.getSignatureControls().signatureControls.filter(x => x.signer == signer);
        const allReleatedTypeControls = allRelatedSignerControls.filter(x => x.type == type);
        if(allReleatedTypeControls.length > 0){
            return allReleatedTypeControls[allReleatedTypeControls.length - 1].controlName ? Number(allReleatedTypeControls[allReleatedTypeControls.length - 1].controlName.split(" ")[1]) : 0;
        }
        return 0;
    }
    
    private addSignatureControl = (type: SignatureControlType, role: SignatureControlRole, signer: string, clientX: number, clientY: number, isHist?: boolean, histGuid?: string, isControlDuplicate?: boolean, duplicateControl?: ISignatureControl) => {
        const { currentPage } = this.state;
        const heightIncrement = 20;
        this._toolbar && this._toolbar.handleZoomChange(1);
        let control: ISignatureControl = {} as ISignatureControl;
        control.controlGuid = Guid.newGuid().toString();
        control.signer = signer;

        const pageSize: PageSize = this.getCurrentPageSize();

        if(isControlDuplicate && duplicateControl) {
            control.top = duplicateControl.top - 5;
            control.left = duplicateControl.left + 5;
            control.required = duplicateControl.required;
        }
        else{
            control.top = ControlBase.getBackendControlTopPosition(pageSize.height, clientY);
            control.left = ControlBase.getBackendControlLeftPosition(clientX);
            control.required = true;
        }

        control.type = type;
        control.role = role;

        if (control.type === SignatureControlType.Radio) {
            control.tooltip = isControlDuplicate ? (duplicateControl.items.length).toString()  : SignatureControlConstants.DefaultNumberOfRadioButtons;
            control.height = (SignatureControlConstants.ControlHeight_RadioButton + Number(control.tooltip) * heightIncrement) * this.state.scale;
            control.width = SignatureControlConstants.ControlWidth_RadioButton  * this.state.scale;
            control.items = this.addControlItems(control.tooltip,false);
        }


        let temp_sfData: ISigningInfo = Object.assign({}, this.props.signingInfo);
        const documentInfoIndex = temp_sfData.documentInfo.findIndex(x => x.documentGuid === this.state.selectedESignDocument.documentGuid);

        if (documentInfoIndex != Helper.NO_INDEX) {
            let documentInfo = temp_sfData.documentInfo[documentInfoIndex]
            let signatureControls: ISignatureControl[];
            let signatureControlsHistory: ISignatureControlsHistory[];
            let conditionalControlsRules: IConditionalControlRule[];
            const signatureDataIndex = documentInfo.signatureData.findIndex(x => x.pageNo === this.state.currentPage);

            // this we are checking if there is no signature data in the document then we will create a new signature data object
            if (!documentInfo.signatureData || !Array.isArray(documentInfo.signatureData)) {
                signatureControls = [];
                signatureControlsHistory = [];
                conditionalControlsRules = [];
                let signatureData = { pageNo: this.state.currentPage, signatureControls: signatureControls, signatureControlsHistory: signatureControlsHistory } as ISignatureData;
                documentInfo.signatureData = [signatureData];
                documentInfo.conditionalControlsRules = conditionalControlsRules;
            }
            else {
                // this we are checking if there is no signature data for the current page then we will create a new signature data object
                if (signatureDataIndex == Helper.NO_INDEX) {
                    signatureControls = [];
                    signatureControlsHistory = [];
                    let signatureData = { pageNo: this.state.currentPage, signatureControls: signatureControls, signatureControlsHistory: signatureControlsHistory } as ISignatureData;
                    documentInfo.signatureData.push(signatureData);
                }
                else {
                    // this we are checking if there is signature data for the current page then we will get the signature controls and signature controls history
                    signatureControls = documentInfo.signatureData[signatureDataIndex].signatureControls;
                    signatureControlsHistory = documentInfo.signatureData[signatureDataIndex].signatureControlsHistory;
                }
            }

            //add Signature history object if it does not exist
            if (!signatureControlsHistory) {
                signatureControlsHistory = [];
                documentInfo.signatureData[signatureDataIndex].signatureControlsHistory = signatureControlsHistory;
            }

            //check if data is from history table if yes then add its possition
            if (!isHist) {
                signatureControlsHistory.push({ signatureControl: control, controlHistoryType: ControlHistoryType.Added });
                this.state.undoDisable ? this.setState({ undoDisable: false }) : "";

            }
            else {
                control.controlGuid = histGuid ? histGuid : control.controlGuid;
                if(isControlDuplicate && duplicateControl) {
                    control.top = duplicateControl.top - 5;
                    control.left = duplicateControl.left + 5;
                }
                else{
                    control.left = clientX;
                    control.top = clientY;
                }
            }

            // this is for giving Name or number to the Radio button and Checkbox to show in add Rules Section
            if (control.type === SignatureControlType.Radio || control.type === SignatureControlType.CheckBox) {
                if(!isHist){
                    const getControlNumber = this.getControlNumber(control.type, signer);
                    control.controlName = control.type == SignatureControlType.Radio ? "RadioButton " + (getControlNumber + 1) : "Checkbox " + (getControlNumber + 1);
                }
                else{
                    control.controlName = control.type == SignatureControlType.Radio ? "RadioButton " + (signatureControls.length + 1) : "Checkbox " + (signatureControls.length + 1);
                }
            }

            signatureControls.push(control);


            this._toolbar.forceUpdate();

            this.props.updateSFData(temp_sfData);
            
            if ([SignatureControlType.Text, SignatureControlType.CheckBox, SignatureControlType.Radio].includes(control.type)) {
                this.onConditionalControlOptionsClick(ConditionControlOptionsTypes.None,control)
            }
            else{
                this.state.conditionalControlState.selectedControl.controlGuid ? this.resetConditionalControlState() : "";
            }
            
            if ([SignatureControlType.Text, SignatureControlType.CheckBox, SignatureControlType.Radio, SignatureControlType.Signature].includes(control.type)) {
                this.setState({
                    toolTipControl: control,
                    showToolTipPopup: true
                });
            }

        }

    }

    private checkShouldRenderRecipientControl = (clients : IClient[], signerGuid : string) => {
        const client = clients.find(i=> i.clientGuid.toString() == signerGuid);
        return client?.clientType != ClientType.CC;
    }

    onControlLoad = (control: any, pageElement: any) => {
        if (control.draggable === false) {
            this.applyControlStyles(control);
            this.makeDraggableControl(control, pageElement, this.handleControlDragEnd);
        }
    }

    private makeDraggableControl = (control: any, pageElement: any, dragEndCallBack: any) => {
        const draggie = new Draggabilly('#' + control.id, {
            containment: pageElement,
            handle: '.draggable-btn-control'
        });

        draggie.off('dragEnd', '#' + control.id);
        draggie.on('dragEnd', function (event: any, pointer: any) {
            event = event;
            pointer = pointer;
            const controlElement = document.getElementById(control.id);
            controlElement &&
                dragEndCallBack(control.id.replace(SignatureControlConstants.ControlIdPrefix, ''),
                    controlElement.offsetTop, controlElement.offsetLeft)
        });
        this.setDraggableControlItems(this.handleControlItemsDragEnd);
    }

    setDraggableControlItems = (dragEndCallBack: any, handle?: string): any => {

        const elements: any = document.querySelectorAll('.choosable-control-item');
        for (let i = 0; i <= elements.length - 1; i++) {

            const element: any = elements[i];
            const draggable = element.attributes["data-draggable"]?.value;

            if (!draggable) {
                element.setAttribute("data-draggable", "true");
                const draggie = new Draggabilly(element, {
                    containment: element.parentElement,
                    handle: handle
                });

                draggie.off('dragEnd', element);
                draggie.on('dragEnd', function (event: any, pointer: any) {
                    event = event;
                    pointer = pointer;

                    if (element) {
                        const id: string = element.attributes["data-id"].value;
                        const controlGuid = element.attributes["data-controlguid"].value;

                        dragEndCallBack(id,
                            controlGuid.replace(SignatureControlConstants.ControlIdPrefix, ''),
                            element.offsetTop, element.offsetLeft)
                    }
                });
            }
        };
    }

    private handleControlItemsDragEnd = (id: string, controlGuid: string, top: number, left: number) => {
        let temp_sfData: ISigningInfo = Object.assign({}, this.props.signingInfo);
        const documentInfoIndex = temp_sfData.documentInfo.findIndex(x => x.documentGuid === this.state.selectedESignDocument.documentGuid);

        if(documentInfoIndex != Helper.NO_INDEX) {
            let documentInfo = temp_sfData.documentInfo[documentInfoIndex]
            const signatureDataIndex = documentInfo.signatureData.findIndex(x => x.pageNo === this.state.currentPage);
            if(signatureDataIndex != Helper.NO_INDEX) {
                let signatureControls = documentInfo.signatureData[signatureDataIndex].signatureControls;
                const index = signatureControls.findIndex(x => x.controlGuid === controlGuid);
                if (index != Helper.NO_INDEX) {
                    let control = signatureControls[index];
                    if (control) {
                        let itemIndex = control.items.findIndex(x => x.name == id);
                        if (itemIndex != Helper.NO_INDEX) {
                            control.items[itemIndex].left = left;
                            control.items[itemIndex].top = top;
                            this.onUpdateControlData(control);
                        }
                    }
                }
            }
        }
        
    }

    applyControlStyles = (control: any) => {
        const controlElement = document.getElementById(control.id);
        if (controlElement) {
            controlElement.removeAttribute('class');
            controlElement.classList.add('choosable-control-group');
            const selectedSignerStyle = controlElement.children[0]?.getAttribute('data-signerstyle');
            controlElement.classList.add(selectedSignerStyle ? selectedSignerStyle : 'choosable-signature-control-border');
        } 
    }

    applyCheckBoxControlStyles = (control: any) => {
        const controlElement : any = document.getElementById(control.id);
        if (controlElement?.children?.key == this.state.conditionalControlState.selectedControl.controlGuid) {
            controlElement.style.overflow = 'visible';
        }
        else{
            controlElement.style.overflow = 'hidden';
        }
    }

    private handleControlDragEnd = (controlGuid: string, top: number, left: number) => {

        let temp_sfData: ISigningInfo = Object.assign({}, this.props.signingInfo);
        const documentInfoIndex = temp_sfData.documentInfo.findIndex(x => x.documentGuid === this.state.selectedESignDocument.documentGuid);

        if(documentInfoIndex != Helper.NO_INDEX) {
            let documentInfo = temp_sfData.documentInfo[documentInfoIndex]
            const signatureDataIndex = documentInfo.signatureData.findIndex(x => x.pageNo === this.state.currentPage);
            if(signatureDataIndex != Helper.NO_INDEX) {
                let signatureControls = documentInfo.signatureData[signatureDataIndex].signatureControls;
                const index = signatureControls.findIndex(x => x.controlGuid === controlGuid);
                if(index != Helper.NO_INDEX) {
                    const controltop = top / this.state.scale;
                    const controlLeft = left / this.state.scale;
                    const pageSize: PageSize = this.getCurrentPageSize();
                    signatureControls[index].left = ControlBase.getBackendControlLeftPosition(controlLeft);
                    signatureControls[index].top = ControlBase.getBackendControlTopPosition(pageSize.height, controltop);
                    let control = signatureControls[index];
                    this.onUpdateControlData(control);
                }
            }
        }
    }

    private onUpdateControlData = (control: ISignatureControl) => {

        let temp_sfData: ISigningInfo = this.props.signingInfo;
        const documentInfoIndex = temp_sfData.documentInfo.findIndex(x => x.documentGuid === this.state.selectedESignDocument.documentGuid);

        if(documentInfoIndex != Helper.NO_INDEX) {
            let documentInfo = temp_sfData.documentInfo[documentInfoIndex]
            const signatureDataIndex = documentInfo.signatureData.findIndex(x => x.pageNo === this.state.currentPage);
            if(signatureDataIndex != Helper.NO_INDEX) {
                let draggedControl = documentInfo.signatureData[signatureDataIndex].signatureControls.filter(c => c.controlGuid == control.controlGuid)[0];
                draggedControl.left = control.left;
                draggedControl.top = control.top;
            }
        }
    }

    public handleResize = (controlGuid: string, height: number, width: number) => {
        let temp_sfData: ISigningInfo = this.props.signingInfo;
        const documentInfoIndex = temp_sfData.documentInfo.findIndex(x => x.documentGuid === this.state.selectedESignDocument.documentGuid);

        if(documentInfoIndex != Helper.NO_INDEX) {
            let documentInfo = temp_sfData.documentInfo[documentInfoIndex]
            const signatureDataIndex = documentInfo.signatureData.findIndex(x => x.pageNo === this.state.currentPage);
            if(signatureDataIndex != Helper.NO_INDEX) {
                let signatureControls = documentInfo.signatureData[signatureDataIndex].signatureControls;
                const index = signatureControls.findIndex(x => x.controlGuid === controlGuid);
                if (index != Helper.NO_INDEX) {
                    let control = signatureControls[index];
                    control.height = height;
                    control.width = width;
                    this.props.updateSFData(temp_sfData);
                }
            }
        }
    }
    getControlWidth = (control: ISignatureControl): number => {
        if (control && control.type == SignatureControlType.CheckBox) {
            return SignatureControlConstants.ControlWidth_Checkbox * this.state.scale;
        }
        return control.width ? control.width : SignatureControlConstants.ControlWidth * this.state.scale;
    }
    private renderSignatureControl = () => {
        const { currentPage, scale } = this.state;
        const controlCollection: any[] = [];
        this._controlList = [];
        const controls: any = this.getSignatureControls();

        if (this._viewPanel) {

            if (controls && controls.signatureControls.length > 0) {
                let tempControls: any = JSON.parse(JSON.stringify(controls));

                tempControls.signatureControls.map((control: ISignatureControl, index: any) => {
                    const renderRecipientControl = this.checkShouldRenderRecipientControl(this.props.signingInfo.recipients, control.signer);
                    const pageSize: PageSize = this.getCurrentPageSize();

                    const pdfViewerTop = ControlBase.getPdfViewerControlTopPosition(pageSize.height, control.top);
                    const pdfViewerLeft = ControlBase.getPdfViewerControlLeftPosition(control.left);

                    switch (control.role) {
                        case SignatureControlRole.SFSender:
                            controlCollection.push(<PlaceholderControl
                                height={control.height ? control.height : (SignatureControlConstants.ControlHeight * this.state.scale)}
                                id={CONTROL_ID_PREFIX + control.controlGuid}
                                key={CONTROL_KEY_PREFIX + control.controlGuid}
                                ref={(ref) => {
                                    this._controlList.push(ref)
                                }}
                                page={currentPage}
                                width={this.getControlWidth(control)}
                                top={pdfViewerTop}
                                left={pdfViewerLeft}
                                name={this.state.selectedESignDocument.fileName}
                                helptext={control.type === SignatureControlType.Radio ? '' : control.tooltip }
                                disabled={false}
                                isRequired={control.required}
                                draggable={control.type === SignatureControlType.Radio ? false : true}
                                data={{
                                    type: control.type,
                                    role: control.role,
                                    signer: control.signer,
                                    items: control.items,
                                    tooltip: control.tooltip,
                                    controlName: control?.controlName ? control.controlName : ''
                                }}

                                onDragStop={(top: number, left: number) => this.updateForm(top, left, control.controlGuid)}
                                onLoad={this.onControlLoad}>
                                <SignatureControl key={control.controlGuid}
                                    control={control}
                                    onControlRemove={this.onControlRemove}
                                    selectedSigner={tempControls ? tempControls.sender : {} as ISigner}
                                    style={this.getSignatureControlStyle(control)}
                                    focusedGroup={this.state.focusedGroup}
                                    onToolTipEdit={this.onToolTipEdit}
                                    updateConditionalControl={this.updateConditionalControl}
                                    onConditionalControlOptionsClick={this.onConditionalControlOptionsClick}
                                    conditionalControlState={this.state.conditionalControlState}
                                    slectControlForRule={this.slectControlForRule}
                                    signerName={this.getControlSignerName(control)}
                                    handleResize={this.handleResize}
                                    controlColor={this.getRecipentColor(control)}
                                    isEditRule= {this.showUpdateButton()}
                                />
                            </PlaceholderControl>);
                            break;
                        case SignatureControlRole.SFReceipient:
                            controlCollection.push(<PlaceholderControl
                                height={control.height ? control.height : (SignatureControlConstants.ControlHeight * this.state.scale)}
                                id={CONTROL_ID_PREFIX + control.controlGuid}
                                key={CONTROL_KEY_PREFIX + control.controlGuid}
                                ref={(ref) => {
                                    this._controlList.push(ref)
                                }}
                                page={currentPage}
                                width={this.getControlWidth(control)}
                                top={pdfViewerTop}
                                left={pdfViewerLeft}
                                name={this.state.selectedESignDocument.fileName}
                                helptext={control.type === SignatureControlType.Radio ? '' : control.tooltip }
                                disabled={false}
                                isRequired={control.required}
                                draggable={control.type === SignatureControlType.Radio ? false : true}
                                data={{
                                    type: control.type,
                                    role: control.role,
                                    signer: control.signer,
                                    items: control.items,
                                    tooltip: control.tooltip,
                                    controlName: control?.controlName ? control.controlName : ''
                                }}
                                onDragStop={(top: number, left: number) => this.updateForm(top, left, control.controlGuid)}
                                onLoad={this.onControlLoad}>
                                {renderRecipientControl ? <SignatureControl key={control.controlGuid}
                                    control={control}
                                    onControlRemove={this.onControlRemove}
                                    selectedSigner={tempControls ? tempControls.signer : {} as ISigner}
                                    style={this.getSignatureControlStyle(control)}
                                    focusedGroup={this.state.focusedGroup}
                                    onToolTipEdit={this.onToolTipEdit}
                                    updateConditionalControl={this.updateConditionalControl}
                                    onConditionalControlOptionsClick={this.onConditionalControlOptionsClick}
                                    conditionalControlState={this.state.conditionalControlState}
                                    signerName={this.getControlSignerName(control)}
                                    slectControlForRule={this.slectControlForRule}
                                    handleResize={this.handleResize}
                                    controlColor={this.getRecipentColor(control)}
                                    isEditRule = {this.showUpdateButton()}
                                /> : null}
                            </PlaceholderControl>);
                            break;
                    }
                });
            }

        }
        return controlCollection;
    }

    private getControlSignerName = (control: ISignatureControl): string => {
        const { signers } = this.state;
        let signerName = '';
        const signer = signers.findIndex(x => x.value == control.signer);

        if(signer != -1){
            signerName = signers[signer].label;
        }

        return signerName;
    }

    private getRecipentColor = (control: ISignatureControl): string => {
        const { signers } = this.state;
        let colorCode = '';
        const signer = signers.findIndex(x => x.value == control.signer);

        if(signer != -1){
            colorCode = GetControlColorCode(signer+1);
        }

        return colorCode;
    }

    private getSignatureControlStyle = (control: ISignatureControl): React.CSSProperties => {

        let controlHeight = 30;
        let fontSize = 16;
        let style: React.CSSProperties = {};
        const colorCode = this.getRecipentColor(control);

        if(control.type == SignatureControlType.Radio) {
            style.background = "linear-gradient(90deg, " + colorCode + " 5px, " + colorCode + "1A 4px)";
            style.border = "1px solid " + colorCode;
            style.height = "100%";
            style.width = "100%";
            style.borderRadius = "5px";

            return style;
        }

        style.height = (controlHeight * this.state.scale).toString() + "px";
        style.width = control.type == SignatureControlType.CheckBox ? (42 * this.state.scale).toString() + "px" : (150 * this.state.scale).toString() + "px";
        style.zIndex = 5;
        style.display = "block";
        style.fontSize = (fontSize * this.state.scale).toString() + "px";
        style.background = "linear-gradient(90deg, " + colorCode + " 5px, " + colorCode + "1A 4px)";
        style.border = "1px solid " + colorCode;
        
        return style;
    } 


    private updateForm = (controlTop?: number, controlLeft?: number, controlGuid?: string) => {

        const { signingInfo } = this.props;
        const { selectedESignDocument } = this.state;
        const pageSize: PageSize = this.getCurrentPageSize();


        const currentDocument = signingInfo.documentInfo.find(x => x.documentGuid == selectedESignDocument.documentGuid);
        let signatureData: ISignatureData | undefined = currentDocument?.signatureData.find(x => x.pageNo == this.state.currentPage);

        if (signatureData) {
            if (controlGuid && controlLeft != null && controlTop != null) {
                let controlCheck = this._controlList.filter(i => i?.props?.id.replace(CONTROL_ID_PREFIX, "") == controlGuid)[0];
                controlCheck.state.left = controlLeft;
                controlCheck.state.top = controlTop;
            }
            StepsHelper.updateFormControls(this._controlList, signatureData, pageSize);
        }
    }

    private getCurrentPageSize = (): PageSize => {
        const { currentPage } = this.state;
        const pageSize: PageSize = this._viewPanel.getPageSize(currentPage, 1);
        return pageSize;
    }


    onDocumentClick = (docuementKey: string) => {

        if(this.state.conditionalControlState.selectingFields) {
            VenusNotifier.Error(ConditionalControlConstant.selectingFieldsError,null);
            return;
        }


        const esignDocument = this.props.documentData.find(x => x.documentGuid === docuementKey) as IDocumentData;
        this.setState({
            selectedESignDocument: esignDocument,
            currentPage: 1
        }, () => {
            if (this.state.selectedESignDocument && !this.state.selectedESignDocument.isPDFloaded) {
                this.props.loadRecognizedDataForSelectedDocument(this.state.selectedESignDocument.documentGuid);
                var selected: IDocumentData = { ...this.state.selectedESignDocument };
                if (this.state.selectedESignDocument.sasUrl && this.state.selectedESignDocument.sasUrl != "") {
                    if (!this.state.selectedESignDocument.isPDFloaded && !this.state.selectedESignDocument.pdf) {
                        loadPdf(this.state.selectedESignDocument.sasUrl).then((data: IPdfDocumentFacade) => {
                            selected.pdf = data;
                            selected.isPDFloaded = true;
                            this.props.updateDocumentData(selected);
                        }).catch(err => {
                            logger.trackError(`Error occurred at loadPDF - SFtep3:  ${validateError(err)}`);
                        })
                    }
                    this.setState({ selectedESignDocument: selected, isBusy: false }, () => { this.forceUpdate(); });
                }
            }
            else {
                if (this.props.isRecipientsUpdated) {
                    this.props.loadRecognizedDataForSelectedDocumentIfRecipientsUpdated(this.state.selectedESignDocument.documentGuid);
                }
            }
            this.onPageChange({ page: 1, scale: this.state.scale });
        });


    }
    onBookmarkPageClick = (pageNo: number, docuementKey?: string) => {
        if(this.state.conditionalControlState.selectingFields && this.state.selectedESignDocument.documentGuid != docuementKey) {
            VenusNotifier.Error(ConditionalControlConstant.selectingFieldsError,null);
            return;
        }

        const index = this.props.documentData.findIndex(x => x.documentGuid === docuementKey);

        //Temp Solution for pdf viewer page change not triggering for first page of documents
        if (this.state.currentPage === pageNo && this.state.selectedESignDocument.documentGuid !== docuementKey && pageNo === 1) {
            this.setState({ selectedESignDocument: { ...this.props.documentData[index] }, currentPage: pageNo, scale: this.state.scale });
        } else {
            this.setState({ selectedESignDocument: { ...this.props.documentData[index] }, currentPage: pageNo, scale: this.state.scale });
        }
        this.onPageChange({ page: pageNo, scale: this.state.scale });
    }

    createUndoButton = () => {

        return this.state.undoDisable ? <div className="icon-bar d-none d-sm-block"><a className="icon disabled" title='' style={{ pointerEvents: 'none' }}><i className='fas fa-reply undo'></i></a> </div>
            : <div className="icon-bar d-none d-sm-block"><a className="icon" onClick={this.onControlUndo} title='Signature control position cannot be undone using Undo button.' ><i className='fas fa-reply undo'></i></a></div>;
    }

    private getSignatureControls = (): SFPdfPageSignatureControls => {
        return {
            signatureControls: this.filterSignatureControls(),
            signer: this.state.selectedSigner,
            focusedGroup: this.state.focusedGroup,
            sender: this.state.sender
        } as SFPdfPageSignatureControls;
    }
    private filterSignatureControls = (): ISignatureControl[] => {
        let signControls: ISignatureControl[] = [];
        const index = this.props.signingInfo.documentInfo.findIndex(x => x.documentGuid == this.state.selectedESignDocument.documentGuid);
        if (index != Helper.NO_INDEX) {

            const signatureData = this.props.signingInfo.documentInfo[index].signatureData;
            if (signatureData && signatureData.length > 0) {
                let index2 = signatureData.findIndex(x => x.pageNo == this.state.currentPage);

                if (index2 != Helper.NO_INDEX) {
                    signatureData[index2].signatureControls.map(function (value, index) {
                        signControls.push({
                            controlGuid: value.controlGuid,
                            signer: value.signer,
                            role: value.role,
                            type: value.type,
                            top: value.top,
                            left: value.left,
                            height: value.height,
                            width: value.width,
                            tooltip: value.tooltip,
                            required: value.required,
                            items: value.items,
                            controlName : value.controlName ? value.controlName : ''
                        });
                    });
                }
            }
        }

        return signControls;
    }

    private isControlInConditionalControlsRules = (control : ISignatureControl) => {
        let temp_signingInfo: ISigningInfo = Object.assign({}, this.props.signingInfo);
        const documentInfoIndex = temp_signingInfo.documentInfo.findIndex(x => x.documentGuid === this.state.selectedESignDocument.documentGuid);


        if (documentInfoIndex !== Helper.NO_INDEX) {
            const conditionalControlsRules = temp_signingInfo.documentInfo[documentInfoIndex]?.conditionalControlsRules;
            if(conditionalControlsRules && conditionalControlsRules.length > 0){
                for (let controls of conditionalControlsRules) {
                    if(controls.conditionalControl.controlGuid == control.controlGuid){
                        return true;
                    }
                    for (let rule of controls.controlOptionsRules) {
                        for (let selectedControl of rule.selectedControls) {
                            if (selectedControl.controlGuid === control.controlGuid) {
                                return true;
                            }
                        }
                    }
                }
            }
        }

        return false;
    }

    private onControlRemove = (control: ISignatureControl, isHist?: boolean) => {
        let temp_signingInfo: ISigningInfo = Object.assign({}, this.props.signingInfo);
        const documentInfoIndex = temp_signingInfo.documentInfo.findIndex(x => x.documentGuid === this.state.selectedESignDocument.documentGuid);
        if (documentInfoIndex !== Helper.NO_INDEX) {
            const signatureData = temp_signingInfo.documentInfo[documentInfoIndex].signatureData;
            const signatureDataIndex = signatureData.findIndex(x => x.pageNo === this.state.currentPage);
            if (signatureDataIndex !== Helper.NO_INDEX) {

                //check if control is already not present under any Conditional Control Rules
                if(!isHist){
                    if(this.isControlInConditionalControlsRules(control)){
                        this.onShowDeleteConditionalControlPopup();
                        return;
                    }
                }

                signatureData[signatureDataIndex].signatureControls = signatureData[signatureDataIndex].signatureControls.filter(x => x.controlGuid != control.controlGuid);

                //Add the Controls to be deleted to history
                if (!isHist) {

                    //add Signature history object if it does not exist
                    if (!signatureData[signatureDataIndex].signatureControlsHistory) {
                        let signatureControlsHistory: ISignatureControlsHistory[] = [];
                        signatureData[signatureDataIndex].signatureControlsHistory = signatureControlsHistory;
                    }

                    signatureData[signatureDataIndex].signatureControlsHistory.push({ signatureControl: control, controlHistoryType: ControlHistoryType.Removed });
                    this.state.undoDisable ? this.setState({ undoDisable: false }) : "";
                    this.props.updateSFData(temp_signingInfo);
                }
            }
            this._toolbar.forceUpdate();

            this._controlLayer.removeControl("control_" + control.controlGuid);
        }
    }

    private onControlUpdate = (oldControl: ISignatureControl, newControl: ISignatureControl) => {
        let temp_signingInfo: ISigningInfo = Object.assign({}, this.props.signingInfo);
        const documentInfoIndex = temp_signingInfo.documentInfo.findIndex(x => x.documentGuid === this.state.selectedESignDocument.documentGuid);
        if (documentInfoIndex !== Helper.NO_INDEX) {
            const signatureData = temp_signingInfo.documentInfo[documentInfoIndex].signatureData;
            const signatureDataIndex = signatureData.findIndex(x => x.pageNo === this.state.currentPage);
            
            if (signatureDataIndex !== Helper.NO_INDEX) {
                const updatedSignatureControls = signatureData[signatureDataIndex].signatureControls.map((x) => {
                    if (x.controlGuid === oldControl.controlGuid) {
                        return {
                            ...x,
                            top: newControl.top,
                            left: newControl.left,
                        };
                    }
                    return x;
                });
                signatureData[signatureDataIndex].signatureControls = updatedSignatureControls;
                this.props.updateSFData(temp_signingInfo);
            }
            this._toolbar.forceUpdate();
        }
    }

    //Add to history

    //Reset
    private onPageReset = (documentGuid: string) => {

        if(this.state.conditionalControlState.selectingFields) {
            VenusNotifier.Error(ConditionalControlConstant.selectingFieldsError,null);
            return;
        }

        if(this.state.conditionalControlState.selectedPanelOption != ConditionControlOptionsTypes.None){
            this.resetConditionalControlState();
        }

        let temp_signingInfo: ISigningInfo = Object.assign({}, this.props.signingInfo);
        let _self = this;
        const documentData = this.props.documentData.filter(i => i.documentGuid === documentGuid)
        const documentInfoIndex = temp_signingInfo.documentInfo.findIndex(x => x.documentGuid === documentGuid);
        if (documentInfoIndex !== Helper.NO_INDEX) {

            if(documentData && !documentData[0].isPDFloaded){
                this.props.loadRecognizedDataForUnloadedDocuments(documentData);
            }

            if (this.state.selectedESignDocument.documentGuid === documentGuid) {

                //Remove all controls
                document.querySelectorAll(".placeholder-control").forEach((element) => {
                    _self._controlLayer.removeControl(element.id);
                })
                document.querySelectorAll(".choosable-control-group").forEach((element) => {
                    _self._controlLayer.removeControl(element.id);
                })

                this.state.undoDisable ? "" : this.setState({ undoDisable: true });
                this._toolbar.forceUpdate();
            }
            temp_signingInfo.documentInfo[documentInfoIndex].signatureData = [];
            this.props.updateSFData(temp_signingInfo);
        }
    }
    //Undo
    private onControlUndo = () => {
        if(this.state.conditionalControlState.selectedPanelOption == ConditionControlOptionsTypes.Rules){ // undo will not work when rules right panel is open
            return
        }

        let temp_signingInfo: ISigningInfo = Object.assign({}, this.props.signingInfo);
        const documentInfoIndex = temp_signingInfo.documentInfo.findIndex(x => x.documentGuid === this.state.selectedESignDocument.documentGuid);
        let signaturehistorys = temp_signingInfo.documentInfo[documentInfoIndex].signatureData.find(x => x.pageNo === this.state.currentPage)?.signatureControlsHistory
        
        //check if control is already not present under any Conditional Control Rules
        if(signaturehistorys && signaturehistorys.length > 0 ){
            if(signaturehistorys[signaturehistorys.length - 1].controlHistoryType == ControlHistoryType.Added){
                if(this.isControlInConditionalControlsRules(signaturehistorys[signaturehistorys.length - 1].signatureControl)){
                    this.onShowDeleteConditionalControlPopup();
                    return;
                }
            }
        }
        
        let signaturehistory = signaturehistorys?.pop();


        //Switch to Add,Remove and update position
        switch (signaturehistory?.controlHistoryType) {
            case ControlHistoryType.Added:
                this.onControlRemove(signaturehistory.signatureControl, true);
                break;

            case ControlHistoryType.Updated:
                this.onControlRemove(signaturehistory.signatureControl, true);
                this.addSignatureControl(signaturehistory.signatureControl.type, signaturehistory.signatureControl.role, signaturehistory.signatureControl.signer, signaturehistory.signatureControl.left, signaturehistory.signatureControl.top, true, signaturehistory.signatureControl.controlGuid);
                break;

            case ControlHistoryType.Removed:
                this.addSignatureControl(signaturehistory.signatureControl.type, signaturehistory.signatureControl.role, signaturehistory.signatureControl.signer, signaturehistory.signatureControl.left, signaturehistory.signatureControl.top, true, signaturehistory.signatureControl.controlGuid);
                break;

            default:
        }

        //Disable undo button
        if (!signaturehistorys || signaturehistorys.length === 0) {
            this.state.undoDisable ? "" : this.setState({ undoDisable: true });
            this._toolbar.forceUpdate();
        }
        this._controlLayer.forceUpdate();


        this.props.updateSFData(temp_signingInfo);
        //call onControlUndoAdd



    }

    //Add to control history





    private onToolTipEdit = (control: ISignatureControl) => {


        //Temp solution pdf viewer controls props are not getting updated when parent state is updated need to check later
        let tempControl = this.props.signingInfo.documentInfo.find(x => x.documentGuid === this.state.selectedESignDocument.documentGuid)?.signatureData.
            find(y => y.pageNo === this.state.currentPage)?.signatureControls.find(z => z.controlGuid === control.controlGuid);

        this.setState({
            toolTipControl: tempControl ? tempControl : control,
            showToolTipPopup: true
        });
    }

    private onCancelToolTipPopup = () => {
        this.setState({
            toolTipControl: initialSignatureControl,
            showToolTipPopup: false
        });
    }

    private onSaveToolTip = (control: ISignatureControl,isupdate:boolean = false) => {

        let temp_signingInfo: ISigningInfo = Object.assign({}, this.props.signingInfo);
        let temp_updatedControl = null;
        const documentInfoIndex = temp_signingInfo.documentInfo.findIndex(x => x.documentGuid === this.state.selectedESignDocument.documentGuid);
        if (documentInfoIndex !== Helper.NO_INDEX) {
            const signatureData = temp_signingInfo.documentInfo[documentInfoIndex].signatureData;
            const signatureDataIndex = signatureData.findIndex(x => x.pageNo === this.state.currentPage);
            if (signatureDataIndex !== Helper.NO_INDEX) {
                let signatureControls = signatureData[signatureDataIndex].signatureControls;

                for (let i = 0; i < signatureControls.length; i++) {

                    let signControl = signatureControls[i];

                    if (signControl.controlGuid === control.controlGuid) {

                        signControl.tooltip = control.tooltip;
                        signControl.required = control.required;

                        if(signControl.type === SignatureControlType.Radio){
                            
                            signControl.height = isupdate ? control.height : (SignatureControlConstants.ControlHeight_RadioButton + Number(control.tooltip) * 20) * this.state.scale;
                            signControl.items = this.addControlItems(control.tooltip,isupdate,control);
                            signControl.controlName = control.controlName ? control.controlName : "RadioButton";
                        }

                        if(signControl.type === SignatureControlType.CheckBox){
                            signControl.controlName = control.controlName ? control.controlName : "CheckBox";
                        }

                        temp_updatedControl = signControl;
                        break;
                    }
                }
                this.props.updateSFData(temp_signingInfo);
            }
        }

        this.setState({
            toolTipControl: initialSignatureControl,
            showToolTipPopup: false
        });

        if(this.state.conditionalControlState.selectedPanelOption == ConditionControlOptionsTypes.Settings && temp_updatedControl != null){            
            this.setConditionalControl(this.state.conditionalControlState.selectedPanelOption, temp_updatedControl);
        }
    }

    private getFocusedGroup = (signatureControlRole: SignatureControlRole): SignatureGroupType => {
        let signatureGroupType = SignatureGroupType.None;
        if (signatureControlRole === SignatureControlRole.SFReceipient) {
            signatureGroupType = SignatureGroupType.Receiver;
        }
        else if (signatureControlRole === SignatureControlRole.SFSender) {
            signatureGroupType = SignatureGroupType.Sender;
        }
        return signatureGroupType;
    }

    onPageChange = (pageProps: PageProperties) => {
        if(!this.state.conditionalControlState.selectingFields) {
            this.resetConditionalControlState();
        }
        this.setState({ currentPage: pageProps.page, scale: pageProps.scale ? pageProps.scale : this.state.scale }, () => {
            this._viewPanel.gotoPage(pageProps.page);
            const documentInfoIndex = this.props.signingInfo.documentInfo.findIndex(x => x.documentGuid === this.state.selectedESignDocument.documentGuid);
            let signData = this.props.signingInfo.documentInfo[documentInfoIndex].signatureData.find(x => x.pageNo === pageProps.page);
            // if signature history is missing add it
            if (signData && !signData.signatureControlsHistory) {
                let signatureData: ISignatureControlsHistory[] = [];
                signData.signatureControlsHistory = signatureData;
            }
            //Manage undo button on page change
            if (!signData || signData.signatureControlsHistory?.length === 0) {
                this.state.undoDisable ? "" : this.setState({ undoDisable: true });
            }
            else {
                this.state.undoDisable ? this.setState({ undoDisable: false }) : "";
            }
            if (this.props.signingInfo.documentInfo[documentInfoIndex].signatureData.length === 0) {
                //Delete elements of cleared document
                document.querySelectorAll(".placeholder-control").forEach((element) => {
                    this._controlLayer.removeControl(element.id);
                })
                document.querySelectorAll(".choosable-control-group").forEach((element) => {
                    this._controlLayer.removeControl(element.id);
                })
            }
            this._toolbar.forceUpdate();
            this.updateForm();
        });


        //this.setState({ currentPage: pageProps.page, scale: pageProps.scale ? pageProps.scale : this.state.scale }, () => {

        //    this._toolbar && this._toolbar.handleZoomChange(pageProps.scale ? pageProps.scale : this.state.scale );

        //});
    }

    private getTooltipContent = (controlType: number): any => {
        const { Radio } = SignatureControlType;
        if (controlType === Radio) {
            return { header: "Radio Properties", title: "How many radio button(s) would you like to add?", tooltipContent: "" }
        } else {
            return { header: "Tool Tip", title: "Add Text", tooltipContent: "This text will help the signer to enter the necessary details" };
        }
    }

    ///////////////////////////////////////////////////////Condtional Control Panel Starts Here//////////////////////////////////////////////

    //this funtion is written if right panel is already closed and we select any conditional control options so we need to open it
    private isRightPanelOpen = () => {
        const element = document.getElementById("right-panel");
        
        if (element && element.classList.contains("collapsed")) {
          element.classList.remove("collapsed");
          element.classList.add("active-panel");
        }
        return;
    }

    private onConditionalControlOptionsClick = (selectedOption: ConditionControlOptionsTypes | null, control : ISignatureControl | null) => {
        let controlRulePresent : IConditionalControlRule;

        this.isRightPanelOpen();
        const documentInfo = this.props.signingInfo.documentInfo.find(x => x.documentGuid === this.state.selectedESignDocument.documentGuid);
        if(control != null){
            controlRulePresent = documentInfo?.conditionalControlsRules.find(x => x.conditionalControl?.controlGuid === control.controlGuid);
        }
        const signatureData = documentInfo?.signatureData.find(x => x.pageNo === this.state.currentPage);

        switch(selectedOption){
            case ConditionControlOptionsTypes.Settings:      

                this.setState({
                    conditionalControlState: {
                        ...this.state.conditionalControlState,
                        selectedPanelOption: selectedOption,
                        selectedControl: control as ISignatureControl}});
                return;

            case ConditionControlOptionsTypes.Rules:

                if(!controlRulePresent){
                    this.setState({
                        conditionalControlState: {
                            ...this.state.conditionalControlState,
                            conditionalControlsRules: [...this.state.conditionalControlState.conditionalControlsRules, 
                                                            {conditionalControl: control as ISignatureControl, 
                                                                controlOptionsRules: [  {ruleId: 1,
                                                                                        selectedOption: {} as  ChoosableControlData,
                                                                                        selectedControls: []}]}],
                            selectedPanelOption: selectedOption,
                            selectedControl: control as ISignatureControl}});
                    return;        
                }
                else{
                    if(documentInfo){
                        this.setState({
                            conditionalControlState: {
                                ...this.state.conditionalControlState,
                                selectedPanelOption: selectedOption,
                                selectedControl: control as ISignatureControl,
                                conditionalControlsRules: documentInfo?.conditionalControlsRules ? structuredClone(documentInfo.conditionalControlsRules) : []}});
                    }
                    return;
                }

            case ConditionControlOptionsTypes.Duplicate:
                if(signatureData){
                    const selectedControl = signatureData.signatureControls.find(x => x.controlGuid === control.controlGuid);
                    this.addSignatureControl(selectedControl.type, selectedControl.role, selectedControl.signer, selectedControl.left, selectedControl.top, undefined, undefined, true, selectedControl );
                }

                return;

            case ConditionControlOptionsTypes.Delete:
                if(this.isControlInConditionalControlsRules(control)){
                    this.onShowDeleteConditionalControlPopup();
                    return;
                }
                else{
                    if(controlRulePresent){
                        const isAnyRulePresent = controlRulePresent.controlOptionsRules.find(x => x?.selectedControls.length > 0); 
                        if(isAnyRulePresent){
                            this.setState({
                                conditionalControlState: {
                                    ...this.state.conditionalControlState,
                                    selectedPanelOption: ConditionControlOptionsTypes.None,
                                    showDeleteConditionalControlPopup: true}});
                                }
                    }
                    else{
                        this.onControlRemove(control);
                        this.resetConditionalControlState();
                    }
                    return;
                }
            
            case ConditionControlOptionsTypes.None:
                this.setState({
                    conditionalControlState: {
                        ...initialConditionalControlPanelState,
                        selectedPanelOption: ConditionControlOptionsTypes.None,
                        selectedControl: control,
                        conditionalControlsRules: documentInfo?.conditionalControlsRules ? documentInfo.conditionalControlsRules : []
                    }});
                
                return;
            default:
                this.resetConditionalControlState();
        }
    }

    private updateConditionalControl = (control: ISignatureControl, type: string,item? :ChoosableControlData ) => {
        const { tooltip, type: controlType } = control;
        let tooltipNumber = Number(tooltip);
        switch (type) {
            case "addControlOption":
                    control.tooltip = (tooltipNumber + 1).toString(); 
                    if (controlType && controlType === SignatureControlType.Radio && Number(control.tooltip) <= 5) {
                            let maxControlNumber = control.items.reduce((prev, current) => (prev.controlNumber > current.controlNumber) ? prev : current)?.controlNumber;
                            control.items.push({
                                id: Guid.newGuid().toString(),
                                controlNumber: Number(maxControlNumber) + 1,
                                name: Guid.newGuid().toString(),
                                top: (20 * control.items.length),
                                left: 35,
                                width: 0,
                                height: 0,
                                value: { checked: false, value: '' }
                            })
                            control.items = control.items.map((item,index) =>  ({...item, left : 35,top: (20 * (index + 1))}))
                            control.height = (SignatureControlConstants.ControlHeight_RadioButton + Number(control.tooltip) * 20) * this.state.scale;                          
                            this.onSaveToolTip(control,true);
                        }
                    break;
            case "deleteControlOption":
                    control.tooltip = (tooltipNumber - 1).toString();
                    if (controlType && controlType === SignatureControlType.Radio && Number(control.tooltip) >= 2) {
                            if(control.items.length > 0){
                                control.items = control.items.filter(x => x.id != item?.id);
                                control.items = control.items.map((item,index) =>  ({...item, left : 35,top: (20 * (index + 1))}))
                            }
                            control.height = (SignatureControlConstants.ControlHeight_RadioButton + Number(control.tooltip) * 20) * this.state.scale;
                            this.onSaveToolTip(control,true);
                        }
                    break;
            case "updateRequired":
                    let temp_signingInfo: ISigningInfo = Object.assign({}, this.props.signingInfo);
                    let temp_updatedControl = null;
                    const documentInfoIndex = temp_signingInfo.documentInfo.findIndex(x => x.documentGuid === this.state.selectedESignDocument.documentGuid);
                    if (documentInfoIndex !== Helper.NO_INDEX) {
                        const signatureData = temp_signingInfo.documentInfo[documentInfoIndex].signatureData;
                        const signatureDataIndex = signatureData.findIndex(x => x.pageNo === this.state.currentPage);
                        if (signatureDataIndex !== Helper.NO_INDEX) {
                            let signatureControls = signatureData[signatureDataIndex].signatureControls;
                            for (let i = 0; i < signatureControls.length; i++) {
                                let signControl = signatureControls[i];
                                if (signControl.controlGuid === control.controlGuid) {
                                    signControl.required = control.required;
                                    temp_updatedControl = signControl;
                                    break;
                                }
                            }
                            this.props.updateSFData(temp_signingInfo);
                        }
                    }
                    if(this.state.conditionalControlState.selectedPanelOption == ConditionControlOptionsTypes.Settings && temp_updatedControl != null){            
                        this.setConditionalControl(this.state.conditionalControlState.selectedPanelOption, temp_updatedControl);
                    }
                    break;
            case "updateTooltip":
                    this.onSaveToolTip(control,true);
                    break;        
            default:
                return;
        }
    }


    private setConditionalControl = (selectedOption: ConditionControlOptionsTypes, selectedControl: ISignatureControl) => {
        this.setState({
            conditionalControlState: {
                ...this.state.conditionalControlState,
                selectedPanelOption: selectedOption,
                selectedControl: selectedControl
            }
        });
    }


    private resetConditionalControlState = () => {
        const documentInfo = this.props.signingInfo.documentInfo.find(x => x.documentGuid === this.state.selectedESignDocument.documentGuid);

        this.setState({conditionalControlState: {...initialConditionalControlPanelState, 
                                                    conditionalControlsRules: documentInfo?.conditionalControlsRules ? documentInfo.conditionalControlsRules : []}});
    }

    private updateConditionalControlState = (conditionalControlState: IConditionalControlPanelState ) => {
        this.setState({conditionalControlState: Object.assign({},conditionalControlState)});
    }

    private onHideDeleteConditionalControlPopup = () => {
        this.setState({
            conditionalControlState: {
                ...this.state.conditionalControlState,
                showDeleteConditionalControlPopup: false
            }
        });
    }

    private onShowDeleteConditionalControlPopup =() => {
        this.setState({
            conditionalControlState: {
                ...this.state.conditionalControlState,
                showDeleteConditionalControlPopup: true
            }
        });
    }

    private validateConditionalControlRules = () => {
        if (this._conditionalControlPanel.current &&  this._conditionalControlPanel.current.validatesignatureRules()) {
            return true;
        }
        return false;
    }

    private onConditionalControlPanelButtonClick = (type : string) => {

        switch(type){
            case "discard":
                this.resetConditionalControlState();
                return;
            case "addRules":
                if(this.validateConditionalControlRules()){
                    let temp_signingInfo: ISigningInfo = Object.assign({}, this.props.signingInfo);
                    const documentInfoIndex = temp_signingInfo.documentInfo.findIndex(x => x.documentGuid === this.state.selectedESignDocument.documentGuid);
                    if (documentInfoIndex !== Helper.NO_INDEX) {
                        let isUpdatingRules = this.showUpdateButton();
                        temp_signingInfo.documentInfo[documentInfoIndex].conditionalControlsRules = [...this.state.conditionalControlState.conditionalControlsRules];
                        this.props.updateSFData(temp_signingInfo);
                        isUpdatingRules ? VenusNotifier.Success("Rule Updated successfully.", null) :  VenusNotifier.Success("Rule created successfully.", null)
                        this.resetConditionalControlState();
                    } 
                }
                return;
            case "deleteControlRules":
                if(this.state.conditionalControlState.selectingFields){
                    VenusNotifier.Error(ConditionalControlConstant.selectingFieldsError,null);
                    return;
                }
                let temp_signingInfo: ISigningInfo = Object.assign({}, this.props.signingInfo);
                const documentInfoIndex = temp_signingInfo.documentInfo.findIndex(x => x.documentGuid === this.state.selectedESignDocument.documentGuid);
                if (documentInfoIndex !== Helper.NO_INDEX) {
                    temp_signingInfo.documentInfo[documentInfoIndex].conditionalControlsRules = temp_signingInfo.documentInfo[documentInfoIndex].conditionalControlsRules.filter(x => x.conditionalControl.controlGuid != this.state.conditionalControlState.selectedControl.controlGuid);
                    this.props.updateSFData(temp_signingInfo);
                    this.resetConditionalControlState();
                }
                return;
            default:
                return;
        }
    }

    private slectControlForRule = (control: ISignatureControl) => {
        let tempConditionalControlState = Object.assign({}, this.state.conditionalControlState);
        let controlRulePresent = tempConditionalControlState.conditionalControlsRules.find(x => x.conditionalControl.controlGuid === this.state.conditionalControlState.selectedControl.controlGuid);
        if(controlRulePresent){
           let selectedControls = controlRulePresent.controlOptionsRules.find(rule => rule.selectedOption.id === this.state.conditionalControlState.selectingFeildOption.id).selectedControls;
            selectedControls.push(control);
        }
        this.setState({conditionalControlState: tempConditionalControlState});
    }

    private showUpdateButton = () => {
        const documentInfo = this.props.signingInfo.documentInfo.find(x => x.documentGuid === this.state.selectedESignDocument.documentGuid);
        if (documentInfo) {
            if(documentInfo?.conditionalControlsRules.find(x => x.conditionalControl.controlGuid === this.state.conditionalControlState.selectedControl.controlGuid)?.controlOptionsRules.find(x => x.selectedControls.length > 0))
            {
                return true;
            }
        }
        return false;
    }


    ///////////////////////////////////////////////////////Condtional Control Panel Ends Here//////////////////////////////////////////////
    
    public render() {
        return (this.state.isBusy ? (<LoadingOverlay style={{ height: '100%' }}>
            <Loader loading={this.state.isBusy} />
        </LoadingOverlay>) :
            <PdfViewer>
                <Header>
                    <Toolbar
                        ref={(ref: any) => this._toolbar = ref}
                        customToolbarOptions={this.createUndoButton()}
                        customToolbarPosition={'left'}
                        hideReadOnly={true}
                        hideRightPanel={false}
                    >
                        <Pagination />
                        <Zoom>
                        </Zoom>
                        <CustomOptions position="left" />
                    </Toolbar>
                    {this.state.conditionalControlState.selectedPanelOption === ConditionControlOptionsTypes.Rules ? 
                        <ConditionalControlPanelButtons
                        onButtonClick={this.onConditionalControlPanelButtonClick}
                        showUpdateButton={this.showUpdateButton()} /> : null}
                </Header>
                <div className="main" style={{
                    height: 'calc(100vh - 305px)',
                    fontSize: '12px'
                }}>
                    <LeftPanel>
                        {
                            <BookmarksPane sectionTitle={"Document(s)"} documentData={this.props.documentData ? this.props.documentData : []}
                                onDocumentSelect={this.onDocumentClick} onPageClick={this.onBookmarkPageClick}
                                isDocumentsLoading={this.props.signingInfo.documentInfo.length == this.props.documentData.length ? false : true}
                                isSelectedDocumentBookmarksLoaded={this.state.selectedESignDocument?.isPDFloaded} reset={this.onPageReset} />
                        }
                    </LeftPanel>
                    {
                        <ControlLayer enableControlsLazyLoad={false} ref={(ref: any) => { this._controlLayer = ref; }} useDefaultNavigationStartControl={false} >
                            {
                                this.renderSignatureControl()
                            }
                        </ControlLayer>
                    }
                    <ViewPanel onDocumentLoad={() => {
                        this.setState({
                            scale: 1
                        })
                    }}
                        ref={(ref: any) => this._viewPanel = ref}

                        onPageChanged={this.onPageChange}
                        onScaleChanged={(scale: number) => {
                            this.setState({
                                scale: scale
                            })
                        }}
                        pageMode={PageMode.SinglePage}
                        defaultPage={this.state.currentPage}
                        url={this.state.selectedESignDocument.sasUrl}
                        disableTextLayer={true}
                        pdfSource={PdfSource.createFromUrl(this.state.selectedESignDocument.sasUrl)}
                    >
                    </ViewPanel>

                    <SignerTooltipPopup
                        control={this.state.toolTipControl}
                        showState={this.state.showToolTipPopup}
                        onHide={this.onCancelToolTipPopup}
                        submitButtonClick={this.onSaveToolTip}
                        content={this.getTooltipContent(this.state.toolTipControl.type)}
                    />

                    <DeleteConditionalControlPopup
                     showDeleteConditionalControlPopup = {this.state.conditionalControlState.showDeleteConditionalControlPopup}
                     onHideDeleteConditionalControlPopup={this.onHideDeleteConditionalControlPopup}
                    />
                    <RightPanel>
                        {
                            <>
                                {this.state.conditionalControlState.selectedPanelOption === ConditionControlOptionsTypes.None ?
                                    <SignatureControlPanel
                                        title="Recipient(s)"
                                        selectedSigner={this.state.selectedSigner}
                                        signers={this.state.signers}
                                        onSelectBoxChange={this.signerChange}
                                        controlBoxItems={signersControlBoxItems}
                                        dragStart={this.dragStartSignatureControl}
                                        inputType={InputType.Dropdown}
                                        dragEnd={this.dragEndSignatureControl}
                                        />
                                        :
                                    <ConditionalControlPanel
                                        ref={this._conditionalControlPanel}
                                        updateConditionalControl= {this.updateConditionalControl}
                                        condtionalControlPanelState = {this.state.conditionalControlState}
                                        closeConditionalControlPanel = {this.resetConditionalControlState}
                                        updateConditionalControlState = {this.updateConditionalControlState}
                                        isEditText = {this.showUpdateButton()}
                                    />}
                            </>
                        }
                    </RightPanel>

                </div>
            </PdfViewer>
        );
    }
}
