import * as React from 'react';
import { CreateRuleSvg, DeleteControlSvg, DisabledDuplicateSvg, DuplicateSvg, SettingIcon } from '../svg/CustomSvgIcons';
import { ISignatureControl, SignatureControlType } from 'src/SignatureFlow/models/SignatureData';
import { ConditionalControlConstant } from '../helper/Constants';
import { VenusNotifier } from '../helper/VenusNotifier';

export enum ConditionControlOptionsTypes {
    None,
    Settings,
    Rules,
    Duplicate,
    Delete
}

export interface ConditionControlOptionsProps {
    onOptionClick: (option: ConditionControlOptionsTypes) => void;
    isConditionalControlOptionDisabled: boolean;
    control: ISignatureControl;
    isEditRule: boolean;
    style: React.CSSProperties;
    isControlRuleDisabled: boolean;
}

const ConditionControlOptions: React.FC<ConditionControlOptionsProps> = (props) => {

    const onOptionClick = (event :any, option: ConditionControlOptionsTypes) => {
        event.stopPropagation();
        if(option === ConditionControlOptionsTypes.Rules && props.isControlRuleDisabled && !props.isConditionalControlOptionDisabled){
            VenusNotifier.Error(ConditionalControlConstant.SelectingDisabledRuleOption,null)
            return;
        }
        if(option === ConditionControlOptionsTypes.Duplicate && isDuplicateDisabled()){
            return;
        }
        if(props.onOptionClick && !props.isConditionalControlOptionDisabled){
            props.onOptionClick(option);
        }
    }

    const isOptionDisabledClass = () => {
        return props.isConditionalControlOptionDisabled ? 'disabled' : '';
    }

    const isDuplicateDisabled = () => {
        return props.control.type === SignatureControlType.Radio   && props.control?.items.length  >= 5 ;
    }

    const generateConditionalControlId  = () => {
        const id_prefix = props.control.type === SignatureControlType.CheckBox ? 'checkbox' : 'radio';
        return `${id_prefix}_conditional_options_${props.control.controlGuid}`;
    }

    return (
        <>
            <div style={props.style} className={`conditional-control-options ${isOptionDisabledClass()}`} id={generateConditionalControlId()}>
                <div onClick={(e) => onOptionClick(e,ConditionControlOptionsTypes.Settings)} data-title='Settings'> <SettingIcon fill='#F8F9FA'/>  </div>
                <div aria-disabled={props.isControlRuleDisabled} onClick={(e) => onOptionClick(e,ConditionControlOptionsTypes.Rules)} data-title={props.isEditRule ? 'Edit Rule' : 'Rules'}>{props.isControlRuleDisabled && !props.isConditionalControlOptionDisabled ? <CreateRuleSvg fill='#6B7075'/> : <CreateRuleSvg fill='#F8F9FA'/> }  </div>
                <div aria-disabled={isDuplicateDisabled()} onClick={(e) => onOptionClick(e,ConditionControlOptionsTypes.Duplicate)} data-title='Duplicate'>  {isDuplicateDisabled() ? <DisabledDuplicateSvg/>: <DuplicateSvg/>} </div>
                <div onClick={(e) => onOptionClick(e,ConditionControlOptionsTypes.Delete)} data-title='Delete'> <DeleteControlSvg/>  </div>
            </div>
        </>
    ); 
}

export default ConditionControlOptions;