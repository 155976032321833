import * as React from 'react';
import { useImperativeHandle, forwardRef } from 'react';
import { BigDropDownArrow, CloseXButton, SmallDashCircle } from '../svg/CustomSvgIcons';
import DropdownComponent from '../common/DropdownComponent';
import { ChoosableControlData, IControlOptionsRule, ISignatureControl, SignatureControlType } from '../../../SignatureFlow/models/SignatureData';
import { getControlText } from '../helper/SignatureHelper';
import { VenusNotifier } from '../helper/VenusNotifier';
import { ConditionalControlConstant } from '../helper/Constants';


export interface ICondtionalControlRuleCardProps {
    rule: IControlOptionsRule;
    selectedContorl: ISignatureControl;
    onChangeSelectedOption: (value: string,ruleId: number) => void;
    isOptionAlreadySelected: (value: string) => boolean;
    onChangeSelctingFields: (option : ChoosableControlData, value: boolean) => void;
    onRemoveSelectedControlRule: (type: string, rule : IControlOptionsRule, control?: ISignatureControl) => void;
    selectingFields: boolean,
    selectingFeildOption: ChoosableControlData | null;
    ruleCardError : any;
    removeRuleCardError: () => void;
}

const CondtionalControlRuleCard = (props:ICondtionalControlRuleCardProps) => {
    const[showOptionError , setShowOptionError] = React.useState('');
    const[showControlError, setShowControlError] = React.useState('');

    const setRulesOptions = (selectedControl: ISignatureControl) => {
        return selectedControl.items.map((option,index) => {
            return {label: `Option ${option.controlNumber}`, value: option.id}
        })
    }

    const onChangeSelectedOption = (value: string) => {
        setShowOptionError('');

        if(props.rule.ruleId == props.ruleCardError?.ruleId && props.ruleCardError?.errorType == 'showOptionError'){
            props.removeRuleCardError();
        }

        const getSelectedIdValue = setRulesOptions(props.selectedContorl).find(option => option.value == value);
        if(props.selectingFields){
            VenusNotifier.Error(ConditionalControlConstant.selectingFieldsError,null);
            return;
        }
        if(getSelectedIdValue.value == props.rule.selectedOption.id){
            return;
        }
        if(!props.isOptionAlreadySelected(value)){
            props.onChangeSelectedOption && props.onChangeSelectedOption(value,props.rule.ruleId);
        }
        else{
            setShowOptionError(`This value exists in another condition. Please select a different value.`);
            props.onChangeSelectedOption && props.onChangeSelectedOption("",props.rule.ruleId);
        }
    }

    const getSelectedControlText = (type: SignatureControlType) => {
        if(type == SignatureControlType.CheckBox){
            return 'Check Box';
        }    
        return getControlText(type);
    }

    const onChangeSelctingFields = (option : ChoosableControlData,value: boolean) => {
        if(option){
            if(props.selectingFields && props.selectingFeildOption?.id == option.id){
                value = false;
            }
            if(props.rule.ruleId == props.ruleCardError?.ruleId && props.ruleCardError?.errorType == 'showControlError'){
                props.removeRuleCardError();
            }
            props.onChangeSelctingFields(option,value);
        }

    }

    const onRemoveSelectedControlRule = (type: string, rule : IControlOptionsRule, control?: ISignatureControl) => {
        if((type == 'Remove-Rule' || type == 'Remove-Fields') && props.selectingFields){
            VenusNotifier.Error(ConditionalControlConstant.selectingFieldsError,null);
            return;
        }
        props.onRemoveSelectedControlRule && props.onRemoveSelectedControlRule(type,rule, control ? control : null);
    }
    
    if(showOptionError && props.rule.ruleId == props.ruleCardError?.ruleId && props.ruleCardError?.errorType == 'showOptionError'){
        setShowOptionError('');
    }

    return <div className='conditional-control-rules-card'>
                {props.selectedContorl.type == SignatureControlType.Radio && 
                    <>
                        <div className='div-flex marB10'>
                            <div className='fontWeight700' style={{flexGrow:'1'}}>If</div>
                            <div style={{cursor: "pointer"}} onClick={() => onRemoveSelectedControlRule('Remove-Rule',props.rule)}><SmallDashCircle fill='#CC4A43'/></div>
                        </div>
                        <div className='control-option-dropdown div-flex'>
                            <DropdownComponent
                                    id="reviewDocumentType"
                                    options={setRulesOptions(props.selectedContorl)}
                                    selectedValue={props.rule.selectedOption.id}
                                    onChange={(value) =>onChangeSelectedOption(value)}
                                    searchable={false}
                                    customPlaceHolder=' '
                                    clearable={false}
                                    arrowRenderer={() => <BigDropDownArrow/>} 
                                    customStyle={{height:'31px'}}/>
                            <span style={{paddingTop:'6px',marginLeft: '10px'}}>is selected</span>
                        </div>
                        {showOptionError && <div className='error-text'>{showOptionError}</div>}
                        {(props.rule.ruleId == props.ruleCardError?.ruleId && props.ruleCardError?.errorType == 'showOptionError') && <div className='error-text'>Please select at least 1 Control.</div>}
                        <hr/>
                        <div>
                            <div className='div-flex marB05'>
                                <div className={!props.rule.selectedOption.id ? 'disabled' : '' +'fontWeight700'} style={{flexGrow:'1'}}>Then</div>
                                <div style={{cursor: "pointer"}} data-testid='5244520c-4654-4567-803a-f20003d701bf' onClick={() => onRemoveSelectedControlRule('Remove-Fields',props.rule)}><SmallDashCircle fill={!props.rule.selectedOption.id ? '#C4C6C8' : '#CC4A43'}/></div>
                            </div>
                            <div className={!props.rule.selectedOption.id ? 'disabled' : '' +'marB10'}> Show fields </div>
                            {(props.rule.ruleId == props.ruleCardError?.ruleId && props.ruleCardError?.errorType == 'showControlError') && <div className='error-text'>Please select at least 1 Control.</div>}
                            <div>
                                <button className='control-select-field-button'
                                        title={(props.selectingFields && props.selectingFeildOption?.id == props.rule.selectedOption.id) ? 'Done' : 'Select Fields'}
                                        disabled={!props.rule.selectedOption.id || (props.selectingFields && props.selectingFeildOption?.id !== props.rule.selectedOption.id)} 
                                        onClick={() => onChangeSelctingFields(props.rule?.selectedOption, true)}>
                                    {(props.selectingFields && props.selectingFeildOption?.id == props.rule.selectedOption.id) ? 'Done' : 'Select Fields'}
                                </button>
                            </div>
                            {props.rule.selectedControls.map((control,index) => {
                                return <div key={index} className='selected-option-control'>
                                    <div className='fontWeight700' style={{flexGrow:'1'}}>{getSelectedControlText(control.type)}</div>
                                    <div style={{cursor: "pointer"}} onClick={() => onRemoveSelectedControlRule('Remove-Field',props.rule,control)} ><CloseXButton fill='#CC4A43'/></div>
                                </div>
                            })}
                        </div>
                    </>
                }
                {props.selectedContorl.type == SignatureControlType.CheckBox &&
                    <>
                      <div>
                        <div className='div-flex marB05'>
                                    <div className={'fontWeight700'} style={{flexGrow:'1'}}>Then</div>
                                    <div style={{cursor: "pointer"}} onClick={() => onRemoveSelectedControlRule('Remove-Fields', props.rule)}><SmallDashCircle fill={'#CC4A43'}/></div>
                                </div>
                                <div className={'marB10'}> Show fields </div>
                                {(props.rule.ruleId == props.ruleCardError?.ruleId && props.ruleCardError?.errorType == 'showControlError') && <div className='error-text'>Please select at least 1 Control.</div>}
                                <div>
                                    <button className='control-select-field-button'
                                            title={props.selectingFields ? 'Done' : 'Select Fields'} 
                                            onClick={() => onChangeSelctingFields(props.rule?.selectedOption, true)}>
                                        {props.selectingFields ? 'Done' : 'Select Fields'}
                                    </button>
                                </div>
                                {props.rule.selectedControls.map((control,index) => {
                                    return <div key={index} className='selected-option-control'>
                                        <div className='fontWeight700' style={{flexGrow:'1'}}>{getSelectedControlText(control.type)}</div>
                                        <div style={{cursor: "pointer"}} onClick={() => onRemoveSelectedControlRule('Remove-Field',props.rule,control)} ><CloseXButton fill='#CC4A43'/></div>
                                    </div>
                                })}
                      </div>
                    </>
                }
        </div>
};

export default CondtionalControlRuleCard;