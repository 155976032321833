import * as React from 'react';
import { Modal, Alert, ListGroup, Form, Button } from 'react-bootstrap';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { FaFileDownload } from 'react-icons/fa';
import { IDownloadableDocuments } from '../../../models/SignatureFlowReportState';
import { SignatureFlowReportConstants } from '../../helper/Constants';

interface IDownloadReturnsProps {
    show: boolean;
    onCancel(): void;
    downloadableDocuments: IDownloadableDocuments[];
    downloadIndividual(documentGuid: string, fileName: string, signingGuid: string, signingId: number, hasAllSignersSigned: boolean, fileDownloadName:string, storageAccountId: number): void;
    downloadAll(reports: IDownloadableDocuments[]): void;
    showLoader: boolean;
}

export class SignatureFlowReportDownloadDocuments extends React.Component<IDownloadReturnsProps, {}> {
    constructor(props: IDownloadReturnsProps) {
        super(props);
        this.state = {
            showLoader: false
        }
        this.getDownloadableDocument = this.getDownloadableDocument.bind(this);
    }

    private getDownloadableDocument() {
        let items = this.props.downloadableDocuments && this.props.downloadableDocuments.map((value, i) => {
            return <><a className="delivered-return-download-link download-return-pop-up" key={value.fileName + value.documentGuid}
                onClick={() => {
                    this.props.downloadIndividual(value.documentGuid, value.fileName,
                        value.signingGuid, value.signingId, value.isSigningCompleted, value.fileDownloadName, value.storageAccountId);
                }} title={value.fileDownloadName}>
                {value.fileDownloadName} 
            </a><br/>
            </>
        })
        return items;
    }

    public render() {
        return <div><Modal centered={true} show={this.props.show} onHide={this.props.onCancel} backdrop="static">
            <Modal.Header closeButton onClick={this.props.onCancel} >
                <Modal.Title>
                    {SignatureFlowReportConstants.DownloadDocument.ModalTitle}
                </Modal.Title>
            </Modal.Header>
            <Form>
                <Modal.Body>
                        <LoadingOverlay style={{ height: '100%' }} >
                                <div className='modal-scrollable'>
                                    <ListGroup>
                                        {
                                            this.getDownloadableDocument()

                                        }

                                    </ListGroup>
                                </div>
                            <Loader loading={this.props.showLoader} text={"Downloading please wait..."} />
                        </LoadingOverlay>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='default'
                        onClick={this.props.onCancel}
                        className='btn-white modal-footer-button-cancel'>
                        Cancel
                    </Button>
                    <Button onClick={() => { this.props.downloadAll(this.props.downloadableDocuments) }}
                        className=' modal-footer-button-save'
                        variant="info"
                        style={{width:'150px'}}>
                        Download All
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
        </div>
    }
}